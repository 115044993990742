import { Background, SizeFillSpaceMixin, StaticElement } from "styled/common/Utils";
import styled, { css } from "styled-components";

export const BackgroundStageWrapper = styled.div`
  ${Background};
  ${SizeFillSpaceMixin}
  pointer-events: none;
  ${StaticElement}
`;

const MaskedStage = css`
  clip-path: circle(0% at 0% 0%);
`;

export const ForegroundStageWrapper = styled.canvas`
  ${SizeFillSpaceMixin}
  ${StaticElement}
  z-index: 9;
  pointer-events: none;
  transform: translateZ(15px);
  ${MaskedStage};
`;
