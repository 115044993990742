import { desktopSizes, mobileSizes, tabletSizes, theme } from "theme/theme";

const desktopFontSizeModifier = 1;
const tabletFontSizeModifier = 1.75;
const mobileFontSizeModifier = 2.5;

export const CalculateWidth = (min: number, value: number, max: number) => {
  if (value < 0) {
    [min, max] = [max, min];
  }

  return `clamp(${(min * value) / 100}px, ${value}vw, ${(max * value) / 100}px)`;
};

export const CalculateHeight = (maxH: number, minW: number, maxW: number, value: number) => {
  const height = `calc(100vw * (${maxH} / ${maxW}) * (${value} / ${100}))`;
  let minHeight = `calc(${minW} * 1px * (${maxH} / ${maxW}) * (${value} / ${100}))`;
  let maxHeight = `calc(${maxW} * 1px * (${maxH} / ${maxW}) * (${value} / ${100}))`;

  if (value < 0) {
    [minHeight, maxHeight] = [maxHeight, minHeight];
  }

  return `clamp(${minHeight}, ${height}, ${maxHeight})`;
};

export const CalculateFromWidth = (
  property: string,
  values: { mobile?: number; tablet?: number; desktop?: number } | number
) => {
  if (!Object.keys(values).length) {
    return `${property}: ${CalculateWidth(
      desktopSizes.minWidth,
      values as number,
      desktopSizes.maxWidth
    )}`;
  }

  return Object.entries(values).map(entry => {
    if (entry[0] === "mobile")
      return `
      ${theme.breakpoints.mobile}{
        ${property}: ${CalculateWidth(mobileSizes.minWidth, entry[1], mobileSizes.maxWidth)};
      }
    `;

    if (entry[0] === "tablet")
      return `
      ${theme.breakpoints.tablet}{
        ${property}: ${CalculateWidth(tabletSizes.minWidth, entry[1], tabletSizes.maxWidth)};
      }
    `;

    if (entry[0] === "desktop")
      return `
      ${theme.breakpoints.desktop}{
        ${property}: ${CalculateWidth(desktopSizes.minWidth, entry[1], desktopSizes.maxWidth)};
      }
    `;
  });
};

export const CalculateFromHeight = (
  property: string,
  values: { mobile?: number; tablet?: number; desktop?: number }
) => {
  return Object.entries(values).map(entry => {
    if (entry[0] === "mobile")
      return `
      ${theme.breakpoints.mobile}{
        ${property}: ${CalculateHeight(
        mobileSizes.maxHeight,
        mobileSizes.minWidth,
        mobileSizes.maxWidth,
        entry[1]
      )};
      }
    `;

    if (entry[0] === "tablet")
      return `
      ${theme.breakpoints.tablet}{
        ${property}: ${CalculateHeight(
        tabletSizes.maxHeight,
        tabletSizes.minWidth,
        tabletSizes.maxWidth,
        entry[1]
      )};
      }
    `;

    if (entry[0] === "desktop")
      return `
      ${theme.breakpoints.desktop}{
        ${property}: ${CalculateHeight(
        desktopSizes.maxHeight,
        desktopSizes.minWidth,
        desktopSizes.maxWidth,
        entry[1]
      )};
      }
    `;
  });
};

export const CalculateFontSize = (
  property: string,
  values: { mobile?: number; tablet?: number; desktop?: number }
) => {
  return Object.entries(values).map(entry => {
    if (entry[0] === "mobile")
      return `
      ${theme.breakpoints.mobile}{
        ${property}: ${CalculateWidth(
        mobileSizes.minWidth,
        entry[1] * mobileFontSizeModifier,
        mobileSizes.maxWidth
      )};
      }
    `;

    if (entry[0] === "tablet")
      return `
      ${theme.breakpoints.tablet}{
        ${property}: ${CalculateWidth(
        tabletSizes.minWidth,
        entry[1] * tabletFontSizeModifier,
        tabletSizes.maxWidth
      )};
      }
    `;

    if (entry[0] === "desktop")
      return `
      ${theme.breakpoints.desktop}{
        ${property}: ${CalculateWidth(
        desktopSizes.minWidth,
        entry[1] * desktopFontSizeModifier,
        desktopSizes.maxWidth
      )};
      }
    `;
  });
};
