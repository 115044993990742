import { CalculateFontSize, CalculateFromHeight } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const MainMenuLinkFont = css`
  ${CalculateFontSize("font-size", { mobile: 3.9, tablet: 7 })}
  ${CalculateFontSize("line-height", { mobile: 3.3, tablet: 5.9 })}
  ${CalculateFromHeight("font-size", { desktop: 9 })}
  ${CalculateFromHeight("line-height", { desktop: 8 })}
  ${CalculateFontSize("letter-spacing", { mobile: 0.2, tablet: 0.2, desktop: 0.2 })}
  ${({ theme: { fonts } }) => fonts.demiBold}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    ${({ theme: { fonts } }) => fonts.demiBold}
  }
  font-weight: 600;
  text-transform: uppercase;
`;
