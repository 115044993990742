import { GatsbyImage } from "gatsby-plugin-image";
import {
  AbsoluteHidden,
  FillBackgroundSpaceMixin,
  NonSelectable,
  StaticElement
} from "styled/common/Utils";
import styled from "styled-components";

export const StaticImage = styled(GatsbyImage)`
  ${StaticElement}
  ${FillBackgroundSpaceMixin}
  ${NonSelectable}
  ${AbsoluteHidden}
`;
