import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  AnimatedTransform,
  FillSpaceMixin,
  NonSelectable,
  Preserve3dMixin
} from "styled/common/Utils";
import styled, { css } from "styled-components";

const FooterBurgerMenuParentMixin = css`
  position: absolute;
  right: 0px;
  bottom: 0px;
  pointer-events: none !important;
  height: calc(var(--vh, 1vh) * 100);
  ${CalculateFromWidth("width", { mobile: 7, tablet: 7, desktop: 7 })}
  z-index: 10;
  transform: translateZ(0px);
  backface-visibility: hidden;
  ${AnimatedTransform};
`;
const BurgerMenuParentMixin = css`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(-1px);
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    height: max(61.44px, min(6vw, 96px));
    width: max(71.68px, min(7vw, 112px));
    transform: translateZ(-6px);
  }
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 1 })}
  ${CalculateFromWidth("width", { mobile: 15, tablet: 10 })}
  ${CalculateFromWidth("height", { mobile: 15, tablet: 10 })}
  backface-visibility: hidden;
  pointer-events: all;
`;

export const BurgerMenuParent = styled.div<{ inFooter: boolean }>`
  ${({ inFooter }) =>
    inFooter
      ? FooterBurgerMenuParentMixin
      : css`
          ${BurgerMenuParentMixin}
          ${Preserve3dMixin}
        `}
`;

const FooterBurgerMenuWrapper = css`
  pointer-events: none !important;
  transform: translateZ(0px);
  backface-visibility: hidden;
`;

export const BurgerMenuWrapper = styled.div<{ inFooter: boolean }>`
  position: absolute;
  z-index: 7;
  right: 0;
  transform: translateZ(9px);
  top: 0;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    height: max(61.44px, min(6vw, 96px));
    width: max(71.68px, min(7vw, 112px));
    display: none;
  }
  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: 1 })}
  ${CalculateFromWidth("width", { mobile: 15, tablet: 10 })}
  ${CalculateFromWidth("height", { mobile: 15, tablet: 10 })}
  backface-visibility: hidden;
  ${({ inFooter }) => inFooter && FooterBurgerMenuWrapper}
`;

export const BurgerMenuButton = styled.button`
  ${FillSpaceMixin}
`;

export const BurgerMenuImage = styled.img`
  margin-right: max(37.888px, min(3.7vw, 59.2px));
  margin-top: max(30.72px, min(3vw, 48px));
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    height: max(25.6px, min(2.5vw, 40px));
    width: max(25.6px, min(2.5vw, 40px));
  }
  ${CalculateFromWidth("width", { mobile: 7, tablet: 5 })}
  ${CalculateFromWidth("height", { mobile: 7, tablet: 5 })}
  ${NonSelectable}
`;
