import React, { MutableRefObject, RefObject, useEffect, useRef } from "react";

import type { OpenMenuCallback } from "components/layout/BurgerMenu/BurgerMenu";
import {
  MenuWrapperBackground,
  MenuWrapperContainer,
  MenuWrapperContentWrapper,
  MenuWrapperMask,
  type MenuWrapperVariant
} from "components/other/MenuWrapper/MenuWrapper.styled";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { useLocomotiveScroll } from "contexts/LocomotiveContext";
import { addCloud, addRenderObject, useRenderContext } from "contexts/RenderContext";
import { gsap } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import {
  ElementRect,
  GetForegroundAnimation,
  GetToggleRect,
  GetTransitionAnimation
} from "utils/animations/foreground-utils";
import { ElementResize } from "utils/animations/resize-utils";

export type DisplayElement = (
  element: HTMLDivElement | HTMLButtonElement,
  transitionData?: MutableRefObject<ElementRect | null>
) => void;

export interface WrappedComponentProps {
  componentContent: RefObject<HTMLDivElement>;
  HideComponent: () => void;
  ShowComponent: DisplayElement;
  location: Location;
  TransitionFromMenu: () => void;
  currentToggleRect: MutableRefObject<ElementRect | null>;
  openMenuCallback?: OpenMenuCallback;
}

interface MenuWrapperProps {
  location: Location;
  openMenuCallback?: OpenMenuCallback;
}

interface MenuWrapperInnerProps {
  WrappedComponent: Component<WrappedComponentProps>;
  cloudName: string;
  cloudGroup: string;
  variant: MenuWrapperVariant;
}

export const MenuWrapper =
  ({
    WrappedComponent,
    cloudName,
    cloudGroup,
    variant
  }: MenuWrapperInnerProps): Component<MenuWrapperProps> =>
  ({ location, openMenuCallback }) => {
    const [stateRender, dispatchRender] = useRenderContext();
    const locomotiveScroll = useLocomotiveScroll();
    const locomotiveScrollRef = useRef(locomotiveScroll);
    const canUseRenderer = useRef<boolean>(false);
    const windowShown = useRef<boolean>(false);
    const animations = useRef<gsap.core.Timeline | null>(null);
    const transitionAnimation = useRef<gsap.core.Timeline | null>(null);
    const background = useRef<HTMLDivElement>(null);
    const maskWrapper = useRef<HTMLDivElement>(null);
    const cloudWrapper = useRef<HTMLDivElement>(null);
    const mobileCloudWrapper = useRef<HTMLDivElement>(null);
    const currentToggleRect = useRef<ElementRect | null>(null);
    const componentWrapper = useRef<HTMLDivElement>(null);
    const componentContentWrapper = useRef<HTMLDivElement>(null);
    const componentContent = useRef<HTMLDivElement>(null);
    const cloud = {
      name: cloudName,
      wrapper: cloudWrapper.current,
      group: cloudGroup,
      addThunders: true,
      maskWrapper: maskWrapper.current
    };
    const renderObject = {
      name: "BlackSquare",
      type: "ImageLayer",
      wrapper: componentWrapper.current,
      group: undefined,
      additionalData: undefined,
      maskWrapper: maskWrapper.current
    };

    useEffect(() => {
      locomotiveScrollRef.current = locomotiveScroll;
    });

    useEffect(() => {
      canUseRenderer.current = stateRender.canUseRenderer;
    }, [stateRender.canUseRenderer]);

    useFirstRenderEffect(() => {
      gsap.set(mobileCloudWrapper.current, { opacity: 0 });
      gsap.set(cloudWrapper.current, { opacity: 0 });

      dispatchRender(addRenderObject(renderObject));
      dispatchRender(addCloud(cloud));
    }, [location]);

    useFirstRenderEffect(() => {
      gsap.set(background.current, { width: 0, height: 0, pointerEvents: "none" });
      gsap.to(componentWrapper.current, { width: 0, height: 0, pointerEvents: "none" });
      window.addEventListener("resize", () =>
        ElementResize(
          componentContentWrapper,
          maskWrapper,
          componentWrapper,
          componentContent,
          background,
          windowShown.current
        )
      );

      ElementResize(
        componentContentWrapper,
        maskWrapper,
        componentWrapper,
        componentContent,
        background,
        windowShown.current
      );
    }, []);

    const ShowComponent: DisplayElement = (element, transitionData) => {
      if (animations.current) {
        animations.current.kill();
      }

      gsap.set(componentContent.current, { pointerEvents: "auto" });
      gsap.set(mobileCloudWrapper.current, { opacity: 1 });
      gsap.set(cloudWrapper.current, { opacity: 1 });

      if (transitionData) {
        TransitionToContactForm(transitionData);

        return;
      }

      locomotiveScrollRef.current.scroll?.stop();
      windowShown.current = true;
      currentToggleRect.current = GetToggleRect(element);

      animations.current = GetForegroundAnimation(
        componentWrapper,
        maskWrapper,
        background,
        currentToggleRect.current,
        1.5,
        false,
        canUseRenderer.current
      ).play();
    };

    const HideComponent = () => {
      if (!windowShown.current) {
        return;
      }

      if (transitionAnimation.current) {
        transitionAnimation.current.progress(1);
        transitionAnimation.current.kill();
      }

      if (animations.current) {
        animations.current.kill();
      }

      gsap.set(componentContent.current, { pointerEvents: "none" });

      locomotiveScrollRef.current.scroll?.start();
      windowShown.current = false;

      if (componentWrapper.current) {
        currentToggleRect.current =
          currentToggleRect.current ?? GetToggleRect(componentWrapper.current);
      }

      if (currentToggleRect.current) {
        animations.current = GetForegroundAnimation(
          componentWrapper,
          maskWrapper,
          background,
          currentToggleRect.current,
          1.5,
          true,
          canUseRenderer.current
        )
          .timeScale(2)
          .reverse(1);
      }
    };

    const TransitionToContactForm = (elementRect: MutableRefObject<ElementRect | null>) => {
      if (animations.current) {
        animations.current.kill();
      }

      if (elementRect.current) {
        currentToggleRect.current = elementRect.current;
        animations.current = null;
      }

      if (!windowShown.current) {
        transitionAnimation.current = GetTransitionAnimation(
          componentWrapper,
          maskWrapper,
          background,
          cloudWrapper,
          1
        ).play();
        windowShown.current = true;
      }
    };

    const TransitionFromMenu = () => {
      if (animations.current) {
        animations.current.kill();
      }

      if (windowShown.current) {
        GetTransitionAnimation(componentWrapper, maskWrapper, background, cloudWrapper, 1, true)
          .timeScale(2)
          .reverse(1);
        windowShown.current = false;
      }
    };

    return (
      <>
        <MenuWrapperBackground ref={background} />
        <MenuWrapperContainer variant={variant} ref={componentWrapper}>
          <RendererWrapper
            mobileRef={mobileCloudWrapper}
            elementRef={cloudWrapper}
            variant={variant}
            style={{ opacity: 0 }}
            type="cloud"
            additionalData={cloudName}
          />
          <MenuWrapperMask ref={maskWrapper} />
          <MenuWrapperContentWrapper variant={variant} ref={componentContentWrapper}>
            <WrappedComponent
              componentContent={componentContent}
              HideComponent={HideComponent}
              ShowComponent={ShowComponent}
              location={location}
              TransitionFromMenu={TransitionFromMenu}
              currentToggleRect={currentToggleRect}
              openMenuCallback={openMenuCallback}
            />
          </MenuWrapperContentWrapper>
        </MenuWrapperContainer>
      </>
    );
  };
