import * as React from "react";
import { MutableRefObject } from "react";
import { isMobile } from "react-device-detect";

import CloudGroup, { Layers } from "components/rendering/groups/CloudGroup";
import ImageLayer from "components/rendering/layers/ImageLayer";
import { CloudElements } from "components/rendering/RenderingStages/RenderBackgroundStage";
import { ImageContextData } from "contexts/ImageContext";
import cloudSettings from "data/cloud-settings.json";
import gsap from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import * as PIXI from "pixi.js";
import { getWindowSize, isWindowUndefined, WindowSize } from "utils/window-utils";
import { v4 as UUID } from "uuid";

export const SetupStage = (stage, foregroundStage, windowSize: MutableRefObject<WindowSize>) => {
  stage.current.app.ticker.add(() => {
    const secondContext = foregroundStage.current.getContext("2d");
    const size = windowSize.current;

    secondContext.fillStyle = "black";
    secondContext.fillRect(0, 0, size.width, size.height);
    secondContext.drawImage(stage.current._canvas, 0, 0, size.width, size.height);
  });

  window.addEventListener("resize", () =>
    ResizeElement(stage, stage.current.app?.renderer, foregroundStage.current)
  );
  ResizeElement(stage, stage.current.app?.renderer, foregroundStage.current);
};

export const SetupPixi = () => {
  if (isWindowUndefined() || typeof window.PIXI !== "undefined") {
    return;
  }

  window.PIXI = PIXI;
  global.PIXI = PIXI;
  PIXI.utils.skipHello();
  gsap.registerPlugin(PixiPlugin);
};

const GetCanvasScale = (windowSize: WindowSize) => {
  const maxWidth = 3840;
  const maxHeight = 2160;

  return Math.max(Math.max(windowSize.width / maxWidth, windowSize.height / maxHeight), 1);
};

const GetCanvasSize = (windowSize: WindowSize) => {
  const canvasScale = GetCanvasScale(windowSize);

  return {
    width: windowSize.width / canvasScale,
    height: windowSize.height / canvasScale
  };
};

export const ResizeElement = (stage, stageRenderer, foregroundStage) => {
  if (isMobile) {
    return;
  }

  const windowSize = getWindowSize();
  const canvasSize = GetCanvasSize(windowSize);

  stage.current._canvas.width = canvasSize.width;
  stage.current._canvas.height = canvasSize.height;

  stageRenderer.view.style.width = windowSize.width + "px";
  stageRenderer.view.style.height = windowSize.height + "px";

  stageRenderer.view.width = canvasSize.width;
  stageRenderer.view.height = canvasSize.height;

  foregroundStage.style.width = windowSize.width + "px";
  foregroundStage.style.height = windowSize.height + "px";

  foregroundStage.width = windowSize.width;
  foregroundStage.height = windowSize.height;
  stageRenderer.resize(windowSize.width, windowSize.height);
};

export const RenderCloudGroups = (
  cloudElements: CloudElements,
  imageDatabase: ImageContextData
) => {
  return cloudElements
    .groupBy(element => element.group)
    .select(group => {
      const settings = group.key()?.includes("Project")
        ? cloudSettings.projectCloudSettings
        : cloudSettings.mainCloudSettings;

      return (
        <CloudGroup
          imageDatabase={imageDatabase}
          layers={group as Layers}
          key={UUID()}
          parent={group.firstOrDefault()!.wrapper!}
          settings={settings}
        />
      );
    });
};

export const RenderImageLayers = (
  imageElements: CloudElements,
  imageDatabase: ImageContextData
) => {
  return imageElements.select(element => {
    return (
      <ImageLayer
        key={element.id}
        imageData={imageDatabase[element.name].childImageSharp.gatsbyImageData}
        parent={element.wrapper!}
      />
    );
  });
};
