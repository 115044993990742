import React, { MutableRefObject, RefObject } from "react";

import { ForegroundStageWrapper } from "components/rendering/RenderingStages/RenderingStages.styled";

interface RenderForegroundStageProps {
  foregroundStage: RefObject<HTMLCanvasElement>;
  windowSize: MutableRefObject<{
    width: number;
    height: number;
  }>;
}

const RenderForegroundStage = ({ foregroundStage, windowSize }: RenderForegroundStageProps) => {
  return (
    <ForegroundStageWrapper
      ref={foregroundStage}
      className="masked-stage"
      {...windowSize.current}
    />
  );
};

export default RenderForegroundStage;
