import React from "react";

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { theme } from "theme/theme";

const ThemeProvider: Component = ({ children }) => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
