import React, { Key, useRef } from "react";

import RenderMask from "components/rendering/groups/RenderMask";
import type { MaskedGroupType } from "components/rendering/RenderingStages/RenderBackgroundStage";
import type { ImageContextData } from "contexts/ImageContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { Container as PIXIContainer } from "pixi.js";

interface MaskedGroupProps {
  maskedGroup: MaskedGroupType;
  imageDatabase: ImageContextData;
  containerKey: Key;
}

export const MaskedGroup = ({ maskedGroup, imageDatabase, containerKey }: MaskedGroupProps) => {
  const maskRef = useRef(null);
  const containerRef = useRef<PIXIContainer>(null);

  useFirstRenderEffect(() => {
    if (maskRef.current && containerRef.current) {
      containerRef.current.mask = maskRef.current;
    }
  });

  return (
    <RenderMask
      maskRef={maskRef}
      containerRef={containerRef}
      maskedGroup={maskedGroup}
      imageDatabase={imageDatabase}
      containerKey={containerKey}
    />
  );
};

export default MaskedGroup;
