import React, { useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import TrackedButton from "components/common/analytics/TrackedButton";
import CloseButton from "components/common/buttons/ButtonClose/ButtonClose";
import { ContactFormInternal } from "components/common/contact/contact-form/ContactForm/ContactForm.styled";
import ContactFormContent from "components/common/contact/contact-form/ContactFormContent/ContactFormContent";
import ContactFormResult from "components/common/contact/contact-form/ContactFormResult/ContactFormResult";
import ContactFormTitle from "components/common/contact/contact-form/ContactFormText/ContactFormText";
import {
  DisplayElement,
  MenuWrapper,
  type WrappedComponentProps
} from "components/other/MenuWrapper/MenuWrapper";
import { setDisplayForm, useContactContext } from "contexts/ContactFormContext";
import gsap from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

const WrappedContactForm = ({
  componentContent,
  HideComponent,
  ShowComponent,
  location
}: WrappedComponentProps) => {
  const contactFormResult = useRef<HTMLDivElement>(null);
  const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
  const formSubmitCallback = useRef<(success: boolean) => void>(() => null);
  const [, dispatchContact] = useContactContext();
  const showForm = useRef<DisplayElement | null>(null);
  const resetFormState = useRef<(() => void) | null>(null);

  useFirstRenderEffect(() => {
    gsap.to(contactFormResult.current, {
      opacity: 0,
      duration: 0,
      pointerEvents: "none",
      display: "none"
    });
    gsap.to(componentContent.current, {
      opacity: 1,
      duration: 0,
      pointerEvents: "none",
      display: "inline-grid",
      position: "relative"
    });

    formSubmitCallback.current = success => {
      setFormSubmitSuccessful(success);
      ShowResult();
    };
  }, [location]);

  useFirstRenderEffect(() => {
    showForm.current = ShowComponent;

    dispatchContact(setDisplayForm(showForm));
  }, [dispatchContact]);

  const HideContactForm = () => {
    HideComponent();
    HideResult();
    resetFormState.current && setTimeout(resetFormState.current, 300);
  };

  const ShowResult = () => {
    gsap.to(componentContent.current, {
      opacity: 0,
      duration: 0.5,
      pointerEvents: "none",
      display: "none"
    });
    gsap.to(contactFormResult.current, {
      opacity: 1,
      delay: 0.4,
      duration: 0.5,
      pointerEvents: "auto",
      display: "inline-grid"
    });
  };

  const HideResult = () => {
    gsap.to(contactFormResult.current, {
      opacity: 0,
      duration: 0.5,
      pointerEvents: "none",
      position: "absolute",
      display: "none"
    });
    gsap.to(componentContent.current, {
      opacity: 1,
      delay: 0.4,
      duration: 0.5,
      pointerEvents: "auto",
      display: "inline-grid",
      position: "relative"
    });
  };

  const ReturnFromForm = () => {
    if (formSubmitSuccessful === true) {
      HideComponent();
    } else {
      HideResult();
    }
  };

  return (
    <>
      <TrackedButton category="form" action="form-close">
        <CloseButton callback={HideContactForm} variant="contact-form-close-button" />
      </TrackedButton>
      <ContactFormInternal ref={componentContent}>
        <ContactFormTitle />
        <GoogleReCaptchaProvider reCaptchaKey="6Lc_qcUZAAAAAF1C7Bxhxhak0wrINNkPMAyUUCJY">
          <ContactFormContent
            resultCallback={formSubmitCallback.current}
            resetFormState={resetFormState}
          />
        </GoogleReCaptchaProvider>
      </ContactFormInternal>
      <ContactFormResult
        errors={formSubmitSuccessful}
        refDiv={contactFormResult}
        onClickButton={ReturnFromForm}
        location={location}
      />
    </>
  );
};

export default MenuWrapper({
  WrappedComponent: WrappedContactForm,
  cloudName: "VioletBlue",
  cloudGroup: "ContactFormCloud",
  variant: "contact-form"
});
