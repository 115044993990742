import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "@reach/router";
import {
  ChatIconButton,
  ChatIconImage,
  ChatIconWrapper,
  CloseChat
} from "components/layout/ChatIcon/ChatIcon.styled";
import { useContactContext } from "contexts/ContactFormContext";
import { registerScrollCallback, useScrollContext } from "contexts/ScrollContext";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";

interface ChatIconProps {
  chatEnabled: boolean;
}

export const ChatIcon = ({ chatEnabled = true }: ChatIconProps) => {
  const closeChatButton = useRef<HTMLButtonElement>(null);
  const openChatButton = useRef<HTMLButtonElement>(null);
  const [chatCurrentlyEnabled, setChatCurrentlyEnabled] = useState(chatEnabled);
  const [chatVisible, setChatVisible] = useState(false);
  const [chatAnimation, setChatAnimation] = useState();
  const hubspotFrameId = "#hubspot-messages-iframe-container";
  const currentHubspotWindow = useRef<Element | null>(null);
  const [, dispatch] = useScrollContext();
  const [{ displayForm }] = useContactContext();
  const location = useLocation();

  useFirstRenderEffect(() => {
    gsap.set(openChatButton.current, { bottom: 0 });

    const openingAnimation = gsap
      .to(openChatButton.current, { duration: 1.5, y: 0, ease: "back.out(2)", z: 10 })
      .pause();

    dispatch(
      registerScrollCallback({
        scrollPositionTrigger: 800,
        methodToInvoke: () => openingAnimation.play(),
        isOneShot: true
      })
    );

    return () => {
      currentHubspotWindow.current =
        currentHubspotWindow.current ?? document.querySelector(hubspotFrameId);

      if (currentHubspotWindow.current) {
        ResetHubspotChatPosition();
      }

      if (chatEnabled) {
        ToggleChat();
      }
    };
  });

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("blog")) {
      if (chatVisible) {
        ToggleChat();
      }

      setChatCurrentlyEnabled(false);
    } else {
      setChatCurrentlyEnabled(true);
    }
  }, [location.pathname]);

  const ToggleChat = () => {
    InvokeAnalyticsEvent(
      "click",
      window.location,
      "contact",
      !chatVisible ? "PAGENAME-chat-open" : "PAGENAME-chat-close",
      undefined,
      undefined
    );
    currentHubspotWindow.current =
      currentHubspotWindow.current ?? document.querySelector(hubspotFrameId);

    if (
      (typeof currentHubspotWindow.current === "undefined" ||
        currentHubspotWindow.current === null) &&
      openChatButton.current &&
      displayForm.current
    ) {
      displayForm.current(openChatButton.current);

      return;
    }

    let animation: any = chatAnimation;

    if (typeof animation === "undefined") {
      currentHubspotWindow.current?.appendChild(closeChatButton.current as Node);
      animation = CreateAnimation();
      setChatAnimation(animation);
    }

    if (chatVisible) {
      animation.progress(0.33).reverse();
    } else {
      animation.progress(0).play();
    }

    setChatVisible(prev => !prev);
  };

  const ResetHubspotChatPosition = () => {
    if (
      typeof currentHubspotWindow.current === "undefined" ||
      currentHubspotWindow.current === null
    ) {
      return;
    }

    gsap.set(currentHubspotWindow.current, {
      width: 436,
      height: 650,
      zIndex: 8,
      marginBottom: "-400vh",
      opacity: 0
    });
  };

  const CreateAnimation = () => {
    const timeline = gsap.timeline({
      onReverseComplete: () => ResetHubspotChatPosition()
    });
    const frameStart = {
      opacity: 0,
      marginBottom: -80,
      duration: 0.25,
      ease: Circ.easeOut,
      zIndex: 8
    };
    const frameEnd = { opacity: 1, marginBottom: 0, duration: 0.25, ease: Circ.easeOut, zIndex: 8 };

    timeline.fromTo(currentHubspotWindow.current, frameStart, frameEnd, 0);
    timeline.fromTo(
      openChatButton.current,
      { opacity: 1, y: 0, duration: 0.75, delay: 0, ease: "back.out(2)", z: 10 },
      { opacity: 0, y: 200, duration: 0.75, delay: 0, z: 10 },
      0
    );
    timeline.fromTo(closeChatButton.current, { y: 200 }, { y: 0, duration: 0.0 }, 0);

    return timeline;
  };

  const ChatHover = () => {
    InvokeAnalyticsEvent(
      "hover",
      window.location,
      "contact",
      !chatVisible ? "PAGENAME-chat-open" : "PAGENAME-chat-close",
      undefined,
      undefined,
      true
    );
  };

  return (
    <ChatIconWrapper enabled={chatCurrentlyEnabled}>
      <ChatIconButton ref={openChatButton} onClick={ToggleChat} onMouseEnter={ChatHover}>
        <ChatIconImage />
      </ChatIconButton>
      <CloseChat ref={closeChatButton} onClick={ToggleChat} onMouseEnter={ChatHover} />
    </ChatIconWrapper>
  );
};

export default ChatIcon;
