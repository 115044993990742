import React, { createContext, Dispatch, Reducer, useContext, useEffect, useReducer } from "react";

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticlePreviewData } from "components/pages/blog-common/ArticlePreview/ArticlePreview";
import { graphql, useStaticQuery } from "gatsby";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { sortBy } from "lodash";

/* ------------------------TYPES---------------------------- */
interface BlogContextData {
  selectedTag: string;
  selectedFilterWord: string;
  allArticlesPreviews: ArticlePreviewData[];
  selectedArticlesPreviews: ArticlePreviewData[];
  tagList: string[];
}

type BlogContextType = [BlogContextData, Dispatch<AnyAction>];

const DefaultBlogContextData: BlogContextData = {
  selectedTag: "",
  selectedFilterWord: "",
  allArticlesPreviews: [],
  selectedArticlesPreviews: [],
  tagList: [
    "Games",
    "News",
    "Apps",
    "Business",
    "VR",
    "AR",
    "Marketing",
    "Graphics",
    "Game Development",
    "Unity",
    "Design",
    "Programming",
    "Guide"
  ]
};

/* ------------------------REDUX------------------------ */
const BlogSlice = createSlice({
  name: "Blog",
  reducers: {
    setSelectedTag: (state, action: PayloadAction<string>) => {
      state.selectedTag = action.payload;
    },
    setSelectedFilterWord: (state, action: PayloadAction<string>) => {
      state.selectedFilterWord = action.payload;
    },
    setAllArticlesPreviews: (state, action: PayloadAction<ArticlePreviewData[]>) => {
      state.allArticlesPreviews = action.payload;
    },
    setSelectedArticlesPreviews: (state, action: PayloadAction<ArticlePreviewData[]>) => {
      state.selectedArticlesPreviews = action.payload;
    },
    setTagList: (state, action: PayloadAction<string[]>) => {
      state.tagList = action.payload;
    }
  },
  initialState: DefaultBlogContextData
});
export const {
  setSelectedTag,
  setSelectedFilterWord,
  setAllArticlesPreviews,
  setSelectedArticlesPreviews,
  setTagList
} = BlogSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const BlogContext = createContext<BlogContextType>([DefaultBlogContextData, () => null]);

export const BlogContextProvider: Component = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<BlogContextData, AnyAction>>(
    BlogSlice.reducer,
    DefaultBlogContextData
  );
  const articlesData = useStaticQuery(query);
  const allArticlesPreview: ArticlePreviewData[] = articlesData.allMarkdownRemark.edges;

  useFirstRenderEffect(() => {
    const sortedArticles = sortArticles(allArticlesPreview);

    dispatch(setAllArticlesPreviews(sortedArticles));
    dispatch(setSelectedArticlesPreviews(sortedArticles));

    const tags = sortedArticles
      .map(article => article.node.frontmatter.tags)
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index);

    dispatch(setTagList(tags));
  });

  useEffect(() => {
    filterContent();
  }, [state.selectedTag, state.selectedFilterWord]);

  const sortArticles = (articles: ArticlePreviewData[]): ArticlePreviewData[] => {
    return sortBy(articles, item => {
      const date: string = item.node.frontmatter.date;
      const reversedDate = date.split(".").reverse().join("-");

      return new Date(reversedDate);
    }).reverse();
  };

  const filterContent = () => {
    const isTagSelected = state.selectedTag.length > 0;
    const isFilterSelected = state.selectedFilterWord.length > 0;
    let list: ArticlePreviewData[] = state.allArticlesPreviews;

    if (isTagSelected) {
      list = list.filter(article => article.node.frontmatter.tags.includes(state.selectedTag));
    }

    if (isFilterSelected) {
      list = list.filter(article =>
        article.node.frontmatter.title
          .toLowerCase()
          .includes(state.selectedFilterWord.toLowerCase())
      );
    }

    list = sortArticles(list);

    dispatch(setSelectedArticlesPreviews(list));
  };

  return <BlogContext.Provider value={[state, dispatch]}> {children}</BlogContext.Provider>;
};

export const useBlogContext = (): BlogContextType => {
  return useContext(BlogContext);
};

export const query = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            description
            date
            author
            tags
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            authorImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          id
          html
          fields {
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;
