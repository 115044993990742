import * as React from "react";

import TrackedElement from "components/common/analytics/TrackedElement";
import {
  SocialIconImage,
  SocialIconLink,
  type SocialIconVariant,
  SocialIconWrapper
} from "components/layout/SocialIcons/SocialIcons.styled";

interface SocialIconProps {
  variant?: SocialIconVariant;
  image: string;
  name: string;
  url: string;
}

export const SocialIcon = ({ variant, image, name, url }: SocialIconProps) => {
  return (
    <TrackedElement category="navigation" action={"sidepanel-" + name}>
      <SocialIconWrapper variant={variant}>
        <SocialIconLink href={url} rel="noreferrer" target="_blank">
          <SocialIconImage src={image} alt={name} />
        </SocialIconLink>
      </SocialIconWrapper>
    </TrackedElement>
  );
};

export default SocialIcon;
