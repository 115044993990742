import { CalculateFontSize } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const FooterDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.5, desktop: 1.5 })}
  ${CalculateFontSize("line-height", { mobile: 2.5, tablet: 2.5, desktop: 2.5 })}
  ${({ theme: { fonts } }) => fonts.light};
`;

export const FooterAddressFont = css`
  p {
    ${({ theme: { fonts } }) => fonts.regular};
    ${CalculateFontSize("font-size", { mobile: 1.05, tablet: 1.2, desktop: 1.05 })}
    ${CalculateFontSize("line-height", { mobile: 1.3, tablet: 1.5, desktop: 1.3 })}
  }
`;

export const FooterCopyrightFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.24, tablet: 1.24, desktop: 1.24 })}
  ${CalculateFontSize("line-height", { mobile: 1.25, tablet: 1.25, desktop: 1.25 })}
  ${({ theme: { fonts } }) => fonts.regular};
  font-weight: 300;
  color: ${({ theme: { colors } }) => colors.black};
`;

export const FooterContactInfoFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.38, tablet: 1.45, desktop: 1.55 })}
  ${CalculateFontSize("line-height", { mobile: 1.6, tablet: 1.5, desktop: 1.5 })}
  ${({ theme: { fonts } }) => fonts.regular};
  font-weight: 500;
  color: ${({ theme: { colors } }) => colors.black};
`;
