export type ThunderData = {
  x: number;
  y: number;
  size: number;
  rotation: number;
  imageName: string;
};

export const GetCloudColorSet = (color: string): string[] => {
  const colorSet = {
    BluePink: ["BluePink1", "BluePink2", "BluePink3"],
    DarkPurpleBlue: ["DarkPurpleBlue1", "DarkPurpleBlue2", "DarkPurpleBlue3"],
    Green: ["Green1", "Green2", "Green3"],
    PinkViolet: ["PinkViolet1", "PinkViolet2", "PinkViolet3"],
    PinkViolet2: ["PinkViolet21", "PinkViolet22", "PinkViolet23"],
    RedOrange: ["RedOrange1", "RedOrange2", "RedOrange3"],
    VioletBlack: ["VioletBlack1", "VioletBlack2"],
    VioletBlue: ["VioletBlue1", "VioletBlue2", "VioletBlue3"],
    YellowOrange: ["YellowOrange1", "YellowOrange2", "YellowOrange3"]
  };

  if (color in colorSet) {
    return colorSet[color];
  } else {
    console.log("Error in getting cloud set! " + color);

    return [];
  }
};

export const GetThunderSet = (color: string): Record<string, ThunderData[]> => {
  const thunderSet = {
    BluePink: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "BluePinkThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "BluePinkThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "BluePinkThunder1"
        }
      ]
    },
    DarkPurpleBlue: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "DarkPurpleBlueThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "DarkPurpleBlueThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "DarkPurpleBlueThunder1"
        }
      ]
    },
    Green: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "GreenThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "GreenThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "GreenThunder1"
        }
      ]
    },
    PinkViolet: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "PinkVioletThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "PinkVioletThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "PinkVioletThunder1"
        }
      ]
    },
    PinkViolet2: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "PinkViolet2Thunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "PinkViolet2Thunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "PinkViolet2Thunder1"
        }
      ]
    },
    RedOrange: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "RedOrangeThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "RedOrangeThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "RedOrangeThunder1"
        }
      ]
    },
    VioletBlack: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "VioletBlackThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "VioletBlackThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "VioletBlackThunder1"
        }
      ]
    },
    VioletBlue: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "VioletBlueThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "VioletBlueThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "VioletBlueThunder1"
        }
      ]
    },
    YellowOrange: {
      thunders: [
        {
          x: 30,
          y: 35,
          size: 30,
          rotation: 30,
          imageName: "YellowOrangeThunder1"
        },
        {
          x: 30,
          y: 60,
          size: 35,
          rotation: 80,
          imageName: "YellowOrangeThunder2"
        },
        {
          x: 65,
          y: 50,
          size: 40,
          rotation: 120,
          imageName: "YellowOrangeThunder1"
        }
      ]
    }
  };

  if (color in thunderSet) {
    return thunderSet[color];
  } else {
    console.log("Error in getting thunder set! " + color);

    return { thunders: [] };
  }
};
