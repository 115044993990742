import { CalculateFromWidth } from "styled/base/SizeFunctions";
import styled, { css } from "styled-components";

export type CloseButtonVariant = "main-menu-close-button" | "contact-form-close-button";

export const CloseButtonImage = styled.img`
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-right: auto;
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-right: auto;
    margin-top: auto;
  }
  ${CalculateFromWidth("margin-right", { desktop: 3.7 })}
  ${CalculateFromWidth("margin-top", { desktop: 3 })}
  ${CalculateFromWidth("height", { mobile: 7, tablet: 5, desktop: 2.5 })}
  ${CalculateFromWidth("width", { mobile: 7, tablet: 5, desktop: 2.5 })}
`;

const ContactFormCloseButton = css`
  ${CalculateFromWidth("margin-right", { mobile: 4, tablet: 4 })}
  ${CalculateFromWidth("margin-top", { mobile: 4, tablet: 5 })}
  ${CalculateFromWidth("height", { mobile: 6, tablet: 10, desktop: 6 })}
  ${CalculateFromWidth("width", { mobile: 6, tablet: 10, desktop: 7 })}
  position: fixed;
  z-index: 12;
  right: 0;
`;

const MainMenuCloseButton = css`
  ${CalculateFromWidth("margin-right", { mobile: 7, tablet: 2 })}
  ${CalculateFromWidth("margin-top", { mobile: 8, tablet: 4 })}
  ${CalculateFromWidth("height", { mobile: 6, tablet: 10, desktop: 6 })}
  ${CalculateFromWidth("width", { mobile: 6, tablet: 10, desktop: 7 })}
  position: absolute;
  z-index: 8;
  right: 0;
`;

export const CloseButtonWrapper = styled.button<{ variant: CloseButtonVariant }>`
  ${({ variant }) => variant === "main-menu-close-button" && MainMenuCloseButton}
  ${({ variant }) => variant === "contact-form-close-button" && ContactFormCloseButton}
`;
