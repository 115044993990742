import React, { createContext, Dispatch, Reducer, useContext, useEffect, useReducer } from "react";
import { isFirefox, isMacOs, isMobile, isSafari } from "react-device-detect";

import { AnyAction, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { getGPUTier } from "detect-gpu";
import { GetCloudColorSet, GetThunderSet, ThunderData } from "providers/CloudThunderDataProvider";
import { CreateRenderElement } from "providers/RenderElementsProvider";
import { isWindowUndefined } from "utils/window-utils";
import { v4 as UUID } from "uuid";

/* ------------------------TYPES---------------------------- */
interface RenderObjectData {
  name: string;
  wrapper: HTMLDivElement | null;
  group?: string;
  type?: string;
  addThunders?: boolean;
  additionalData?: ThunderData;
  maskWrapper?: HTMLDivElement | null;
}

export type RenderElement = RenderObjectData & { id: UUID };

interface RenderContextData {
  canUseRenderer: boolean;
  renderElements: RenderElement[];
}

type RenderContextType = [RenderContextData, Dispatch<AnyAction>];

const DefaultRenderContextData: RenderContextData = {
  canUseRenderer: false,
  renderElements: []
};

/* ------------------------REDUX------------------------ */
const RenderSlice = createSlice({
  name: "Render",
  reducers: {
    changeCanUserRenderer: (state, action: PayloadAction<boolean>) => {
      state.canUseRenderer = action.payload;
    },
    clearRenderElements: state => {
      state.renderElements = [];
    },
    addRenderObject: (state, action: PayloadAction<RenderObjectData>) => {
      const { name, type, wrapper, group, additionalData, maskWrapper } = action.payload;

      state.renderElements = [
        ...state.renderElements,
        CreateRenderElement(
          UUID(),
          name,
          type,
          wrapper,
          group,
          additionalData,
          maskWrapper
        ) as Draft<RenderElement>
      ];
    },
    addCloud: (state, action: PayloadAction<RenderObjectData>) => {
      const { name, wrapper, group, addThunders = true, maskWrapper } = action.payload;

      GetCloudColorSet(name).forEach(cloudName => {
        state.renderElements = [
          ...state.renderElements,
          CreateRenderElement(
            UUID(),
            cloudName,
            "CloudLayer",
            wrapper,
            group,
            undefined,
            maskWrapper
          ) as Draft<RenderElement>
        ];
      });

      if (!addThunders) {
        return;
      }

      GetThunderSet(name).thunders.forEach(thunder => {
        state.renderElements = [
          ...state.renderElements,
          CreateRenderElement(
            UUID(),
            thunder.imageName,
            "ThunderLayer",
            wrapper,
            group,
            thunder,
            maskWrapper
          ) as Draft<RenderElement>
        ];
      });
    }
  },
  initialState: DefaultRenderContextData
});
export const { changeCanUserRenderer, clearRenderElements, addRenderObject, addCloud } =
  RenderSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const RenderContext = createContext<RenderContextType>([
  DefaultRenderContextData,
  () => null
]);

export const RenderContextProvider: Component = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<RenderContextData, AnyAction>>(
    RenderSlice.reducer,
    DefaultRenderContextData
  );

  const UpdateRenderer = async () => {
    const result = await getGPUTier();

    dispatch(changeCanUserRenderer(GetCanUseRenderer(result.tier)));
  };

  const GetCanUseRenderer = (tier: number) => {
    if (isWindowUndefined()) {
      return true;
    }

    return tier >= 2;
  };

  useEffect(() => {
    UpdateRenderer();
  }, []);

  return <RenderContext.Provider value={[state, dispatch]}>{children}</RenderContext.Provider>;
};

export const useRenderContext = (): RenderContextType => {
  return useContext(RenderContext);
};

export const IsDeviceRenderReady = (): boolean => {
  return !isMobile && !isSafari && !(isMacOs && isFirefox);
};
