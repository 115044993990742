import {
  AboutFont,
  AdvantageHeaderFont,
  CallToActionArticleHeaderFont,
  CallToActionHeaderFont,
  HeaderMainFont,
  HeaderSectionFont,
  LetsTalkFont,
  ProjectHeaderFont,
  ProjectHeaderSmallFont
} from "styled/common/font-styles/CommonFonts";
import { AnimatedTransformOpacityMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type HeaderTextVariant =
  | "project-header-font"
  | "project-header-small-font"
  | "lets-talk-font"
  | "about-paragraph"
  | "call-to-action-header"
  | "advantage-header"
  | "call-to-action-article-header"
  | "automation-header";

export type HeaderTextProps = {
  variant?: HeaderTextVariant;
  disableDefaultStyles?: boolean;
  isTitle?: boolean;
};

export const HeaderText = styled.span<HeaderTextProps>`
  ${({ variant }) => variant === "project-header-font" && ProjectHeaderFont}
  ${({ variant }) => variant === "project-header-small-font" && ProjectHeaderSmallFont}
  ${({ variant }) => variant === "lets-talk-font" && LetsTalkFont}
  ${({ variant }) => variant === "about-paragraph" && AboutFont}
  ${({ variant }) => variant === "call-to-action-header" && CallToActionHeaderFont}
  ${({ variant }) => variant === "advantage-header" && AdvantageHeaderFont}
  ${({ variant }) => variant === "call-to-action-article-header" && CallToActionArticleHeaderFont}
  ${({ variant }) =>
    !variant &&
    css`
      display: inline-block;
      ${AnimatedTransformOpacityMixin}
    `}
  ${({ variant, disableDefaultStyles, isTitle }) =>
    !variant && !disableDefaultStyles && (isTitle ? HeaderMainFont : HeaderSectionFont)}
`;

export const HeadersWrapper = styled.h1`
  display: contents;
`;
