import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { AnimatedBackground, AnimatedMask, AnimatedWrapper } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type MenuWrapperVariant = "contact-form" | "main-menu";

export const MenuWrapperBackground = styled.div`
  background-color: ${({ theme: { colors } }) => colors.black};
  opacity: 0;
  z-index: 7;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  pointer-events: none;
  transform: translateZ(14px);
  ${AnimatedBackground}
`;
const ContactFormWrapper = css`
  z-index: 11;
  clip-path: circle(0% at 30% 20%);
  ${AnimatedWrapper}
`;
const MainMenuWrapper = css`
  z-index: 10;
  clip-path: circle(0% at 20% 30%);
  ${AnimatedWrapper}
`;

export const MenuWrapperContainer = styled.div<{ variant: MenuWrapperVariant }>`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  transform: translateZ(20px);
  ${({ variant }) => variant === "contact-form" && ContactFormWrapper}
  ${({ variant }) => variant === "main-menu" && MainMenuWrapper}
`;

export const MenuWrapperMask = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: -10;
  ${AnimatedMask}
`;

const ContactFormContentWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    max-height: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-height: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    height: fit-content;
  }
  ${CalculateFromHeight("height", { tablet: 200, desktop: 100 })}
  ${CalculateFromHeight("min-height", { mobile: 100, tablet: 100, desktop: 90 })}
  ${CalculateFromWidth("width", { mobile: 100, tablet: 100, desktop: 100 })}
`;
const MainMenuContentWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    height: 100%;
  }
  ${CalculateFromHeight("min-height", { mobile: 120, tablet: 80, desktop: 70 })}
  ${CalculateFromHeight("height", { mobile: 200, tablet: 200 })}
  ${CalculateFromWidth("width", { mobile: 100, tablet: 100, desktop: 100 })}
  max-height: 100%;
`;

export const MenuWrapperContentWrapper = styled.div<{ variant: MenuWrapperVariant }>`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${({ variant }) => variant === "contact-form" && ContactFormContentWrapper}
  ${({ variant }) => variant === "main-menu" && MainMenuContentWrapper}
`;
