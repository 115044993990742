import { MouseEvent, useRef, useState } from "react";
import React from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import TrackedElement from "components/common/analytics/TrackedElement";
import CloseButton from "components/common/buttons/ButtonClose/ButtonClose";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import DreamStormLogo from "components/common/other/DreamstormLogo/DreamstormLogo";
import {
  HeaderLink,
  HeaderLogoWrapperMenu,
  HeaderMenu,
  MainMenuContent,
  MainMenuCopyrightLeft,
  MainMenuCtaButton,
  MainMenuLinksWrapper,
  MenuCopyrightWrapper
} from "components/layout/Menu/MainMenu.styled";
import MainMenuLink from "components/layout/Menu/MainMenuLink";
import SidePanel from "components/layout/SidePanel/SidePanel";
import SocialIcons from "components/layout/SocialIcons/SocialIcons";
import { MenuWrapper, type WrappedComponentProps } from "components/other/MenuWrapper/MenuWrapper";
import { clearRenderElements, useRenderContext } from "contexts/RenderContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { blockLink } from "utils/blockLink";

import MainMenuChildrenLink from "./MainMenuChildrenLink";

const WrappedMainMenu = ({
  componentContent,
  HideComponent,
  ShowComponent,
  location,
  TransitionFromMenu,
  currentToggleRect,
  openMenuCallback
}: WrappedComponentProps) => {
  const [menuLinks, setMenuLinks] = useState<JSX.Element[]>([]);
  const borderWrapper = useRef<HTMLDivElement>(null);
  const logotype = useRef<HTMLImageElement>(null);
  const [, dispatchRender] = useRenderContext();

  useFirstRenderEffect(() => {
    setMenuLinks(getMenuLinks(location));
    dispatchRender(clearRenderElements());
  }, [location]);

  useFirstRenderEffect(() => {
    if (openMenuCallback) {
      openMenuCallback.current = ShowComponent;
    }
  }, []);

  const getMenuLinks = (location: Location) => {
    return pages
      .map(item => {
        if (item.location && item.location !== location.pathname) {
          return (
            <MainMenuLink
              key={item.location}
              hideMainMenu={HideComponent}
              location={item.location}
              text={item.text}
            />
          );
        } else if (item.children) {
          return (
            <MainMenuChildrenLink
              key={item.text}
              childrenPages={item.children}
              hideMainMenu={HideComponent}
              currentLocation={location.pathname}
              text={item.text}
            />
          );
        }
      })
      .filter((item): item is JSX.Element => item !== undefined);
  };

  return (
    <>
      <SidePanel animateNavigation={false}>
        <HeaderMenu>
          <TrackedElement category="navigation" action="menu-logo">
            <HeaderLink
              onClick={(e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) =>
                blockLink(e, "/")
              }
              to="/"
            >
              <HeaderLogoWrapperMenu ref={borderWrapper}>
                <DreamStormLogo
                  wrapperVariant="header-logo"
                  logoVariant="logotype"
                  color="white"
                  reference={logotype}
                />
              </HeaderLogoWrapperMenu>
            </HeaderLink>
          </TrackedElement>
        </HeaderMenu>
      </SidePanel>
      <TrackedButton category="form" action="main-menu-close">
        <CloseButton callback={HideComponent} variant="main-menu-close-button" />
      </TrackedButton>
      <MainMenuContent ref={componentContent}>
        <DreamStormLogo wrapperVariant="main-menu-logo" logoVariant="logo" color="white" />
        <MainMenuLinksWrapper smaller={menuLinks?.length > 4}>{menuLinks}</MainMenuLinksWrapper>
        <SocialIcons inMenu />
        <MenuCopyrightWrapper>
          <MainMenuCopyrightLeft>Made by DreamStorm Studios © 2020</MainMenuCopyrightLeft>
        </MenuCopyrightWrapper>
        <SocialIcons inMenu />
        <MainMenuCtaButton>
          <TrackedButton category="form" action="PAGENAME-menu-form-open">
            <CtaButton onClick={TransitionFromMenu} transitionData={currentToggleRect} />
          </TrackedButton>
        </MainMenuCtaButton>
      </MainMenuContent>
    </>
  );
};

export default MenuWrapper({
  WrappedComponent: WrappedMainMenu,
  cloudName: "PinkViolet2",
  cloudGroup: "MainMenuCloud",
  variant: "main-menu"
});

const servicesPages = [
  {
    text: "Menu_Services_Automation",
    location: "/automation/"
  },
  {
    text: "Menu_Services_Gamedev",
    location: "/game-development/"
  },
  {
    text: "Menu_Services_Webdev",
    location: "/web-development/"
  }
];

const pages = [
  {
    text: "Menu_Home",
    location: "/"
  },
  {
    text: "Menu_About",
    location: "/about/"
  },
  {
    text: "Menu_OurWork",
    location: "/projects/"
  },
  {
    text: "Menu_Services",
    children: servicesPages
  },
  {
    text: "Menu_Contact",
    location: "/contact/"
  },
  {
    text: "Menu_Blog",
    location: "/blog/"
  }
];
