import { CalculateFromWidth } from "styled/base/SizeFunctions";
import styled, { css } from "styled-components";

import {
  AnimatedChildLinkHeaderBeforeFont,
  AnimatedLinkHeaderBeforeFont,
  AnimatedLinkMenuBeforeFont
} from "./font-styles/CommonFonts";

export const AnimatedLinksAnimatedSpan = styled.span`
  position: relative;
  display: inline-block;
  transition: transform 0.3s;
  cursor: pointer;
  ${CalculateFromWidth("top", { mobile: 0, tablet: 0, desktop: 0 })}
`;

const AnimatedLinkHeaderTextWrapperMixin = css`
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 50%;
  width: 100%;
  line-height: 1.2;
  ${CalculateFromWidth("height", { mobile: 1.6, tablet: 1.6, desktop: 1.6 })}
  ${AnimatedLinksAnimatedSpan} {
    &::before {
      ${AnimatedLinkHeaderBeforeFont}
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
    }
  }
`;

export const AnimatedLinks = css`
  & a:hover,
  & a:focus {
    ${AnimatedLinksAnimatedSpan} {
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
`;

export const AnimatedLinkHeaderTextWrapper = styled.span`
  ${AnimatedLinkHeaderTextWrapperMixin}
`;

export const AnimatedLinkMenuTextWrapper = styled.span`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${CalculateFromWidth("height", { desktop: 3.1 })}
  ${AnimatedLinksAnimatedSpan} {
    &::before {
      ${AnimatedLinkMenuBeforeFont}
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const AnimatedChildMenuTextWrapper = styled.span`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${CalculateFromWidth("height", { desktop: 3.1 })}
  ${AnimatedLinksAnimatedSpan} {
    &::before {
      ${AnimatedChildLinkHeaderBeforeFont}
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      transform: translate3d(0, 0, 0);
    }
  }
`;
