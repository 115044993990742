import { RefObject } from "react";

import gsap from "gsap";
import { getWindowSize } from "utils/window-utils";

export const ElementResize = <T extends HTMLElement>(
  contentWrapper: RefObject<T>,
  maskWrapper: RefObject<T>,
  elementWrapper: RefObject<T>,
  elementContent: RefObject<T>,
  elementBackground: RefObject<T>,
  windowShown: boolean
) => {
  if (contentWrapper.current === undefined || contentWrapper.current === null) {
    return;
  }

  const windowSize = getWindowSize();
  const rect = contentWrapper.current.getBoundingClientRect();
  const targetScale = Math.min(
    Math.min(windowSize.width / rect.width, 1),
    Math.min(windowSize.height / rect.height, 1)
  );

  gsap.set(elementContent.current, {
    scale: targetScale,
    y: (-rect.height * (1 - targetScale)) / 2
  });

  if (windowShown) {
    const clipValue = windowSize.width > windowSize.height ? "280vw" : "280vh";

    gsap.set(".masked-stage", { clipPath: "circle(" + clipValue + " at 0vw 0vw" });
    gsap.set(maskWrapper.current, {
      width: clipValue,
      height: clipValue,
      top: rect.height,
      left: rect.width
    });
    gsap.set(elementWrapper.current, { clipPath: "circle(" + clipValue + " at 0vw 0vw" });
  } else {
    gsap.set(elementBackground.current, {
      pointerEvents: "none",
      width: "0px",
      height: "0px",
      opacity: 0
    });
    gsap.set(elementWrapper.current, {
      pointerEvents: "none",
      width: "0px",
      height: "0px",
      opacity: 0
    });
  }
};
