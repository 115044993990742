import React from "react";

import { StaticImage } from "components/rendering/RenderStaticElements/RenderStaticElements.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";

interface GetStaticImageProps {
  imageName: string;
}

const RenderStaticImage = ({ imageName }: GetStaticImageProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const image = imageDatabase[imageName].childImageSharp.gatsbyImageData;

  return <StaticImage image={image} key={imageName} alt={imageName} />;
};

export default RenderStaticImage;
