import { css } from "styled-components";

export const GridLayout = css`
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: repeat(3, auto);
`;
export const GridHeader = css`
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
`;
export const GridContent = css`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
`;
export const GridFooter = css`
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
`;
