import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactFormDescriptionFont,
  ContactFormTitleFont
} from "styled/common/font-styles/ContactFormFonts";
import styled from "styled-components";

export const ContactFormText = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(3, 1fr);
    position: relative;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 1fr);
    position: relative;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
  ${CalculateFromHeight("height", { mobile: 60, tablet: 35, desktop: 45 })}
  color: white;
  width: 100%;
  display: grid;
`;

export const ContactFormTitleContent = styled.h4`
  ${ContactFormTitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 4 / span 7;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 8;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 2 / span 3;
  }
  ${CalculateFromWidth("margin-right", { mobile: 6.4, desktop: 4 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 4.5, desktop: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 3, tablet: 5, desktop: 10 })}
`;

export const ContactFormDescription = styled.p`
  ${ContactFormDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 4 / span 7;
    grid-row: 3 / span 1;
    position: absolute;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 7;
    grid-row: 2 / span 1;
    position: absolute;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 5 / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 12 })}
  ${CalculateFromHeight("bottom", { desktop: 5 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 4, tablet: 4, desktop: 4 })}
`;
