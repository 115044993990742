import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { GridContent, GridLayout } from "styled/common/Grid";
import { AnimatedTransform, Preserve3dMixin } from "styled/common/Utils";
import styled from "styled-components";

export const LayoutWrapper = styled.div`
  color: white;
  ${({ theme: { fonts } }) => fonts.regular};
  ${Preserve3dMixin};
  ${({ theme: { breakpoints } }) => breakpoints.mobileMinWidth} {
    width: 100%;
    max-width: 100vw;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${GridLayout}
  }
`;

export const Content = styled.main`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  transform: translateZ(0px);
  pointer-events: all;
  ${({ theme: { breakpoints } }) => breakpoints.mobileMinWidth} {
    width: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${GridContent}
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktopMinWidth} {
    ${CalculateFromWidth("max-width", { desktop: 100 })}
  }
`;

export const ScrollContainer = styled.div`
  position: relative;
  ${Preserve3dMixin}
  pointer-events: none;
`;

export const ScrollSection = styled.div`
  ${AnimatedTransform}
  ${Preserve3dMixin}
`;

export const PageScrollContainer = styled.div`
  ${Preserve3dMixin}
`;
