import type { ProjectProps } from "components/common/project/ProjectPreview/ProjectPreview";
import { CustomEventArgs, trackCustomEvent } from "gatsby-plugin-google-analytics";
import Cookies from "js-cookie";
import { replaceMinusRegex, replaceSlashRegex } from "utils/regularExpressions";
import { isWindowUndefined } from "utils/window-utils";

const pageNamePlaceholder = "PAGENAME";

export const InvokeAnalyticsEvent = (
  type: string,
  location: Location,
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction = false
) => {
  if (isWindowUndefined()) {
    return;
  }

  const cookieRaw = Cookies.get("cookieConsent");

  if (typeof cookieRaw === "undefined" || cookieRaw === null) {
    return;
  }

  const currentConsent = JSON.parse(Cookies.get("cookieConsent"));

  if (currentConsent.agreedToPrivacyPolicy !== true) {
    //return; //We don't track users on other sites, just the on-site analytics
  }

  action = action.replace(pageNamePlaceholder, GetPageNameFromPath(location.pathname));
  label = label ? label : type;

  const customEventData: CustomEventArgs = {
    category: category.toLowerCase(),
    action: action.toLowerCase(),
    label: label.toLowerCase(),
    value: value,
    nonInteraction: nonInteraction
  };

  trackCustomEvent(customEventData);
};

export const GetOpenProjectAction = (projectData: ProjectProps): string => {
  const actionName = pageNamePlaceholder + "-open-project-";

  return actionName + GetProjectPageName(projectData.url);
};

const GetProjectPageName = (path: string): string => {
  return path
    .split("/")
    .filter(item => item !== "")[1]
    .replace(replaceMinusRegex, "");
};

export const GetPageNameFromPath = (path: string): string => {
  if (path.split("").filter(item => item === "/").length > 2) {
    return GetProjectPageName(path);
  }

  let pageName = path.replace(replaceSlashRegex, "");

  if (pageName === "") {
    pageName = "home";
  }

  return pageName + "";
};
