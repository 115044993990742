import React, { RefObject } from "react";

import ErrorBody from "components/common/contact/contact-form/ContactFormError/ContactFormError";
import { ContactFormResultWrapper } from "components/common/contact/contact-form/ContactFormResult/ContactFormResult.styled";
import SuccessBody from "components/common/contact/contact-form/ContactFormSuccess/ContactFormSuccess";

export interface ContactFormResultProps {
  errors: boolean;
  refDiv: RefObject<HTMLDivElement>;
  onClickButton: () => void;
  location: Location;
}

export const ContactFormResult = ({ errors, refDiv, onClickButton }: ContactFormResultProps) => {
  return (
    <ContactFormResultWrapper ref={refDiv}>
      {errors ? <SuccessBody onClick={onClickButton} /> : <ErrorBody onClick={onClickButton} />}
    </ContactFormResultWrapper>
  );
};

export default ContactFormResult;
