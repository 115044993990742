import { DefaultTheme } from "styled-components";

export const desktopSizes = {
  maxWidth: 1600,
  minWidth: 1024,
  maxHeight: 1080,
  minHeight: 500
};
export const tabletSizes = {
  maxWidth: 1023,
  minWidth: 767,
  maxHeight: 1200
};
export const mobileSizes = {
  maxWidth: 767,
  minWidth: 200,
  maxHeight: 720
};

export const theme: DefaultTheme = {
  breakpoints: {
    desktop: `@media screen and (min-width: ${desktopSizes.minWidth}px)`,
    tablet: `@media screen and (min-width: ${tabletSizes.minWidth}px) and (max-width: ${tabletSizes.maxWidth}px)`,
    mobile: `@media screen and (max-width: ${tabletSizes.minWidth}px)`,
    tabletDesktop: `@media screen and (min-width: ${tabletSizes.minWidth}px)`,
    tabletMobile: `@media screen and (max-width: ${tabletSizes.maxWidth}px)`,
    mobileMinWidth: `@media screen and (min-width: ${mobileSizes.minWidth}px)`,
    desktopMaxHeight: `@media screen and (max-height: ${desktopSizes.minHeight}px)`,
    desktopMinWidth: `@media screen and (min-width: ${desktopSizes.maxWidth}px)`
  },
  colors: {
    primary: `rgb(231, 231, 231)`,
    white: `#fff`,
    black: `#000`,
    footer: `#5ee8de`,
    games: `#e4469f`,
    ar: `#637cfb`,
    turquoise: "#5be8e0",
    grey: `#6a6a6a`,
    yellow: "#efa642"
  },
  fonts: {
    regular: `font-family: TTFirsNeue-Regular;`,
    medium: `font-family: TTFirsNeue-Medium;`,
    demiBold: `font-family: TTFirsNeue-DemiBold;`,
    bold: `font-family: TTFirsNeue-Bold;`,
    light: `font-family: TTFirsNeue-Light;`,
    extraLight: `font-family: TTFirsNeue-ExtraLight;`,
    blog: `font-family: Work Sans, sans-serif;`
  }
};
