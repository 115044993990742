import { FieldError } from "react-hook-form";

import Translate from "utils/translation/translation";
import * as yup from "yup";

export interface ContactFormValues {
  email: string;
  message: string;
  phone?: string;
  acceptedTerms?: boolean;
}

export interface ContactFormErrors {
  email?: FieldError;
  message?: FieldError;
  phone?: FieldError;
  acceptedTerms?: FieldError;
}

export const contactFormSchema: yup.SchemaOf<ContactFormValues> = yup.object().shape({
  email: yup
    .string()
    .email(Translate("Contact_Error_InvalidEmail"))
    .required(Translate("Contact_Error_Required")),
  message: yup
    .string()
    .max(500, Translate("Contact_Error_TooLong"))
    .required(Translate("Contact_Error_Required")),
  phone: yup.string(),
  acceptedTerms: yup.boolean().oneOf([true], Translate("Contact_Error_AcceptConditions"))
});
