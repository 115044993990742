import { Link } from "gatsby";
import {
  CalculateFontSize,
  CalculateFromHeight,
  CalculateFromWidth
} from "styled/base/SizeFunctions";
import { AnimatedLinks } from "styled/common/AnimatedLinks";
import {
  CommonLightBigFont,
  CommonLink,
  MainMenuChildLinkFont,
  StandardFont
} from "styled/common/font-styles/CommonFonts";
import { MainMenuLinkFont } from "styled/common/font-styles/MainMenuFonts";
import { CenterFlex, SizeFillSpaceMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

const mainMenuColumns = 12;
const mainMenuRows = 8;

export const MainMenuContent = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(8, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(10, 1fr);
    ${SizeFillSpaceMixin}
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(10, 1fr);
    ${SizeFillSpaceMixin}
  }
  ${CalculateFromWidth("width", { desktop: 100 })}
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  margin: 0 auto auto auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  transition: transform 0.1s linear, grid-template-rows 0.1s linear;
`;

const ElementMainMenuStyles = css`
  ${CommonLink}
  ${MainMenuLinkFont}
  ${CalculateFromHeight("padding-top", { desktop: 0.2 })}
  ${CalculateFromHeight("height", { mobile: 7, tablet: 5, desktop: 8 })}
  ${CalculateFontSize("font-size", { mobile: 3.8, tablet: 3.8, desktop: 5 })}
  ${CalculateFontSize("line-height", { mobile: 4.5, tablet: 4.5, desktop: 5 })}
  position: relative;
  overflow: hidden;
`;

export const LinkMainMenu = styled(Link)`
  ${ElementMainMenuStyles}
`;

export const ElementMainMenuChildren = styled.a`
  ${ElementMainMenuStyles}
  cursor: pointer;
`;

const SmallerMenuLinksWrapperShared = css`
  ${CalculateFromHeight("padding-top", { desktop: 0.2 })}
  ${CalculateFromHeight("height", { mobile: 12, tablet: 7.4, desktop: 7.4 })}
`;

const MainMenuSmallerLinksWrapper = css`
  ${LinkMainMenu} {
    ${SmallerMenuLinksWrapperShared}
  }
  ${ElementMainMenuChildren} {
    ${SmallerMenuLinksWrapperShared}
  }
`;

export const MainMenuLinksWrapper = styled.div<{ smaller: boolean }>`
  ${AnimatedLinks}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 3 / span 8;
    grid-row: 2 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 10;
    grid-row: 2 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 10;
    grid-row: 3 / span 4;
  }
  ${CalculateFromWidth("margin-top", { tablet: 6 })}
  ${({ smaller }) => smaller && MainMenuSmallerLinksWrapper}

  transition: margin-bottom 0.1s linear, margin-left 0.1s linear, grid-row 0.1s linear, grid-column 0.1s linear;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  overflow: visible;
`;

export const MainMenuSubtitle = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 4;
    grid-row: 6 / span 2;
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 7;
    grid-row: 7 / span 2;
    margin-left: calc(calc(100vw / ${mainMenuColumns}) / 2);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 7;
    grid-row: 6 / span 2;
    margin-left: calc(calc(100vw / ${mainMenuColumns}) / 2);
  }
  ${CalculateFromWidth("margin-left", { desktop: 0.5 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 3 })}
`;

export const MainMenuDescription = styled.p`
  ${CommonLightBigFont}
`;

export const MainMenuCopyrightLeft = styled.p`
  float: left;
  position: relative;
  ${StandardFont}
`;

export const MenuCopyrightWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 5;
    grid-row: 8 / span 1;
    margin-bottom: calc(calc(100vh / ${mainMenuRows}) / 4);
    margin-right: calc(100vw / ${mainMenuColumns}) * 0.75;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 7 / span 6;
    grid-row: 10 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 11;
    grid-row: 10 / span 1;
  }
  ${CalculateFromWidth("margin-left", { desktop: 0.5 })}
  ${CalculateFromHeight("margin-top", { tablet: 3.1 })};
`;

export const MainMenuCtaButton = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 10 / span 3;
    grid-row: 5 / span 4;
    margin-right: calc(calc(100vw / ${mainMenuColumns}) / 4);
    margin-top: auto;
    margin-bottom: calc(calc(100vh / ${mainMenuRows}) / 4);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
`;

export const MainMenuLogo = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;
  }
  ${CalculateFromWidth("margin-left", { mobile: 12.5, tablet: 2.5 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5 })}
`;

export const HeaderMenu = styled.div`
  width: 100%;
  z-index: 5;
  color: ${({ theme: { colors } }) => colors.primary};

  ${CalculateFromHeight("height", { mobile: 0.5, tablet: 0.5, desktop: 4 })}
  position: absolute;
`;

export const HeaderLogoWrapperMenu = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
    pointer-events: none;
  }
  ${CalculateFromWidth("width", { desktop: 14.3 })}
  ${CalculateFromHeight("height", { desktop: 3.125 })}
  ${CalculateFromHeight("margin-top", { desktop: 0.15 })}
  ${CalculateFromHeight("padding-top", { desktop: 0.15 })}
  ${CalculateFromWidth("margin-left", { desktop: 4.2 })}
  ${CalculateFromWidth("border-left-width", { desktop: 0.1 })}
  border-left-color: rgb(255, 255, 255);
  border-left-style: solid;
  z-index: 10;
  cursor: pointer;
  overflow: hidden;
`;

export const HeaderLink = styled(Link)`
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    ${SizeFillSpaceMixin}
  }
  z-index: 5;
  position: relative;
  display: inline;
`;

export const MainMenuChildLink = styled(Link)`
  ${CenterFlex}
  ${CommonLink};
  ${MainMenuChildLinkFont};
  ${CalculateFromWidth("margin-top", { mobile: 1, tablet: 0.5, desktop: 0.5 })}
  ${CalculateFromWidth("margin-bottom", { mobile: 1, tablet: 0.5, desktop: 0.5 })}
`;

export const MainMenuChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${CalculateFromWidth("padding-top", { mobile: 4, tablet: 2, desktop: 1 })}
  ${CalculateFromWidth("padding-bottom", { mobile: 4, tablet: 2, desktop: 1 })}
`;

type LinkMenuImageArrowProps = {
  isTransformed: boolean;
};

export const LinkMenuImageArrow = styled.img<LinkMenuImageArrowProps>`
  width: 40px;
  height: 40px;
  margin: auto 0;
  ${CalculateFromWidth("margin-left", { mobile: 2.5, tablet: 1.5, desktop: 1.5 })}
  ${CalculateFromWidth("margin-top", { mobile: 1 })}
  transform: ${({ isTransformed }) => (isTransformed ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 200ms linear;

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 28px;
    height: 28px;
  }
`;
