import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { Preserve3dMixin, SizeFillSpaceMixin, StaticElement } from "styled/common/Utils";
import styled from "styled-components";

export const LoadingScreenWrapper = styled.div`
  ${SizeFillSpaceMixin}
  z-index: 99;
  background-color: ${({ theme: { colors } }) => colors.black};
  ${StaticElement}
  pointer-events: none;
  transform: translateZ(100px);
  ${Preserve3dMixin}
`;

export const LogoAnimationWrapper = styled.div`
  position: absolute;
  ${CalculateFromHeight("top", { mobile: 8.9, tablet: 5 })}
  ${CalculateFromWidth("top", { desktop: 3.375 })}
  ${CalculateFromWidth("left", { mobile: 6.5, tablet: 5.1, desktop: 3.3 })}
  ${CalculateFromWidth("width", { mobile: 6.85, tablet: 4.9, desktop: 2.2275 })}
  ${CalculateFromWidth("height", { mobile: 6.85, tablet: 4.9, desktop: 2.295 })}
`;
