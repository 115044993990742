import React from "react";
import PageWrapper from "components/PageWrapper";
import RootElementWrapper from "components/RootElementWrapper";

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>;
};

export const wrapRootElement = ({ element }) => {
  return <RootElementWrapper>{element}</RootElementWrapper>;
};
