import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactFormResultDescriptionFont,
  ContactFormResultTitleFont
} from "styled/common/font-styles/ContactFormFonts";
import styled from "styled-components";

export const ContactFormResultTitle = styled.h4`
  ${ContactFormResultTitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 3;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 3;
    grid-row: 3 / span 1;
  }
  ${CalculateFromWidth("margin-left", { desktop: 3 })}
  ${CalculateFromHeight("margin-top", { mobile: -10, tablet: 5 })}
`;

export const ContactFormResultDescription = styled.p`
  ${ContactFormResultDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 3;
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 3;
    grid-row: 4 / span 1;
  }
  ${CalculateFromWidth("margin-left", { desktop: 3 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 8, desktop: 8 })}
  ${CalculateFromWidth("margin-top", { mobile: 3 })}
  ${CalculateFromHeight("margin-top", { tablet: 8, desktop: 7 })}
`;
