import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactFormValidationCommonFont,
  ContactFormValidationCommonFontTerms
} from "styled/common/font-styles/ContactFormFonts";
import styled, { css } from "styled-components";

export type ContactFormValidationVariant =
  | "contact-form-email-valid"
  | "contact-form-phone-valid"
  | "contact-form-message-valid"
  | "contact-form-terms-valid";

const ContactFormValidationCommon = css`
  z-index: -10;
  opacity: 0;
  display: none;
`;

const ContactFormValidationBase = css`
  ${ContactFormValidationCommon}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 6 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 4 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 4 / span 3;
  }
  ${CalculateFromWidth("margin-right", { mobile: 6.4 })}
  ${CalculateFromHeight("padding-top", { mobile: 0.5, tablet: 0.5, desktop: 0.5 })}
`;

const ContactFormEmailValid = css`
  ${ContactFormValidationBase}
  ${ContactFormValidationCommonFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 1 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 21, tablet: 8.25, desktop: 4.3 })}
`;

const ContactFormPhoneValid = css`
  ${ContactFormValidationBase};
  ${ContactFormValidationCommonFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 2 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 21, tablet: 9.25, desktop: 4.3 })}
`;

const ContactFormMessageValid = css`
  ${ContactFormValidationBase}
  ${ContactFormValidationCommonFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 4 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 6 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 6 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 10.5, tablet: 2.8, desktop: 2.8 })}
`;

const ContactFormTermsValid = css`
  ${ContactFormValidationCommon}
  ${ContactFormValidationCommonFontTerms}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 3 / span 5;
    grid-row: 6 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 8 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 8 / span 4;
  }
  ${CalculateFromHeight("margin-top", { tablet: 4, desktop: 3 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 1, desktop: -5 })}
  ${CalculateFromWidth("margin-left", { mobile: 7, tablet: -2, desktop: 9.9 })}
`;

export const ContactFormValidation = styled.p<{ variant: ContactFormValidationVariant }>`
  ${({ variant }) => variant === "contact-form-email-valid" && ContactFormEmailValid}
  ${({ variant }) => variant === "contact-form-phone-valid" && ContactFormPhoneValid}
  ${({ variant }) => variant === "contact-form-message-valid" && ContactFormMessageValid}
  ${({ variant }) => variant === "contact-form-terms-valid" && ContactFormTermsValid}
`;
