import React, { RefObject, useRef } from "react";

import ButtonBody from "components/common/buttons/ButtonStandard/ButtonBody";
import {
  ButtonStandard,
  type StandardButtonVariant
} from "components/common/buttons/ButtonStandard/ButtonStandard.styled";
import gsap, { Power2, Power3 } from "gsap/gsap-core";
import arrow from "media/images/other/arrow.svg";

interface StandardButtonProps {
  url?: string;
  text: string;
  variant?: StandardButtonVariant;
  external?: boolean;
  buttonRef?: RefObject<HTMLDivElement>;
  onClick?: () => void;
  onTrackedClick?: () => void;
  onTrackedHover?: () => void;
}

export const StandardButton = ({
  url,
  text,
  variant,
  external = false,
  buttonRef,
  onClick,
  onTrackedClick,
  onTrackedHover
}: StandardButtonProps) => {
  const emptyCircle = useRef<HTMLDivElement>(null);
  const filledCircle = useRef<HTMLDivElement>(null);
  const buttonText = useRef<HTMLParagraphElement>(null);

  const ActivateButton = () => {
    gsap.to(emptyCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircle.current, { scale: 0.75, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonText.current, { x: "33%", ease: Power2.easeInOut, duration: 0.5 });
    onTrackedHover && onTrackedHover();
  };

  const DeactivateButton = () => {
    gsap.to(emptyCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonText.current, { x: "0%", ease: Power2.easeInOut, duration: 0.5 });
  };

  const OnClick = () => {
    onTrackedClick && onTrackedClick();
    onClick && onClick();
  };

  return (
    <ButtonStandard
      role="button"
      onMouseLeave={DeactivateButton}
      onMouseEnter={ActivateButton}
      variant={variant}
      ref={buttonRef}
      onClick={OnClick}
    >
      <ButtonBody
        url={url}
        emptyCircle={emptyCircle}
        filledCircle={filledCircle}
        arrow={arrow}
        buttonText={buttonText}
        text={text}
        external={external}
      />
    </ButtonStandard>
  );
};

export default StandardButton;
