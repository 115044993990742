import React, { MutableRefObject } from "react";

import {
  ContactFormValidation,
  type ContactFormValidationVariant
} from "components/common/contact/contact-form/ContactFormValidation/ContactFormValidation.styled";

interface RenderValidationProps {
  messageState: {
    ref: MutableRefObject<HTMLParagraphElement>;
    message: string;
  };
  variant: ContactFormValidationVariant;
}

const RenderValidation = ({ messageState, variant }: RenderValidationProps) => {
  return (
    <ContactFormValidation ref={messageState.ref} variant={variant}>
      {messageState.message}
    </ContactFormValidation>
  );
};

export default RenderValidation;
