import * as React from "react";

import {
  ContactFormDescription,
  ContactFormText,
  ContactFormTitleContent
} from "components/common/contact/contact-form/ContactFormText/ContactFormText.styled";
import Translate from "utils/translation/translation";

export const ContactFormTitle = () => {
  return (
    <ContactFormText>
      <ContactFormTitleContent>{Translate("Contact_Form_Header")}</ContactFormTitleContent>
      <ContactFormDescription>{Translate("Contact_Form_Text")}</ContactFormDescription>
    </ContactFormText>
  );
};

export default ContactFormTitle;
