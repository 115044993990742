import React, { Ref } from "react";

import { Container } from "@inlet/react-pixi";
import type { MaskedGroupType } from "components/rendering/RenderingStages/RenderBackgroundStage";
import type { ImageContextData } from "contexts/ImageContext";
import type { Container as PIXIContainer } from "pixi.js";
import { RenderCloudGroups, RenderImageLayers } from "utils/render-utilities";

interface RenderMaskedElementsProps {
  containerRef: Ref<PIXIContainer>;
  maskedGroup: MaskedGroupType;
  imageDatabase: ImageContextData;
}

const RenderMaskedElements = ({
  containerRef,
  maskedGroup,
  imageDatabase
}: RenderMaskedElementsProps) => {
  return (
    <Container ref={containerRef}>
      {RenderImageLayers(maskedGroup.imageElements, imageDatabase)}
      {RenderCloudGroups(maskedGroup.cloudElements, imageDatabase)}
    </Container>
  );
};

export default RenderMaskedElements;
