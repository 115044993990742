import { useEffect } from "react";

import { useFirstRenderState } from "hooks/useFirstRenderState";

export const useFirstRenderEffect = (
  callback: () => void,
  dependencyArray?: React.DependencyList
) => {
  const firstRender = useFirstRenderState();

  useEffect(() => {
    if (firstRender) {
      return;
    }

    callback();
  }, [firstRender, ...(dependencyArray || [])]);
};
