import React from "react";

import { StaticImage } from "components/rendering/RenderStaticElements/RenderStaticElements.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { GetCloudColorSet } from "providers/CloudThunderDataProvider";

interface GetStaticCloudProps {
  color: string;
}

const RenderStaticCloud = ({ color }: GetStaticCloudProps) => {
  const [imageDatabase] = useImageDatabaseContext();

  return (
    <>
      {GetCloudColorSet(color).map(layer => {
        return (
          <StaticImage
            image={imageDatabase[layer].childImageSharp.gatsbyImageData}
            key={layer}
            alt={layer}
          />
        );
      })}
    </>
  );
};

export default RenderStaticCloud;
