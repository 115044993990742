import React, { MutableRefObject, RefObject } from "react";

import { Stage } from "@inlet/react-pixi";
import MaskedGroup from "components/rendering/groups/MaskedGroup";
import type { ImageContextData } from "contexts/ImageContext";
import type { RenderElement } from "contexts/RenderContext";
import Enumerable from "linq";
import { RenderCloudGroups, RenderImageLayers } from "utils/render-utilities";
import { isWindowUndefined } from "utils/window-utils";
import { v4 as UUID } from "uuid";

export type CloudElements = Enumerable.IEnumerable<RenderElement>;

export type MaskedGroupType = {
  parent: HTMLDivElement;
  cloudElements: CloudElements;
  imageElements: CloudElements;
};

interface RenderBackgroundStageProps {
  renderElements: RenderElement[];
  stage: RefObject<Stage | HTMLCanvasElement>;
  imageDatabase: ImageContextData;
  windowSize: MutableRefObject<{
    width: number;
    height: number;
  }>;
}

const RenderBackgroundStage = ({
  renderElements,
  stage,
  imageDatabase,
  windowSize
}: RenderBackgroundStageProps) => {
  if (isWindowUndefined()) {
    return (
      <canvas id={"stage"} ref={stage as RefObject<HTMLCanvasElement>} {...windowSize.current} />
    );
  }

  const maskedElements = Enumerable.from(renderElements).where(
    element => typeof element["maskWrapper"] !== "undefined"
  );
  const standardRenderElements = Enumerable.from(renderElements).except(maskedElements);
  const cloudElements = standardRenderElements.where(
    element => typeof element["group"] !== "undefined"
  );
  const imageElements = standardRenderElements.except(cloudElements);
  const maskedGroups = maskedElements
    .groupBy(element => element["maskWrapper"])
    .select(group => {
      const cloudElements = group.where(
        groupElement => typeof groupElement["group"] !== "undefined"
      );
      const imageElements = group.except(cloudElements);

      return {
        parent: group.key()!,
        cloudElements: cloudElements,
        imageElements: imageElements
      };
    });

  return (
    <Stage
      id="stage"
      ref={stage as RefObject<Stage>}
      {...windowSize.current}
      options={{ preserveDrawingBuffer: true }}
    >
      {RenderCloudGroups(cloudElements, imageDatabase)}
      {RenderImageLayers(imageElements, imageDatabase)}
      {maskedGroups.select(group => (
        <MaskedGroup
          key={UUID()}
          maskedGroup={group}
          imageDatabase={imageDatabase}
          containerKey={UUID()}
        />
      ))}
    </Stage>
  );
};

export default RenderBackgroundStage;
