import React, { useRef, useState } from "react";

import { Sprite, useTick } from "@inlet/react-pixi";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import type { Sprite as SpriteType } from "pixi.js";
import { LoadImageData } from "providers/RenderElementsProvider";

interface ImageLayerProps {
  parent: HTMLDivElement;
  imageData: IGatsbyImageData;
}

export const ImageLayer = ({ parent, imageData }: ImageLayerProps) => {
  const parentRect = parent.getBoundingClientRect();
  const imageSprite = useRef<SpriteType>(null);

  /* We need this to refresh this component every frame... */
  const [dummyData, UpdateDummyData] = useState(0);

  useTick(deltaTime => {
    UpdateDummyData(dummyData + deltaTime);
  });

  useFirstRenderEffect(() => {
    LoadImageData(
      imageData,
      parent.getBoundingClientRect(),
      newTexture => {
        if (typeof imageSprite.current === "undefined" || imageSprite.current === null) {
          return;
        }

        imageSprite.current.texture = newTexture;
      },
      1.5
    );
  }, [parent, imageData]);

  return (
    <Sprite
      ref={imageSprite}
      image={imageData.placeholder!.fallback}
      anchor={0.5}
      x={parentRect.left + parentRect.width / 2}
      y={parentRect.top + parentRect.height / 2}
      width={parentRect.width}
      height={parentRect.height}
      alpha={parseFloat(parent.style.opacity)}
    />
  );
};

export default ImageLayer;
