import { HeaderText } from "components/common/headers/HeaderData/HeaderData.styled";
import { CalculateFontSize, CalculateFromHeight } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const AnimatedLinkHeaderBeforeFont = css`
  ${({ theme: { fonts } }) => fonts.demiBold};
  white-space: nowrap;
`;

export const AnimatedChildLinkHeaderBeforeFont = css`
  ${({ theme: { fonts } }) => fonts.regular};
  white-space: nowrap;
`;

export const AnimatedLinkMenuBeforeFont = css`
  ${CalculateFontSize("letter-spacing", { mobile: 0.2, tablet: 0.2, desktop: 0.2 })}
  ${({ theme: { fonts } }) => fonts.demiBold};
  white-space: nowrap;
`;

export const CommonLink = css`
  ${CalculateFontSize("font-size", { mobile: 1.75, tablet: 1.75, desktop: 1.1 })}
  ${CalculateFontSize("line-height", { mobile: 1.75, tablet: 1.75, desktop: 1.7 })}
  color: white;
  text-shadow: none;
  text-decoration: none;
  background-image: none;
  margin-bottom: 0;
  font-weight: 300;
  ${({ theme: { fonts } }) => fonts.light};

  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    ${({ theme: { fonts } }) => fonts.regular};
  }
`;

export const StandardFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.35, desktop: 1.22 })}
  ${CalculateFontSize("line-height", { mobile: 2.3, tablet: 1.8, desktop: 1.7 })}
  ${({ theme: { fonts } }) => fonts.light}
  font-weight: 300;
  margin-top: 0;
`;

export const AboutFont = css`
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 2.8, desktop: 2 })}
  ${CalculateFontSize("line-height", { mobile: 2.8, tablet: 3.4, desktop: 2.8 })}
  ${({ theme: { fonts } }) => fonts.extraLight}
  font-weight: 300;
  margin-top: 0;
`;

export const NavLinkFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.4, tablet: 1.4, desktop: 1.4 })}
  ${CalculateFontSize("line-height", { mobile: 1.4, tablet: 1.4, desktop: 1.4 })}
  ${({ theme: { fonts } }) => fonts.regular};
  white-space: nowrap;
`;

export const MainMenuChildLinkFont = css`
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 2.5, desktop: 2.5 })}
  ${CalculateFontSize("line-height", { mobile: 2.5, tablet: 3, desktop: 3 })}
  ${({ theme: { fonts } }) => fonts.regular};
  font-weight: 400;
  text-transform: uppercase;
`;

export const NavLanguageFont = css`
  ${CalculateFontSize("font-size", { mobile: 0.9, tablet: 0.9, desktop: 0.9 })}
  ${CalculateFontSize("line-height", { mobile: 1.3, tablet: 1.3, desktop: 1.3 })}
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.grey};
`;

export const RecommendationFont = css`
  ${CalculateFontSize("font-size", { mobile: 2.4, tablet: 2.4, desktop: 2.4 })}
  ${CalculateFontSize("line-height", { mobile: 3 })}
  ${({ theme: { fonts } }) => fonts.extraLight}
  font-weight: 200;
`;

export const RecommendationNameFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.8, tablet: 1.5, desktop: 1.3 })}
  ${CalculateFontSize("line-height", { mobile: 2.7, tablet: 3, desktop: 2 })}
  ${({ theme: { fonts } }) => fonts.light};
`;
export const RecommendationPositionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.3, tablet: 1.3, desktop: 0.8 })}
  ${CalculateFontSize("line-height", { mobile: 2.7, tablet: 2.4, desktop: 2 })}
  ${({ theme: { fonts } }) => fonts.light};
`;

export const Header4 = css`
  ${CalculateFontSize("font-size", { mobile: 4.3, tablet: 5.75, desktop: 5.75 })}
  ${CalculateFontSize("line-height", { mobile: 4.5, tablet: 6, desktop: 6 })}
  ${({ theme: { fonts } }) => fonts.medium};
  font-weight: 400;
`;

export const Header5 = css`
  ${CalculateFontSize("font-size", { mobile: 3.25, tablet: 4.25, desktop: 4.1 })}
  ${CalculateFontSize("line-height", { mobile: 3.5, tablet: 4.5, desktop: 4.5 })}
  ${({ theme: { fonts } }) => fonts.medium};
  font-weight: 400;
`;

export const Header6 = css`
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1.2, desktop: 1.2 })}
  ${CalculateFontSize("line-height", { mobile: 2, tablet: 2, desktop: 2 })}
  font-weight: 400;
  ${({ theme: { fonts } }) => fonts.medium};
`;

export const CommonLightBigFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.8, tablet: 2.1, desktop: 2.5 })}
  ${CalculateFontSize("line-height", { mobile: 3, tablet: 3, desktop: 3.5 })}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-weight: 200;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    font-weight: 100;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-weight: 100;
  }
  ${({ theme: { fonts } }) => fonts.light};
`;

export const NewsletterDescriptionFont = css`
  ${CommonLightBigFont};
  ${CalculateFontSize("font-size", { mobile: 1.7, tablet: 1.9, desktop: 2.3 })}
`;

export const NewsletterCheckboxLabelFont = css`
  ${CommonLightBigFont};
  ${CalculateFontSize("font-size", { mobile: 2.1, tablet: 1.9, desktop: 2.3 })}
`;

export const NewsletterInputFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.6, tablet: 1.15, desktop: 1.3 })}
  ${({ theme: { fonts } }) => fonts.medium}
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const NewsletterPrivacyPolicyFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 0.7, desktop: 1 })}
  ${CalculateFontSize("line-height", { mobile: 2, tablet: 1.1, desktop: 1.1 })}
  ${({ theme: { fonts } }) => fonts.light};
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
`;

export const NewsletterPrivacyPolicyLinkFont = css`
  ${NewsletterPrivacyPolicyFont}
  ${({ theme: { fonts } }) => fonts.regular};
  color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.white};
`;

export const IconContainerIdFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.7, tablet: 1.2, desktop: 1 })}
  ${CalculateFontSize("line-height", { mobile: 1, tablet: 1, desktop: 1 })}
  ${({ theme: { fonts } }) => fonts.demiBold}
  font-weight: 800;
`;

export const CommonDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 3, tablet: 2.5, desktop: 3.5 })}
  ${CalculateFontSize("line-height", { mobile: 4, tablet: 3.5, desktop: 4.5 })}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-weight: 300;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    font-weight: 100;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-weight: 100;
  }
  ${({ theme: { fonts } }) => fonts.light}
  word-break: break-word;
  white-space: normal;
`;

export const CommonTitleDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.85, tablet: 1.82, desktop: 1.52 })}
  ${CalculateFontSize("line-height", { mobile: 2.5, tablet: 2.5, desktop: 2.5 })}
  ${({ theme: { fonts } }) => fonts.light}
  font-weight: 300;
`;

export const AutomationSubtitleFont = css`
  ${CommonDescriptionFont}
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 2, desktop: 2.5 })}
  ${CalculateFontSize("line-height", { mobile: 3 })}
`;

export const ProjectHeaderFont = css`
  ${CalculateFontSize("font-size", { mobile: 6.5, tablet: 9, desktop: 14.7 })}
  ${CalculateFontSize("line-height", { mobile: 6.3, tablet: 8, desktop: 13.7 })}
  display: inline-block;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-weight: 600;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    font-weight: 600;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-weight: 400;
  }
  ${({ theme: { fonts } }) => fonts.demiBold}
  word-wrap: none;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const ProjectHeaderSmallFont = css`
  ${CalculateFontSize("font-size", { mobile: 5.2, tablet: 7.5, desktop: 9 })}
  ${CalculateFontSize("line-height", { mobile: 4.9, tablet: 6.5, desktop: 11.7 })}
  display: inline-block;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-weight: 600;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    font-weight: 600;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-weight: 400;
  }
  ${({ theme: { fonts } }) => fonts.demiBold}
  word-wrap: none;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const HeaderMainFont = css`
  ${CalculateFontSize("font-size", { mobile: 7.5, tablet: 12, desktop: 14.7 })}
  ${CalculateFontSize("line-height", { mobile: 6.3, tablet: 9, desktop: 13.7 })}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    font-weight: 600;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    font-weight: 400;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    font-weight: 400;
  }
  ${({ theme: { fonts } }) => fonts.demiBold};
  word-wrap: none;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const HeaderSectionFont = css`
  ${CalculateFontSize("font-size", { mobile: 5.8, tablet: 7.7, desktop: 12.2 })}
  ${CalculateFontSize("line-height", { mobile: 6, tablet: 8, desktop: 12.5 })}
  ${({ theme: { fonts } }) => fonts.demiBold}
  word-wrap: none;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
`;

export const LetsTalkFont = css`
  ${CalculateFontSize("font-size", { mobile: 6.5, tablet: 9, desktop: 14 })}
  ${CalculateFontSize("line-height", { mobile: 6, tablet: 7.5, desktop: 12.49 })}
  display: inline-block;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    letter-spacing: normal;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    letter-spacing: normal;
  }
  ${({ theme: { fonts } }) => fonts.bold}
  text-transform: uppercase;
  font-weight: 200;
`;

export const CallToActionHeaderFont = css`
  ${CalculateFontSize("font-size", { mobile: 5, tablet: 6.5, desktop: 7 })};
  ${CalculateFontSize("line-height", { mobile: 5.2, tablet: 5.7, desktop: 6.2 })};
  font-weight: 500;
`;

export const CallToActionArticleHeaderFont = css`
  ${CallToActionHeaderFont};
  color: ${({ theme: { colors } }) => colors.black};
`;

export const CallToActionDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 1.6, desktop: 1.6 })}
  ${CalculateFontSize("line-height", { mobile: 2.8, tablet: 2.3, desktop: 2.3 })}
  ${({ theme: { fonts } }) => fonts.extraLight}
  font-weight: 300;
`;

export const CallToActionArticleDescriptionFont = css`
  ${CallToActionDescriptionFont}
  color: ${({ theme: { colors } }) => colors.black};
`;

export const AdvantageHeaderFont = css`
  ${CalculateFontSize("font-size", { mobile: 4.15, tablet: 6, desktop: 6 })};
  ${CalculateFontSize("line-height", { mobile: 4.15, tablet: 6, desktop: 6 })};
  font-weight: 500;
`;

export const AdvantageTitleFont = css`
  ${CalculateFontSize("font-size", { mobile: 3.25, tablet: 3, desktop: 3.5 })}
  ${CalculateFontSize("line-height", { mobile: 3.5, tablet: 3, desktop: 3.5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 2.75, tablet: 1.75, desktop: 2.25 })}
  ${({ theme: { fonts } }) => fonts.light};
`;

export const AdvantageDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.44, tablet: 1.35, desktop: 1.24 })}
  ${CalculateFontSize("line-height", { mobile: 2.3, tablet: 1.8, desktop: 1.7 })}
  ${({ theme: { fonts } }) => fonts.light}
`;

export const NecessityDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.35, desktop: 2.5 })}
  ${CalculateFontSize("line-height", { mobile: 2.8, tablet: 2.3, desktop: 3 })}
  ${({ theme: { fonts } }) => fonts.extraLight}
  font-weight: 300;
`;

export const NecessityHeaderFont = css`
  ${HeaderText} {
    ${CalculateFontSize("font-size", { mobile: 4, tablet: 6.75, desktop: 8 })}
    ${CalculateFontSize("line-height", { mobile: 5.75, tablet: 6.75, desktop: 9 })}
  }
`;
