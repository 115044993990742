import React from "react";

import { Container } from "@inlet/react-pixi";
import { Layers } from "components/rendering/groups/CloudGroup";
import CloudLayer from "components/rendering/layers/CloudLayer";
import ThunderLayer from "components/rendering/layers/ThunderLayer";
import type { ImageContextData } from "contexts/ImageContext";
import type { Filter } from "pixi.js";

type GroupContainerProps = {
  opacity: string;
  parentRect: DOMRect;
  filter: Filter;
  groupAnimationData: {
    filterModifier: number;
    cloudRotation: number;
  };
  layers: Layers;
  imageDatabase: ImageContextData;
};

const GroupContainer = ({
  opacity,
  parentRect,
  filter,
  groupAnimationData,
  layers,
  imageDatabase
}: GroupContainerProps) => {
  return (
    <Container
      filters={typeof filter !== "undefined" && filter !== null ? [filter] : []}
      rotation={groupAnimationData.cloudRotation}
      anchor={0.5}
      x={parentRect.left + parentRect.width / 2}
      y={parentRect.top + parentRect.height / 2}
      alpha={opacity !== "" ? parseFloat(opacity) : 1}
    >
      {layers
        .where(layer => layer.type === "CloudLayer")
        .toArray()
        .map(layer => {
          return (
            <CloudLayer
              key={layer.id}
              imageData={imageDatabase[layer.name].childImageSharp.gatsbyImageData}
              parentRect={parentRect}
            />
          );
        })}
      {layers
        .where(layer => layer.type === "ThunderLayer")
        .toArray()
        .map(({ id, name, additionalData }) => {
          return (
            <ThunderLayer
              key={id}
              imageData={imageDatabase[name].childImageSharp.gatsbyImageData}
              parentRect={parentRect}
              thunderData={additionalData}
            />
          );
        })}
    </Container>
  );
};

export default GroupContainer;
