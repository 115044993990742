import React, { type CSSProperties, RefObject } from "react";

import SocialIcon from "components/layout/SocialIcons/SocialIcon";
import {
  SocialIconsWrapper,
  type SocialIconVariant
} from "components/layout/SocialIcons/SocialIcons.styled";
import Facebook from "media/images/icons/contact/facebook.svg";
import FacebookBlack from "media/images/icons/contact/facebook-black.svg";
import Instagram from "media/images/icons/contact/instagram.svg";
import InstagramBlack from "media/images/icons/contact/instagram-black.svg";
import TikTok from "media/images/icons/contact/tiktok.svg";
import TikTokBlack from "media/images/icons/contact/tiktok-black.svg";
import Twitter from "media/images/icons/contact/twitter.svg";
import TwitterBlack from "media/images/icons/contact/twitter-black.svg";
import Youtube from "media/images/icons/contact/youtube.svg";
import YoutubeBlack from "media/images/icons/contact/youtube-black.svg";

interface SocialIconsProps {
  inMenu: boolean;
  variant?: SocialIconVariant;
  elementRef?: RefObject<HTMLDivElement>;
  style?: CSSProperties;
  isBlog?: boolean;
}

export const SocialIcons = ({ inMenu, variant, elementRef, style, isBlog }: SocialIconsProps) => {
  return (
    <SocialIconsWrapper inMenu={inMenu} ref={elementRef} style={style ?? {}}>
      <SocialIcon
        variant={variant}
        image={isBlog ? FacebookBlack : Facebook}
        url="https://www.facebook.com/DreamStormStudios/"
        name="Facebook"
      />
      <SocialIcon
        variant={variant}
        image={isBlog ? InstagramBlack : Instagram}
        url="https://www.instagram.com/dreamstorm_studios/"
        name="Instagram"
      />
      <SocialIcon
        variant={variant}
        image={isBlog ? TwitterBlack : Twitter}
        url="https://twitter.com/TheDreamStorm"
        name="Twitter"
      />
      <SocialIcon
        variant={variant}
        image={isBlog ? TikTokBlack : TikTok}
        url="https://www.tiktok.com/@dreamstorm_studios"
        name="TikTok"
      />
      <SocialIcon
        variant={variant}
        image={isBlog ? YoutubeBlack : Youtube}
        url="https://www.youtube.com/channel/UC9_gSrLK59VARfslEetmpmA"
        name="Youtube"
      />
    </SocialIconsWrapper>
  );
};

export default SocialIcons;
