import React, { MutableRefObject, RefObject, useRef } from "react";
import { isMobile } from "react-device-detect";

import TrackedElement from "components/common/analytics/TrackedElement";
import {
  BurgerMenuButton,
  BurgerMenuImage,
  BurgerMenuParent,
  BurgerMenuWrapper
} from "components/layout/BurgerMenu/BurgerMenu.styled";
import { useRenderContext } from "contexts/RenderContext";
import {
  GetScrollPosition,
  registerScrollCallback,
  useScrollContext
} from "contexts/ScrollContext";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import BurgerImage from "media/images/icons/contact/hamburger.svg";

export type OpenMenuCallback = MutableRefObject<
  ((element: HTMLImageElement | HTMLDivElement) => void) | null
>;

interface BurgerMenuProps {
  openMenuCallback?: OpenMenuCallback;
  location?: Location;
  inFooter: boolean;
  elementRef?: RefObject<HTMLDivElement>;
}

export const BurgerMenu = ({
  openMenuCallback,
  location,
  inFooter,
  elementRef
}: BurgerMenuProps) => {
  const burgerMenu = useRef<HTMLImageElement>(null);
  const burgerMenuWrapper = useRef<HTMLDivElement>(null);
  const showBurgerMenu = useRef<gsap.core.Tween | null>(null);
  const showBurgerWrapper = useRef<gsap.core.Tween | null>(null);
  const [, dispatch] = useScrollContext();
  const [{ renderElements }] = useRenderContext();

  const animateMenu = () => {
    if (isMobile || !showBurgerMenu.current || !showBurgerWrapper.current) {
      return;
    }

    const triggerHeight = 0.05;

    if (GetScrollPosition() < triggerHeight) {
      showBurgerMenu.current.reverse();
      showBurgerWrapper.current.reverse();
    } else {
      showBurgerMenu.current.play();
      showBurgerWrapper.current.play();
    }
  };

  const OpenMenu = () => {
    if (openMenuCallback && openMenuCallback.current && burgerMenu.current) {
      openMenuCallback.current(burgerMenu.current);
    }
  };

  useFirstRenderEffect(() => {
    if (isMobile) {
      return;
    }

    dispatch(
      registerScrollCallback({
        scrollPositionTrigger: 0,
        methodToInvoke: animateMenu,
        isOneShot: false
      })
    );
  }, [location, renderElements]);

  useFirstRenderEffect(() => {
    gsap.set(burgerMenu.current, { opacity: 1 });

    if (isMobile) {
      return;
    }

    showBurgerMenu.current = gsap
      .from(burgerMenu.current, { y: "-5vh", opacity: 0, duration: 0.5, ease: Circ.easeOut })
      .pause();
    showBurgerWrapper.current = gsap.to(burgerMenuWrapper.current, { display: "inline" }).pause();
  }, [isMobile]);

  return (
    <BurgerMenuParent ref={elementRef} inFooter={inFooter}>
      <BurgerMenuWrapper inFooter={inFooter} ref={burgerMenuWrapper}>
        <TrackedElement category="navigation" action="menu-open">
          <BurgerMenuButton onClick={OpenMenu}>
            <BurgerMenuImage ref={burgerMenu} src={BurgerImage} alt="Menu" style={{ opacity: 0 }} />
          </BurgerMenuButton>
        </TrackedElement>
      </BurgerMenuWrapper>
    </BurgerMenuParent>
  );
};

export default BurgerMenu;
