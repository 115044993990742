import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonLink } from "styled/common/font-styles/CommonFonts";
import { NonSelectable } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type SocialIconVariant = "social-icon-wrapper" | "article-author-social-icon-wrapper";

export const SocialIconLink = styled.a`
  ${CommonLink}
`;

export const SocialIconImage = styled.img`
  ${NonSelectable}
  ${CommonLink}
  ${CalculateFromWidth("width", { desktop: 1.5 })}
  ${CalculateFromWidth("height", { desktop: 1.5 })}
  ${CalculateFromWidth("margin-left", { desktop: 1 })}
`;

const SocialIconWrapperStyles = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    justify-content: left;
  }
  ${CalculateFromWidth("margin-top", { desktop: 0.125 })}
  ${CalculateFromWidth("margin-bottom", { desktop: 3 })}
`;

const ArticleAuthorWrapperStyles = css`
  display: flex;
  align-items: center;
  ${CalculateFromWidth("margin-left", { mobile: 2, tablet: 2, desktop: 2 })}
  ${SocialIconLink} {
    display: flex;
    align-items: center;
  }
`;

export const SocialIconWrapper = styled.div<{ variant?: SocialIconVariant }>`
  z-index: 99 !important;
  ${({ variant }) => variant === "social-icon-wrapper" && SocialIconWrapperStyles}
  ${({ variant }) => variant === "article-author-social-icon-wrapper" && ArticleAuthorWrapperStyles}
`;

const MenuSocialIcons = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    grid-row: 9 / span 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: auto;
    ${SocialIconImage} {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 4;
    grid-row: 10 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 8;
  }
  ${CalculateFromHeight("margin-top", { mobile: 4, tablet: 3 })}
  ${SocialIconImage} {
    ${CalculateFromHeight("width", { mobile: 6, tablet: 3 })}
    ${CalculateFromHeight("height", { mobile: 6, tablet: 3 })}
  }
`;

const CommonSocialIcons = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: block;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktopMaxHeight} {
    display: none;
  }
  ${CalculateFromHeight("bottom", { desktop: 5 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
  position: fixed;
  z-index: 4;
`;

export const SocialIconsWrapper = styled.div<{ inMenu?: boolean }>`
  ${({ inMenu }) => (inMenu ? MenuSocialIcons : CommonSocialIcons)}
`;
