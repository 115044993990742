import chatIcon from "media/images/icons/contact/chat-icon.svg";
import chatIconBlue from "media/images/icons/contact/chat-icon-blue.svg";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { DisableSelection, FillSpaceMixin, Preserve3dMixin } from "styled/common/Utils";
import styled from "styled-components";

export const ChatIconWrapper = styled.div<{ enabled: boolean }>`
  display: ${({ enabled }) => (enabled ? "block" : "none")};
  ${Preserve3dMixin}
  pointer-events: all;
`;

export const ChatIconButton = styled.button`
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    pointer-events: none;
    position: fixed;
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    vertical-align: middle;
    display: inline-block;
    position: fixed;
    left: auto;
    top: 100vh;
    transform: translateY(200px) translateZ(10px);
    z-index: 7;
  }
  ${CalculateFromHeight("height", { desktop: 10 })}
  ${CalculateFromHeight("margin-top", { desktop: -10 })}
  ${CalculateFromHeight("width", { desktop: 13 })}
  ${CalculateFromWidth("right", { desktop: 3.5 })}
`;

export const CloseChat = styled.button`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    height: 100px;
    width: 100px;
    position: fixed;
    left: auto;
    top: 100vh;
    height: 100px;
    ${CalculateFromWidth("right", { desktop: 3 })};
    z-index: 10;
    transform: translateY(200px);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    pointer-events: none;
    position: fixed;
    opacity: 0;
    width: 0px;
    height: 0px;
  }
`;

export const ChatIconImage = styled.div`
  ${DisableSelection}
  ${FillSpaceMixin}
  background: url(${chatIcon}) center/contain no-repeat;
  transition: background-image 100ms ease-out;

  &:hover {
    ${({ theme: { breakpoints } }) => breakpoints.desktop} {
      background-image: url(${chatIconBlue});
    }
  }
`;
