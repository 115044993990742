import React, { useReducer, useRef, useState } from "react";

import { useTick } from "@inlet/react-pixi";
import GroupContainer from "components/rendering/groups/GroupContainer";
import NoiseLayer from "components/rendering/layers/NoiseLayer";
import type { ImageContextData } from "contexts/ImageContext";
import type { RenderElement } from "contexts/RenderContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Enumerable from "linq";
import * as PIXI from "pixi.js";

export type Layers = Enumerable.IGrouping<string, RenderElement>;

export type CloudSettings = {
  noiseScaleSpeed: number;
  noiseScaleSpeedVariation: number;
  noiseScaleStrength: {
    x: number;
    y: number;
  };
  noiseScrollSpeed: {
    x: number;
    y: number;
  };
  noiseScaleBase: number;
  noiseScaleAmplitude: number;
  noiseScrollType: string;
  cloudSpeed: number;
};

interface CloudGroupProps {
  layers: Layers;
  imageDatabase: ImageContextData;
  parent: HTMLDivElement;
  settings: CloudSettings;
}

export const CloudGroup = ({ layers, imageDatabase, parent, settings }: CloudGroupProps) => {
  const [groupAnimationData, UpdateGroupAnimation] = useReducer(FilterReducer, GetInitialData());
  const [filter, SetFilter] = useState<PIXI.Filter>();
  const noiseSprite = useRef<PIXI.Sprite>(null);

  const recreateFilter = () => {
    if (!noiseSprite.current) return;

    SetFilter(CreateDisplacementFilter(noiseSprite.current, settings));
  };

  useTick(deltaTime => {
    const width = parent.getBoundingClientRect().width;

    UpdateGroupAnimation({ deltaTime, filter, settings, width });
  });

  useFirstRenderEffect(() => {
    recreateFilter();
  }, [settings]);

  return (
    <>
      <NoiseLayer
        noiseImageData={imageDatabase.Noise.childImageSharp.gatsbyImageData}
        parentRect={parent.getBoundingClientRect()}
        noiseRef={noiseSprite}
        settings={settings}
        onLoadCallback={recreateFilter}
      />

      {filter && (
        <GroupContainer
          opacity={parent.style.opacity}
          parentRect={parent.getBoundingClientRect()}
          filter={filter}
          groupAnimationData={groupAnimationData}
          layers={layers}
          imageDatabase={imageDatabase}
        />
      )}
    </>
  );
};

export default CloudGroup;

const GetInitialData = () => {
  return {
    filterModifier: 0,
    cloudRotation: 0
  };
};

const FilterReducer = (prevData, { deltaTime, filter, settings, width }) => {
  if (typeof prevData == "undefined") {
    prevData = GetInitialData();
  }

  if (typeof filter === "undefined" || !filter) {
    return prevData;
  }

  const filterModifier =
    prevData.filterModifier +
    (((deltaTime / 60) * width) / 1024) *
      settings.noiseScaleSpeed *
      Math.random() *
      settings.noiseScaleSpeedVariation;

  filter.scale.x = (GetNoiseScaleX(filterModifier, settings) * width) / 1024;
  filter.scale.y = (GetNoiseScaleY(filterModifier, settings) * width) / 1024;

  return {
    filterModifier: filterModifier,
    cloudRotation:
      prevData.cloudRotation + (((deltaTime / 720) * width) / 1024) * settings.cloudSpeed
  };
};

const CreateDisplacementFilter = (displacementSprite: PIXI.Sprite, settings: CloudSettings) => {
  const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);

  displacementFilter.scale.x = settings.noiseScaleBase;
  displacementFilter.scale.y = settings.noiseScaleBase;

  return displacementFilter;
};

const GetNoiseScaleX = (value, settings) => {
  return (
    settings.noiseScaleBase +
    Math.sin(value) * settings.noiseScaleAmplitude * settings.noiseScaleStrength.x
  );
};
const GetNoiseScaleY = (value, settings) => {
  return (
    settings.noiseScaleBase +
    Math.cos(value) * settings.noiseScaleAmplitude * settings.noiseScaleStrength.y
  );
};
