import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import gsap from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import { getWindowSize } from "utils/window-utils";
import { v4 as UUID } from "uuid";

interface CallbackData {
  scrollPosition?: number;
  methodToInvoke?: () => void;
  isOneShot?: boolean;
  triggerData?: any;
  id: string;
  sourceAnimationData?: AnimationData;
}

interface LocomotiveScrollData {
  isReady: boolean;
  scrollLimit: number;
}

export interface AnimationData {
  target: string | HTMLElement;
  animation: gsap.TweenVars;
  triggerOffsetMultiplier?: number;
  proxyElement?: any;
  instantPlayback?: boolean;
}

export interface ScrollCallbackData {
  scrollPositionTrigger: number;
  methodToInvoke: () => void;
  isOneShot: boolean;
}

interface ScrollContextData {
  CallbackList: CallbackData[];
  locomotiveScrollData: LocomotiveScrollData;
}

type ScrollContextType = [ScrollContextData, Dispatch<AnyAction>];

const DefaultScrollContextData: ScrollContextData = {
  CallbackList: [],
  locomotiveScrollData: { isReady: false, scrollLimit: 1 }
};

/* ------------------------REDUX------------------------ */
const scrollSlice = createSlice({
  name: "scroll",
  reducers: {
    updateLocomotiveScrollData: (state, action: PayloadAction<LocomotiveScrollData>) => {
      state.locomotiveScrollData = action.payload;
    },
    recalculateCallbacks: state => {
      state.CallbackList = state.CallbackList.map(item => {
        return recreateCallback(item, state.locomotiveScrollData);
      });
      state.CallbackList = state.CallbackList.filter(callbackData => {
        return typeof callbackData.scrollPosition !== "undefined";
      });
    },
    registerScrollAnimation: (state, action: PayloadAction<AnimationData>) => {
      state.CallbackList.push(
        registerScrollAnimationInternal(action.payload, state.locomotiveScrollData)
      );
    },
    registerScrollCallback: (state, action: PayloadAction<ScrollCallbackData>) => {
      state.CallbackList.push(
        registerScrollCallbackInternal(action.payload, state.locomotiveScrollData)
      );
    },
    clearScrollCallbacks: state => {
      state.CallbackList = [];
    },
    removeCallback: (state, action: PayloadAction<string>) => {
      state.CallbackList = state.CallbackList.filter(item => item.id !== action.payload);
    }
  },
  initialState: DefaultScrollContextData
});
export const {
  updateLocomotiveScrollData,
  recalculateCallbacks,
  registerScrollAnimation,
  registerScrollCallback,
  clearScrollCallbacks,
  removeCallback
} = scrollSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const ScrollContext = createContext<ScrollContextType>([
  DefaultScrollContextData,
  () => null
]);

export const ScrollContextProvider: Component = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ScrollContextData, AnyAction>>(
    scrollSlice.reducer,
    DefaultScrollContextData
  );

  return <ScrollContext.Provider value={[state, dispatch]}>{children}</ScrollContext.Provider>;
};

export const useScrollContext = (): ScrollContextType => {
  return useContext(ScrollContext);
};

export const GetScrollPosition = (): number => {
  const locomotiveScroll = (window.scroll as any).scroll as LocomotiveScroll;
  const scrollY: number = locomotiveScroll.scroll.instance.scroll.y ?? 0;
  const scrollLimit: number = locomotiveScroll.scroll.instance.limit.y ?? 1;

  return scrollY / scrollLimit;
};

const registerScrollAnimationInternal = (
  animationData: AnimationData,
  scrollData: LocomotiveScrollData
): any => {
  const targetElement =
    typeof animationData.target !== "string"
      ? animationData.target
      : document.getElementById(animationData.target);
  const triggerProxy: HTMLElement = getTriggerProxy(animationData.proxyElement, targetElement!);
  const gsapAnimation: gsap.core.Tween = gsap.from(targetElement, animationData.animation).pause();

  animationData.triggerOffsetMultiplier ??= 1;

  if (animationData.instantPlayback) {
    gsapAnimation.play();

    return {
      id: UUID(),
      scrollPosition: undefined,
      methodToInvoke: undefined,
      isOneShot: undefined,
      triggerData: undefined
    };
  }

  const rect = triggerProxy.getBoundingClientRect();
  const offset = rect.top - rect.height * animationData.triggerOffsetMultiplier;

  return registerScrollCallbackInternal(
    { scrollPositionTrigger: offset, methodToInvoke: () => gsapAnimation.play(), isOneShot: true },
    scrollData,
    animationData
  );
};

const getTriggerProxy = (
  animationTriggerProxy: string | HTMLElement,
  targetElement: HTMLElement
): HTMLElement => {
  let triggerProxy =
    typeof animationTriggerProxy === "string"
      ? document.getElementById(animationTriggerProxy as string)
      : animationTriggerProxy;

  triggerProxy =
    typeof triggerProxy !== "undefined" && triggerProxy !== null ? triggerProxy : targetElement;

  return triggerProxy;
};

const registerScrollCallbackInternal = (
  data: ScrollCallbackData,
  scrollData: LocomotiveScrollData,
  animationData?: AnimationData
): any => {
  const { scrollPositionTrigger, methodToInvoke, isOneShot } = data;

  if (!scrollData.isReady) {
    return {
      id: UUID(),
      scrollPosition: undefined,
      methodToInvoke: methodToInvoke,
      isOneShot: isOneShot,
      triggerData: scrollPositionTrigger,
      sourceAnimationData: animationData
    };
  } else {
    return {
      id: UUID(),
      scrollPosition: calculateLimitFromTriggerData(scrollPositionTrigger, scrollData),
      methodToInvoke: methodToInvoke,
      isOneShot: isOneShot,
      triggerData: undefined,
      sourceAnimationData: animationData
    };
  }
};

const calculateLimitFromTriggerData = (
  scrollPositionTrigger: number,
  scrollData: LocomotiveScrollData
): number => {
  const callbackLocation =
    (scrollPositionTrigger - getWindowSize().height) / scrollData.scrollLimit;

  return Math.min(Math.max(0, callbackLocation), 0.999);
};

const recreateCallback = (callback, scrollData: LocomotiveScrollData) => {
  if (callback.sourceAnimationData) {
    return {
      ...regenerateCallbackFromData(callback, callback.sourceAnimationData, scrollData),
      methodToInvoke: callback.methodToInvoke
    };
  }

  if (typeof callback.scrollPosition !== "undefined") {
    return callback;
  } else if (typeof callback.triggerData !== "undefined") {
    return {
      id: UUID(),
      scrollPosition: calculateLimitFromTriggerData(callback.triggerData, scrollData),
      methodToInvoke: callback.methodToInvoke,
      isOneShot: callback.isOneShot,
      triggerData: undefined
    };
  } else {
    return {
      id: UUID(),
      scrollPosition: undefined,
      methodToInvoke: undefined,
      isOneShot: undefined,
      triggerData: undefined
    };
  }
};

export default ScrollContextProvider;

const regenerateCallbackFromData = (
  callbackData: CallbackData,
  animationData: AnimationData,
  scrollData: LocomotiveScrollData
): CallbackData => {
  const targetElement =
    typeof animationData.target !== "string"
      ? animationData.target
      : document.getElementById(animationData.target);
  const triggerProxy: HTMLElement = getTriggerProxy(animationData.proxyElement, targetElement!);
  const gsapAnimation: gsap.core.Tween = gsap.from(targetElement, animationData.animation).pause();

  animationData.triggerOffsetMultiplier ??= 1;

  if (animationData.instantPlayback) {
    gsapAnimation.play();

    return {
      id: UUID(),
      scrollPosition: undefined,
      methodToInvoke: undefined,
      isOneShot: undefined,
      triggerData: undefined
    };
  }

  const rect = triggerProxy.getBoundingClientRect();
  const offset = rect.top - rect.height * animationData.triggerOffsetMultiplier;

  return registerScrollCallbackInternal(
    {
      scrollPositionTrigger: offset,
      methodToInvoke: callbackData.methodToInvoke!,
      isOneShot: true
    },
    scrollData,
    animationData
  );
};
