import React, { useRef } from "react";

import {
  CloseButtonImage,
  type CloseButtonVariant,
  CloseButtonWrapper
} from "components/common/buttons/ButtonClose/ButtonClose.styled";
import gsap, { Circ } from "gsap/gsap-core";
import Close from "media/images/other/close.svg";

interface CloseButtonProps {
  callback: () => void;
  variant: CloseButtonVariant;
  onTrackedClick?: () => void;
  onTrackedHover?: () => void;
}

export const CloseButton = ({
  callback,
  variant,
  onTrackedClick,
  onTrackedHover
}: CloseButtonProps) => {
  const close = useRef<HTMLImageElement>(null!);
  const button = useRef<HTMLButtonElement>(null!);

  const ActivateButton = () => {
    gsap.to(close.current, { scale: 1.1, ease: Circ.easeOut, duration: 0.5 });
    onTrackedHover && onTrackedHover();
  };

  const DeactivateButton = () => {
    gsap.to(close.current, { scale: 1, ease: Circ.easeOut, duration: 0.5 });
  };

  const InvokeCallback = () => {
    if (typeof callback !== "undefined") {
      callback();
    }

    button.current.blur();
    close.current.blur();
    onTrackedClick && onTrackedClick();
  };

  return (
    <CloseButtonWrapper
      ref={button}
      tabIndex={-1}
      onMouseLeave={DeactivateButton}
      onMouseEnter={ActivateButton}
      variant={variant}
      onClick={InvokeCallback}
    >
      <CloseButtonImage ref={close} src={Close} alt="Close" />
    </CloseButtonWrapper>
  );
};

export default CloseButton;
