import React from "react";

import RenderStaticCloud from "components/rendering/RenderStaticElements/RenderStaticCloud";
import RenderStaticImage from "components/rendering/RenderStaticElements/RenderStaticImage";
import { IsDeviceRenderReady, useRenderContext } from "contexts/RenderContext";

interface RenderStaticElementsProps {
  type: string;
  additionalData: string;
}

const RenderStaticElements = ({ type, additionalData }: RenderStaticElementsProps) => {
  const [{ canUseRenderer }] = useRenderContext();

  if (!IsDeviceRenderReady() || !canUseRenderer) {
    switch (type) {
      case "cloud":
        return <RenderStaticCloud color={additionalData} />;
      case "image":
        return <RenderStaticImage imageName={additionalData} />;
      default:
        return <div id="static" />;
    }
  }

  return <div id="static-image" />;
};

export default RenderStaticElements;
