import React from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import TrackedElement from "components/common/analytics/TrackedElement";
import StandardButton from "components/common/buttons/ButtonStandard/ButtonStandard";
import {
  ContactFormMailAdress,
  ContactFormResultDescriptionError,
  ContactFormResultTitleError
} from "components/common/contact/contact-form/ContactFormError/ContactFormError.styled";
import type { ResultBodyProps } from "components/common/contact/contact-form/ContactFormSuccess/ContactFormSuccess";
import Translate from "utils/translation/translation";

const ErrorBody = ({ onClick }: ResultBodyProps) => {
  return (
    <>
      <ContactFormResultTitleError>
        {Translate("Contact_Form_Result_Error")}
      </ContactFormResultTitleError>
      <ContactFormResultDescriptionError>
        {Translate("Contact_Form_Result_ErrorMessage")}
        <TrackedElement category="form" action="form-result-error-email">
          <ContactFormMailAdress href="mailto:hello@dreamstormstudios.com">
            hello@dreamstormstudios.com
          </ContactFormMailAdress>
        </TrackedElement>
      </ContactFormResultDescriptionError>
      <TrackedButton category="form" action="form-result-error-return">
        <StandardButton
          text="Contact_Form_Result_Return"
          variant="contact-form-result-button-wrapper"
          onClick={onClick}
        />
      </TrackedButton>
    </>
  );
};

export default ErrorBody;
