import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { DisableSelection, Preserve3dMixin } from "styled/common/Utils";
import { createGlobalStyle, css } from "styled-components";
import { desktopSizes, tabletSizes } from "theme/theme";

const Heading = css`
  margin: unset;
  padding: unset;
  font-size: unset;
  font-weight: unset;
`;

const GlobalStyles = createGlobalStyle`

  .grecaptcha-badge {
    visibility: hidden;
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  html.has-scroll-smooth {
    overflow: hidden;
  }

  html.has-scroll-dragging {
    ${DisableSelection}
  }

  .has-scroll-smooth body {
    overflow: hidden;
  }

  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh;
  }

  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
    z-index: 7;
  }

  .c-scrollbar:hover {
    transform: scaleX(1.45);
  }

  .c-scrollbar:hover,
  .has-scroll-scrolling .c-scrollbar,
  .has-scroll-dragging .c-scrollbar {
    opacity: 1;
  }

  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme: { colors } }) => colors.white};
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  @media screen and (min-width: ${tabletSizes.minWidth}px) {
    .c-scrollbar_thumb {
      display: none;
    }
  }

  @media screen and (min-width: ${desktopSizes.minWidth}px) {
    .c-scrollbar_thumb {
      display: block;
    }
  }

  body {
    user-select: auto;
    margin: 0;
    padding: 0;
    font: 100% Helvetica, sans-serif;
    color: ${({ theme: { colors } }) => colors.primary};
    overflow-x: hidden;
    width: 100%;
    position: relative;
    background-color: ${({ theme: { colors } }) => colors.black};
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  html.has-scroll-smooth {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    ${Heading}
  }

  img {
    margin-bottom: 0;
  }

  a {
    text-shadow: none;
    text-decoration: none;
    background-image: none;
    margin-bottom: 0;
    margin-top: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  p {
    ${({ theme: { fonts } }) => fonts.light};
    margin-bottom: 0;
    margin-top: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  #gatsby-focus-wrapper {
    ${Preserve3dMixin}
    display: flex;
    pointer-events: none;
  }

  #___gatsby {
    ${Preserve3dMixin}
  }

  .gatsby-image-wrapper img,
  .gatsby-image-wrapper > div {
    transition: opacity 500ms ease 0s !important;
  }

  #hubspot-messages-iframe-container {
    ${({ theme: { breakpoints } }) => breakpoints.desktop}{
      margin-bottom: -400vh;
      ${CalculateFromWidth("margin-right", { desktop: 3.5 })};
    }
  }
`;

export default GlobalStyles;
