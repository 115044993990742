import React, { RefObject, useState } from "react";

import { Sprite, useTick } from "@inlet/react-pixi";
import type { CloudSettings } from "components/rendering/groups/CloudGroup";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import type { Sprite as SpriteType } from "pixi.js";
import { LoadImageData } from "providers/RenderElementsProvider";

interface NoiseLayerProps {
  parentRect: DOMRect;
  noiseImageData: IGatsbyImageData;
  noiseRef: RefObject<SpriteType>;
  settings: CloudSettings;
  onLoadCallback: () => void;
}

export const NoiseLayer = ({
  parentRect,
  noiseImageData,
  noiseRef,
  settings,
  onLoadCallback
}: NoiseLayerProps) => {
  const [noiseOffset, setNoiseOffset] = useState({ x: 0, y: 0, amplitude: 0, circle: 0 });
  //Fake warning here - adding parentRect to array causes infinite rerender
  /* eslint-disable */
  useFirstRenderEffect(() => {
    LoadImageData(
      noiseImageData,
      parentRect,
      newTexture => {
        if (noiseRef.current && noiseRef.current !== null) {
          noiseRef.current.texture = newTexture;
          onLoadCallback();
        }
      },
      0.5
    );
  }, [noiseImageData, noiseRef]);
  /* eslint-enable */

  useTick(delta => {
    const speed = settings.noiseScrollSpeed;
    let x = 0;
    let y = 0;
    const amplitude = noiseOffset.amplitude + (delta / 50) * speed.y;
    let circle = 0;

    if (settings.noiseScrollType === "inplace") {
      setNoiseOffset({ x: x, y: y, amplitude: amplitude, circle: 0 });
    } else if (settings.noiseScrollType === "circular") {
      circle = noiseOffset.circle + ((delta * speed.x) / 100) * Math.random();
      x = parentRect.width / 2 + (parentRect.width / 3) * Math.cos(amplitude);
      y = parentRect.height / 2 + (parentRect.height / 3) * Math.sin(amplitude);
      setNoiseOffset({ x: x, y: y, amplitude: amplitude, circle: circle });
    }
  });

  return (
    <>
      <Sprite
        ref={noiseRef}
        image={noiseImageData.placeholder!.fallback}
        anchor={0.5}
        x={parentRect.left + noiseOffset.x}
        y={parentRect.top + noiseOffset.y}
        width={
          noiseRef.current?.visible !== true
            ? 0
            : parentRect.width * 3 +
              parentRect.width *
                (0.5 - Math.sin(noiseOffset.amplitude) / 2) *
                settings.noiseScaleStrength.x
        }
        height={
          parentRect.height * 3 +
          parentRect.height *
            (0.5 - Math.sin(noiseOffset.amplitude) / 2) *
            settings.noiseScaleStrength.x
        }
        visible
      />
    </>
  );
};

export default NoiseLayer;
