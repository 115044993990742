import React, { useRef } from "react";

import {
  CookieButton,
  CookieButtonsWrapper,
  CookieLink,
  CookieNotificationWrapper,
  CookieText
} from "components/layout/CookieNotification/CookieNotification.styled";
import gsap, { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Cookies from "js-cookie";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";
import Translate from "utils/translation/translation";

type CookieConsentType = {
  agreedToPrivacyPolicy: boolean;
  date: Date;
};

export const CookieNotification = () => {
  const cookieWrapper = useRef<HTMLDivElement>(null);
  const acceptButton = useRef<HTMLButtonElement>(null);
  const buttonArrow = useRef<HTMLDivElement>(null);

  const storeConsent = () => {
    Cookies.set("cookieConsent", JSON.stringify({ agreedToPrivacyPolicy: true, date: Date() }));
    gsap.to(cookieWrapper.current, { y: 30, opacity: 0, duration: 0.75 });
    InvokeAnalyticsEvent(
      "click",
      window.location,
      "actions",
      "cookies-accept",
      undefined,
      undefined
    );
  };

  const activateButton = () => {
    gsap.to(buttonArrow.current, { x: 10, duration: 0.25, ease: Circ.easeOut });
    InvokeAnalyticsEvent(
      "hover",
      window.location,
      "actions",
      "cookies-accept",
      undefined,
      undefined
    );
  };

  const deactivateButton = () => {
    gsap.to(buttonArrow.current, { x: 0, duration: 0.25, ease: Circ.easeOut, display: "flex" });
  };

  const onPrivacyPolicyClick = () => {
    InvokeAnalyticsEvent(
      "click",
      window.location,
      "actions",
      "cookies-privacy-policy",
      undefined,
      undefined
    );
  };

  const onPrivacyPolicyHover = () => {
    InvokeAnalyticsEvent(
      "hover",
      window.location,
      "actions",
      "cookies-privacy-policy",
      undefined,
      undefined
    );
  };

  const handleHideBanner = () => {
    if (cookieWrapper.current) {
      gsap.to(cookieWrapper.current, { y: 30, opacity: 0, duration: 0.75 });
    }
  };

  useFirstRenderEffect(() => {
    const cookieRaw = Cookies.get("cookieConsent");

    if (typeof cookieRaw === "undefined" || cookieRaw === null) {
      Cookies.set("cookieConsent", JSON.stringify({ agreedToPrivacyPolicy: false, date: 0 }));
    }

    const currentConsent: CookieConsentType = JSON.parse(Cookies.get("cookieConsent"));

    if (!cookieWrapper.current) {
      return;
    }

    if (currentConsent.agreedToPrivacyPolicy) {
      cookieWrapper.current.style.display = "none";

      return;
    }

    cookieWrapper.current.style.display = "visible";
  });

  return (
    <CookieNotificationWrapper ref={cookieWrapper}>
      <CookieText>
        {Translate("Common_CookieNotification_Text_First")}
        <CookieLink
          to="/privacy-policy/"
          onMouseEnter={onPrivacyPolicyHover}
          onClick={onPrivacyPolicyClick}
        >
          {Translate("Common_CookieNotification_PrivacyPolicy")}
        </CookieLink>
        {Translate("Common_CookieNotification_Text_Second")}
      </CookieText>
      <CookieButtonsWrapper>
        <CookieButton onClick={handleHideBanner}>
          {Translate("Common_CookieNotification_Reject")}
        </CookieButton>
        <CookieButton
          ref={acceptButton}
          onClick={storeConsent}
          onMouseEnter={activateButton}
          onMouseLeave={deactivateButton}
          darkVariant
        >
          {Translate("Common_CookieNotification_Accept")}
        </CookieButton>
      </CookieButtonsWrapper>
    </CookieNotificationWrapper>
  );
};

export default CookieNotification;
