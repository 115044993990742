/* eslint-disable @typescript-eslint/no-empty-function */

import {
  createContext,
  DependencyList,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react";
import React, { useContext } from "react";

import { useLocation } from "@reach/router";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import LocomotiveScroll, { type LocomotiveScrollOptions, Scroll, Smooth } from "locomotive-scroll";
import { clamp } from "lodash";
import { useDebounce } from "use-debounce";
import useResizeObserver from "use-resize-observer";
import { dev } from "utils/dev-mode";

export interface LocomotiveScrollContextValue {
  scroll: Scroll | null;
  isReady: boolean;
  scrollHeight: number;
  containerHeight: number | undefined;
}

export interface LocomotiveScrollProviderProps {
  options: LocomotiveScrollOptions;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  watch: DependencyList | undefined;
}

export const LocomotiveScrollContext = createContext<LocomotiveScrollContextValue>({
  scroll: null,
  isReady: false,
  scrollHeight: 0,
  containerHeight: 0
});

export const useLocomotiveScroll = (): LocomotiveScrollContextValue => {
  return useContext(LocomotiveScrollContext);
};

export const LocomotiveProvider: Component<LocomotiveScrollProviderProps> = ({
  children,
  options,
  containerRef,
  watch
}) => {
  const [scrollLimit, setScrollLimit] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const LocomotiveScrollRef = useRef<Scroll | null>(null);
  const { height: containerHeight } = useResizeObserver<HTMLDivElement>({ ref: containerRef });
  const [height] = useDebounce(containerHeight, 100);
  const location = useLocation();
  const currentWindowSize = useRef(0);

  if (!watch) {
    console.warn(
      "react-locomotive-scroll: you did not add any props to watch. Scroll may have weird behaviors if the instance is not updated when the route changes"
    );
  }

  useFirstRenderEffect(() => {
    if (LocomotiveScrollRef.current) {
      return;
    }

    try {
      const dataScrollContainer = document.querySelector("[data-scroll-container]");

      if (!dataScrollContainer) {
        console.warn(
          `react-locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.`
        );
      }

      LocomotiveScrollRef.current = new LocomotiveScroll({
        el: dataScrollContainer ?? undefined,
        ...options
      });
      dev.log("Created Scroll");
    } catch (error) {
      throw Error(`react-locomotive-scroll: ${error}`);
    }
  }, [isReady]);

  useEffect(() => {
    return () => {
      dev.log("Destroying Scroll");

      if (LocomotiveScrollRef.current) {
        const smoothScroll = LocomotiveScrollRef.current.scroll as Smooth;

        smoothScroll.isScrolling = false;
        smoothScroll.stop = true;
        LocomotiveScrollRef.current.scroll.smooth = false;
        LocomotiveScrollRef.current.smooth = false;

        if (typeof smoothScroll.vs !== "undefined") {
          smoothScroll.vs.listenerOptions.passive = false;
          smoothScroll.vs._onMouseWheel = () => {};
          smoothScroll.vs._onKeyDown = () => {};
          smoothScroll.vs._onTouchMove = () => {};
          smoothScroll.vs._onTouchStart = () => {};
          smoothScroll.vs._onWheel = () => {};
        }

        LocomotiveScrollRef.current.stop();
        LocomotiveScrollRef.current.destroy();
        LocomotiveScrollRef.current.name = "new name";
      }

      LocomotiveScrollRef.current?.destroy();
      setIsReady(false);
      LocomotiveScrollRef.current = null;
    };
  }, [location]);

  useFirstRenderEffect(
    () => {
      if (height !== undefined && height > 0) {
        LocomotiveScrollRef.current?.update();
        setIsReady(true);
      }
    },
    watch ? [...watch, height] : [height]
  );

  const invokeResize = () => {
    dev.log("resize!");
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 50);
  };

  const updateLocomotiveScroll = () => {
    if (!LocomotiveScrollRef.current) {
      return;
    }

    const locomotive = LocomotiveScrollRef.current.scroll;

    LocomotiveScrollRef.current?.stop();
    locomotive.instance.delta.y = clamp(
      locomotive.instance.delta.y,
      0,
      locomotive.instance.limit.y
    );
    locomotive.instance.scroll.y = clamp(
      locomotive.instance.scroll.y,
      0,
      locomotive.instance.limit.y
    );

    const smoothScroll = LocomotiveScrollRef.current.scroll as unknown as Smooth;

    if (smoothScroll) {
      smoothScroll.isScrolling = true;
      smoothScroll.animatingScroll = true;
      locomotive.checkScroll(true);
    }

    LocomotiveScrollRef.current?.start();
    LocomotiveScrollRef.current.scroll.scrollTo(locomotive.instance.scroll.y, {
      offset: (currentWindowSize.current - window.innerHeight) / 2,
      duration: 50,
      easing: [0, 0.5, 0.5, 0.5],
      disableLerp: false
    });
    currentWindowSize.current = window.innerHeight;
  };

  useFirstRenderEffect(() => {
    window.addEventListener("orientationchange", invokeResize);

    if (LocomotiveScrollRef.current) {
      window.addEventListener("resize", updateLocomotiveScroll);
    }

    return () => {
      window.removeEventListener("resize", updateLocomotiveScroll);
      window.removeEventListener("orientationchange", invokeResize);
    };
  }, []);

  useEffect(() => {
    const scrollLimit = LocomotiveScrollRef.current?.scroll.instance.limit;

    setScrollLimit(scrollLimit?.y ?? 1); // Re-render the context
    dev.log("Updating Scroll with limit", scrollLimit?.y ?? 1);
    currentWindowSize.current = window.innerHeight;
  }, [LocomotiveScrollRef.current?.scroll.instance.limit.y, height]);

  return (
    <LocomotiveScrollContext.Provider
      value={{
        scroll: LocomotiveScrollRef.current,
        isReady: isReady,
        scrollHeight: scrollLimit,
        containerHeight: height
      }}
    >
      {children}
    </LocomotiveScrollContext.Provider>
  );
};

export default LocomotiveProvider;
