import { cloneElement } from "react";

import { useLocation } from "@reach/router";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";

export interface TrackedProps {
  children: JSX.Element;
  category: string;
  action: string;
}

export const TrackedElement: Component<TrackedProps> = ({
  children,
  category = "default",
  action = "default"
}) => {
  const location = useLocation();

  const onClick = () => {
    InvokeAnalyticsEvent("click", location, category, action, undefined, undefined);
  };

  const onHover = () => {
    InvokeAnalyticsEvent("hover", location, category, action, undefined, undefined, true);
  };

  return cloneElement(children, {
    onClick: GetOnClick(children, onClick),
    onMouseEnter: GetOnHover(children, onHover)
  });
};

const GetOnClick = (children, onClick) => {
  if (children.props.onClick) {
    return (el, event) => {
      onClick();
      children.props.onClick(el, event);
    };
  }

  return onClick;
};

const GetOnHover = (children, onMouseEnter) => {
  if (children.props.onMouseEnter) {
    return (el, event) => {
      onMouseEnter();
      children.props.onMouseEnter(el, event);
    };
  }

  return onMouseEnter;
};

export default TrackedElement;
