import { cloneElement } from "react";

import { useLocation } from "@reach/router";
import type { TrackedProps } from "components/common/analytics/TrackedElement";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";

export const TrackedInput: Component<TrackedProps> = ({
  children,
  category = "default",
  action = "default"
}) => {
  const location = useLocation();
  const onClick = () => {
    InvokeAnalyticsEvent("click", location, category, action, undefined, undefined);
  };

  const onHover = () => {
    InvokeAnalyticsEvent("hover", location, category, action, undefined, undefined, true);
  };

  const onBlur = () => {
    InvokeAnalyticsEvent("completed", location, category, action, undefined, undefined, true);
  };

  return cloneElement(children, {
    onClick: GetOnClick(children, onClick),
    onMouseEnter: onHover,
    onBlur: onBlur
  });
};

const GetOnClick = (children, onClick) => {
  if (children.props.onClick) {
    return (el, event) => {
      onClick();
      children.props.onClick(el, event);
    };
  }

  return onClick;
};

export default TrackedInput;
