import TranslationData from "data/translation.json";

let translation: Record<string, string>;
const debug = false;

const GetCurrentLanguage = () => {
  return "en";
};

const Translate = (key: string) => {
  if (typeof translation === "undefined") {
    SetupTranslation();
  }

  const value = translation[key];
  const language = GetCurrentLanguage();

  if (
    typeof value === "undefined" ||
    value === null ||
    typeof value[language] === "undefined" ||
    value[language] === null
  ) {
    return "";
  } else {
    return debug ? "Ok" : value[language];
  }
};

export const SetupTranslation = () => {
  translation = TranslationData.TranslationNew.reduce((result, item) => {
    result[item.key] = {
      en: item.text_en,
      pl: item.text_pl
    };

    return result;
  }, {});
};

export default Translate;
