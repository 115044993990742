import { cloneElement } from "react";

import { useLocation } from "@reach/router";
import type { TrackedProps } from "components/common/analytics/TrackedElement";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";

export const TrackedButton: Component<TrackedProps> = ({
  children,
  category = "default",
  action = "default"
}) => {
  const location = useLocation();

  const onClick = () => {
    InvokeAnalyticsEvent("click", location, category, action, undefined, undefined);
  };

  const onHover = () => {
    InvokeAnalyticsEvent("hover", location, category, action, undefined, undefined, true);
  };

  return cloneElement(children, { onTrackedClick: onClick, onTrackedHover: onHover });
};

export default TrackedButton;
