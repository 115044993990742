import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { AnimatedTransform, Preserve3dMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

const FooterSidePanelProps = css`
  ${CalculateFromHeight("margin-top", { desktop: 5 })}
  ${CalculateFromHeight("margin-bottom", { desktop: -5 })}
  ${CalculateFromWidth("left", { tablet: 1.5, desktop: 2.2 })}
  ${CalculateFromWidth("width", { mobile: 4, tablet: 4, desktop: 4 })}
  position: absolute;
  height: calc(var(--vh, 1vh) * 100);
  bottom: 0;
  ${AnimatedTransform}
`;

export const SidePanelWrapper = styled.div<{ inFooter: boolean }>`
  ${CalculateFromWidth("left", { desktop: 2.2 })}
  ${CalculateFromWidth("width", { desktop: 4 })}
  ${CalculateFromHeight("margin-top", { desktop: 5 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
  position: fixed;
  ${Preserve3dMixin};
  transform: translateZ(5px);
  height: 100vh;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  pointer-events: all;
  ${({ inFooter }) => inFooter && FooterSidePanelProps}
  z-index: 1;
`;

export const SidePanelSigilWrapper = styled.button`
  ${CalculateFromWidth("padding-left", { desktop: 1 })}
  ${CalculateFromHeight("padding-top", { mobile: 2.75, tablet: 2.75, desktop: 0.225 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    width: 100%;
    height: fit-content;
    display: none;
    pointer-events: none;
  }
  position: fixed;
`;

export const SocialSigil = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-right: auto;
  }
  ${CalculateFromHeight("margin-top", { tablet: 0.6, desktop: -0.3 })}
  ${CalculateFromWidth("margin-top", { mobile: 0.75 })}
  ${CalculateFromHeight("width", { mobile: 7.5, tablet: 5.25, desktop: 3.4 })}
  ${CalculateFromHeight("height", { mobile: 7.5, tablet: 4.25, desktop: 3.3 })}
  ${CalculateFromWidth("margin-left", { mobile: 2, tablet: -1, desktop: 0.1 })}
`;
