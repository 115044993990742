import { useEffect, useState } from "react";

import { isWindowUndefined } from "utils/window-utils";

export const useFirstRenderState = () => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (isWindowUndefined()) {
      return;
    }

    setFirstRender(false);
  }, []);

  return firstRender;
};

/* Template for quick copy of hook implementation.
import { useFirstRenderState } from "hooks/use-first-render-state"
const firstRender = useFirstRenderState()
    if (firstRender) {
      return
    }
*/
