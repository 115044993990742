import React, { RefObject, useEffect, useRef } from "react";

import TrackedElement from "components/common/analytics/TrackedElement";
import DreamStormLogo from "components/common/other/DreamstormLogo/DreamstormLogo";
import {
  SidePanelSigilWrapper,
  SidePanelWrapper
} from "components/layout/SidePanel/SidePanel.styled";
import SocialIcons from "components/layout/SocialIcons/SocialIcons";
import gsap from "gsap/gsap-core";
import LocomotiveScroll from "locomotive-scroll";
import { isScrollUndefined } from "utils/window-utils";

interface SidePanelProps {
  animateNavigation?: boolean;
  elementRef?: RefObject<HTMLDivElement>;
  iconsVisible?: boolean;
  sigilVisible?: boolean;
  inFooter?: boolean;
}

export const SidePanel: Component<SidePanelProps> = ({
  animateNavigation = false,
  inFooter = false,
  elementRef = null,
  children = null,
  iconsVisible = true,
  sigilVisible = true
}) => {
  const socialIcons = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!animateNavigation) {
      gsap.set(socialIcons.current, { opacity: 1 });

      return;
    }

    gsap.to(socialIcons.current, { opacity: 1, delay: 2, duration: 1 });
  }, [animateNavigation]);

  const ScrollToTop = () => {
    if (isScrollUndefined()) {
      return;
    }

    ((window.scroll as any).scroll as LocomotiveScroll).scrollTo(0);
  };

  return (
    <SidePanelWrapper inFooter={inFooter} ref={elementRef}>
      {sigilVisible && (
        <TrackedElement category="navigation" action="sidepanel-logo">
          <SidePanelSigilWrapper onClick={ScrollToTop}>
            <DreamStormLogo logoVariant="sigil" color="white" wrapperVariant="social-sigil" />
          </SidePanelSigilWrapper>
        </TrackedElement>
      )}
      {children}
      <SocialIcons
        inMenu={false}
        variant="social-icon-wrapper"
        elementRef={socialIcons}
        style={{ opacity: 0, display: iconsVisible ? undefined : "none" }}
        isBlog={false}
      />
    </SidePanelWrapper>
  );
};

export default SidePanel;
