import React, { MutableRefObject, useRef } from "react";

import {
  ButtonCta,
  CtaButtonArrow,
  CtaButtonColor,
  CtaButtonContentWrapper,
  CtaButtonText,
  type CtaButtonVariant,
  CtaButtonWrapper,
  FilledCircle,
  FilledCircleHover
} from "components/common/buttons/ButtonCta/ButtonCta.styled";
import { useContactContext } from "contexts/ContactFormContext";
import gsap, { Power2, Power3 } from "gsap/gsap-core";
import Arrow from "media/images/other/arrow.svg";
import type { ElementRect } from "utils/animations/foreground-utils";
import Translate from "utils/translation/translation";

interface CtaButtonProps {
  text?: string;
  type?: string;
  color?: CtaButtonColor;
  variant?: CtaButtonVariant;
  wide?: boolean;
  onClick?: () => void;
  transitionData?: MutableRefObject<ElementRect | null>;
  onPointerDown?: () => void;
  refButton?: MutableRefObject<HTMLButtonElement>;
  onTrackedClick?: () => void;
  onTrackedHover?: () => void;
  hiderArrow?: boolean;
}

export const CtaButton = ({
  color = "white",
  variant,
  wide = false,
  text = "Common_Link_StartAProject",
  type = "default",
  onClick,
  transitionData,
  refButton,
  onTrackedClick,
  onTrackedHover,
  hiderArrow = false
}: CtaButtonProps) => {
  const filledCircleHover = useRef<HTMLDivElement>(null);
  const filledCircle = useRef<HTMLDivElement>(null);
  const buttonContentWrapper = useRef<HTMLDivElement>(null);
  const buttonArrow = useRef<HTMLImageElement>(null);
  const buttonText = useRef<HTMLParagraphElement>(null);
  const colorData = GetVariantStyle(color);
  const [{ displayForm }] = useContactContext();
  const button = useRef<HTMLDivElement>(null);
  const buttonElement = useRef<HTMLButtonElement>(null);

  const ActivateButton = () => {
    gsap.to(filledCircle.current, { scale: 0.75, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircleHover.current, { scale: 0.85, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonContentWrapper.current, { scale: 0.9, ease: Power2.easeInOut, duration: 0.5 });
    gsap.to(buttonText.current, {
      css: { color: colorData.targetTextColor },
      delay: 0.1875,
      duration: 0.125
    });
    gsap.to(buttonArrow.current, {
      filter: colorData.arrowEndFilter,
      delay: 0.1875,
      duration: 0.125
    });
    onTrackedHover && onTrackedHover();
  };

  const DeactivateButton = () => {
    gsap.to(filledCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircleHover.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonContentWrapper.current, { scale: 1, ease: Power2.easeInOut, duration: 0.5 });
    gsap.to(buttonText.current, {
      css: { color: colorData.initialTextColor },
      delay: 0.1875,
      duration: 0.125
    });
    gsap.to(buttonArrow.current, {
      filter: colorData.arrowStartFilter,
      delay: 0.1875,
      duration: 0.125
    });
  };

  const UnclickButton = () => {
    ActivateButton();
    gsap.to(filledCircle.current, { scale: 1, ease: Power3.easeInOut, delay: 0.5, duration: 0.5 });
    gsap.to(filledCircleHover.current, {
      scale: 0,
      ease: Power3.easeInOut,
      delay: 0.5,
      duration: 0.5
    });
    gsap.to(buttonContentWrapper.current, {
      scale: 1,
      ease: Power2.easeInOut,
      delay: 0.5,
      duration: 0.5
    });
    gsap.to(buttonText.current, {
      css: { color: colorData.initialTextColor },
      delay: 0.5,
      duration: 0.125
    });
    gsap.to(buttonArrow.current, {
      filter: colorData.arrowStartFilter,
      delay: 0.5,
      duration: 0.125
    });
  };
  const buttonBody = (
    <>
      <FilledCircleHover
        wide={wide}
        variant={color}
        ref={filledCircleHover}
        style={{ transform: "scale(0,0)" }}
      />
      <FilledCircle wide={wide} variant={color} ref={filledCircle} />
      <CtaButtonContentWrapper ref={buttonContentWrapper}>
        <CtaButtonText variant={color} ref={buttonText}>
          {Translate(text)}
        </CtaButtonText>
        {!hiderArrow && (
          <CtaButtonArrow variant={color} ref={buttonArrow} src={Arrow} alt="Contact us" />
        )}
      </CtaButtonContentWrapper>
    </>
  );

  const ShowContactForm = () => {
    if (displayForm.current && button.current) {
      displayForm.current(button.current, transitionData);
    }

    if (typeof onClick !== "undefined") {
      onClick();
    }
  };

  const InvokeCallback = callback => {
    if (typeof callback !== "undefined") {
      callback();
    }

    if (typeof refButton !== "undefined") {
      refButton.current.blur();
    } else if (buttonElement.current) {
      buttonElement.current.blur();
    }

    onTrackedClick && onTrackedClick();
  };

  switch (type) {
    case "submit":
      return (
        <CtaButtonWrapper wide={wide} variant={variant} onClick={UnclickButton}>
          <ButtonCta
            tabIndex={-1}
            onMouseLeave={DeactivateButton}
            onMouseEnter={ActivateButton}
            type="submit"
            onClick={() => InvokeCallback(onClick)}
            ref={refButton ?? buttonElement}
          >
            {buttonBody}
          </ButtonCta>
        </CtaButtonWrapper>
      );
    default:
      return (
        <CtaButtonWrapper wide={wide} variant={variant} ref={button}>
          <ButtonCta
            tabIndex={-1}
            onMouseLeave={DeactivateButton}
            onMouseEnter={ActivateButton}
            onClick={() => InvokeCallback(ShowContactForm)}
            ref={refButton ?? buttonElement}
          >
            {buttonBody}
          </ButtonCta>
        </CtaButtonWrapper>
      );
  }
};

export default CtaButton;

const GetVariantStyle = (color: string) => {
  switch (color) {
    case "black":
      return {
        normalStyle: "filled-circle-background background-black",
        hoverStyle: "filled-circle-foreground background-white",
        textStyle: "cta-button-text text-white",
        arrowStyle: "cta-button-arrow invert-colors",
        initialTextColor: "#fff",
        targetTextColor: "#000",
        arrowStartFilter: "invert(1)",
        arrowEndFilter: "invert(0)"
      };
    case "white":
    default:
      return {
        normalStyle: "filled-circle-background background-white",
        hoverStyle: "filled-circle-foreground background-blue",
        textStyle: "cta-button-text text-black",
        arrowStyle: "cta-button-arrow",
        startingTextColor: "#000",
        targetTextColor: "#000",
        arrowStartFilter: "",
        arrowEndFilter: ""
      };
  }
};
