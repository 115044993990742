import { CalculateFontSize } from "styled/base/SizeFunctions";
import { CenterFlex } from "styled/common/Utils";
import { css } from "styled-components";

export const CtaButtonContentWrapperFont = css`
  ${CenterFlex}
  text-decoration: none;
  ${({ theme: { fonts } }) => fonts.regular}
  ${CalculateFontSize("line-height", { mobile: 2, tablet: 2, desktop: 2 })}
  white-space: nowrap;
`;
