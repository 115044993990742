import React, { Key, Ref } from "react";

import { Container } from "@inlet/react-pixi";
import RenderMaskedElements from "components/rendering/groups/RenderMaskedElements";
import MaskLayer from "components/rendering/layers/MaskLayer";
import type { MaskedGroupType } from "components/rendering/RenderingStages/RenderBackgroundStage";
import type { ImageContextData } from "contexts/ImageContext";
import type { Container as PIXIContainer, Graphics } from "pixi.js";

interface RenderMaskProps {
  maskRef: Ref<Graphics>;
  containerRef: Ref<PIXIContainer>;
  maskedGroup: MaskedGroupType;
  imageDatabase: ImageContextData;
  containerKey: Key;
}

const RenderMask = ({
  maskRef,
  containerRef,
  maskedGroup,
  imageDatabase,
  containerKey
}: RenderMaskProps) => {
  return (
    <Container key={containerKey}>
      <MaskLayer parent={maskedGroup.parent} maskRef={maskRef} />
      <RenderMaskedElements
        containerRef={containerRef}
        maskedGroup={maskedGroup}
        imageDatabase={imageDatabase}
      />
    </Container>
  );
};

export default RenderMask;
