import { useEffect, useRef } from "react";

import { useLocation } from "@reach/router";
import { useLocomotiveScroll } from "contexts/LocomotiveContext";
import {
  clearScrollCallbacks,
  GetScrollPosition,
  recalculateCallbacks,
  removeCallback,
  updateLocomotiveScrollData,
  useScrollContext
} from "contexts/ScrollContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";
import { dev } from "utils/dev-mode";

export const ContentScroll = () => {
  const locomotiveScroll = useLocomotiveScroll();
  const locomotiveScrollRef = useRef(locomotiveScroll);
  const [callbackList, dispatch] = useScrollContext();
  const scrollPos = useRef(0);
  const maxScrollPos = useRef(0);
  const location = useLocation();
  const callbacks = useRef(callbackList);

  useEffect(() => {
    locomotiveScrollRef.current = locomotiveScroll;
    callbacks.current = callbackList;
  });

  const ScrollUpdate = () => {
    scrollPos.current = GetScrollPosition();

    if (scrollPos.current >= 0.999999 && maxScrollPos.current <= 1) {
      scrollPos.current = 1;
    }

    if (scrollPos.current >= 0.999999 && maxScrollPos.current === 1) {
      return;
    }

    callbacks.current.CallbackList.forEach(({ id, scrollPosition, methodToInvoke, isOneShot }) => {
      if (scrollPos.current >= Number(scrollPosition)) {
        methodToInvoke?.();

        if (isOneShot) {
          dispatch(removeCallback(id));
        }
      }
    });

    let thresholdToReport;

    if (scrollPos.current > maxScrollPos.current) {
      if (scrollPos.current >= 0.25 && maxScrollPos.current < 0.25) {
        thresholdToReport = 25;
      }

      if (scrollPos.current >= 0.5 && maxScrollPos.current < 0.5) {
        thresholdToReport = 50;
      }

      if (scrollPos.current >= 0.75 && maxScrollPos.current < 0.75) {
        thresholdToReport = 75;
      }

      if (scrollPos.current >= 0.99 && maxScrollPos.current < 0.99) {
        thresholdToReport = 100;
      }

      maxScrollPos.current = scrollPos.current;
    }

    if (typeof thresholdToReport !== "undefined" && thresholdToReport !== null) {
      InvokeAnalyticsEvent(
        "scroll",
        location,
        "scroll",
        "PAGENAME-scroll-depth",
        thresholdToReport + "%",
        undefined
      );
    }
  };

  useEffect(() => {
    if (locomotiveScroll.isReady) {
      dev.log("Attaching scroll");
      document.documentElement.setAttribute("data-direction", "vertical");

      if (locomotiveScroll.scroll) {
        dispatch(
          updateLocomotiveScrollData({
            isReady: locomotiveScroll.isReady,
            scrollLimit: locomotiveScroll.scroll!.scroll!.instance!.limit.y
          })
        );
        locomotiveScroll.scroll?.on("scroll", ScrollUpdate);
      }
    }
  }, [locomotiveScroll.isReady]);

  useFirstRenderEffect(() => {
    if (!locomotiveScroll.isReady) {
      return;
    }

    const scrollLock = document.getElementById("scrollLock");

    if (scrollLock !== null && scrollLock.parentNode !== null) {
      scrollLock.parentNode.removeChild(scrollLock);
    }

    window.scroll = locomotiveScroll as any;

    if (locomotiveScroll.scroll) {
      dispatch(
        updateLocomotiveScrollData({
          isReady: locomotiveScroll.isReady,
          scrollLimit: locomotiveScroll.scroll!.scroll!.instance!.limit.y
        })
      );
    }
  }, [locomotiveScroll.isReady]);

  useEffect(() => {
    if (callbackList || callbacks.current) {
      dev.log("Clearing callbacks");
      dispatch(clearScrollCallbacks());
    }
  }, [location]);

  useEffect(() => {
    if (
      !locomotiveScrollRef.current.scroll ||
      !locomotiveScrollRef.current.isReady ||
      !locomotiveScroll.isReady ||
      locomotiveScrollRef.current.scroll!.scroll!.instance!.limit.y < window.innerHeight
    ) {
      return;
    }

    dev.log("Recalculating callback");
    dispatch(
      updateLocomotiveScrollData({
        isReady: locomotiveScrollRef.current.isReady,
        scrollLimit: locomotiveScrollRef.current.scroll!.scroll!.instance!.limit.y
      })
    );
    dispatch(recalculateCallbacks());
    callbacks.current = callbackList;
  }, [
    locomotiveScrollRef.current.scrollHeight,
    location,
    locomotiveScrollRef.current.scroll?.scroll?.instance?.limit.y,
    locomotiveScrollRef.current.containerHeight
  ]);

  return null;
};

export default ContentScroll;
