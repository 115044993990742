import React, { CSSProperties, RefObject } from "react";

import {
  RendererBackground,
  RendererContainer,
  RendererElementsWrapper,
  type RendererWrapperVariant
} from "components/rendering/RendererWrapper/RendererWrapper.styled";
import RenderStaticElements from "components/rendering/RenderStaticElements/RenderStaticElements";

interface RendererWrapperProps {
  type: string;
  additionalData: string;
  elementRef: RefObject<HTMLDivElement>;
  variant: RendererWrapperVariant;
  style?: CSSProperties;
  mobileRef?: RefObject<HTMLDivElement>;
}

export const RendererWrapper: Component<RendererWrapperProps> = ({
  type,
  additionalData,
  elementRef,
  variant,
  style,
  children,
  mobileRef
}) => {
  return (
    <RendererContainer ref={mobileRef} variant={variant} style={style}>
      <RendererElementsWrapper>
        <RendererBackground ref={elementRef} style={{ ...style }} />
        <RendererBackground>
          <RenderStaticElements type={type} additionalData={additionalData} />
        </RendererBackground>
        {children}
      </RendererElementsWrapper>
    </RendererContainer>
  );
};

export default RendererWrapper;
