import React from "react";

import TrackedElement from "components/common/analytics/TrackedElement";
import { LinkMainMenu } from "components/layout/Menu/MainMenu.styled";
import { GetPageNameFromPath } from "providers/AnalyticsServiceProvider";
import {
  AnimatedLinkMenuTextWrapper,
  AnimatedLinksAnimatedSpan
} from "styled/common/AnimatedLinks";
import Translate from "utils/translation/translation";

interface MainMenuLink {
  hideMainMenu: () => void;
  location: string;
  text: string;
}

const MainMenuLink = ({ hideMainMenu, location, text }: MainMenuLink) => {
  return (
    <TrackedElement category="navigation" action={"menu-" + GetPageNameFromPath(location)}>
      <LinkMainMenu to={location} onClick={hideMainMenu}>
        <AnimatedLinkMenuTextWrapper>
          <AnimatedLinksAnimatedSpan data-hover={Translate(text)}>
            {Translate(text)}
          </AnimatedLinksAnimatedSpan>
        </AnimatedLinkMenuTextWrapper>
      </LinkMainMenu>
    </TrackedElement>
  );
};

export default MainMenuLink;
