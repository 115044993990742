import React, { RefObject } from "react";

import {
  StandardButtonArrow,
  StandardButtonCircleWrapper,
  StandardButtonEmptyCircle,
  StandardButtonFilledCircle,
  StandardButtonLink,
  StandardButtonText,
  StandardButtonTextParent,
  StandardButtonWrapper
} from "components/common/buttons/ButtonStandard/ButtonStandard.styled";
import { Link } from "gatsby";
import Translate from "utils/translation/translation";

interface RenderButtonBodyProps {
  url?: string;
  emptyCircle: RefObject<HTMLDivElement>;
  filledCircle: RefObject<HTMLDivElement>;
  arrow: string;
  buttonText: RefObject<HTMLParagraphElement>;
  text: string;
  external: boolean;
}

const ButtonBody = ({
  url,
  emptyCircle,
  filledCircle,
  arrow,
  buttonText,
  text,
  external
}: RenderButtonBodyProps) => {
  const buttonBody = (
    <StandardButtonWrapper style={{ cursor: "pointer" }}>
      <StandardButtonCircleWrapper>
        <StandardButtonEmptyCircle ref={emptyCircle} />
        <StandardButtonFilledCircle ref={filledCircle} style={{ transform: "scale(0,0)" }}>
          <StandardButtonArrow src={arrow} alt="" />
        </StandardButtonFilledCircle>
      </StandardButtonCircleWrapper>
      <StandardButtonTextParent>
        <StandardButtonText ref={buttonText}>{Translate(text)}</StandardButtonText>
      </StandardButtonTextParent>
    </StandardButtonWrapper>
  );

  if (url) {
    return external ? (
      <StandardButtonLink href={url} rel="noreferrer" target="_blank">
        {buttonBody}
      </StandardButtonLink>
    ) : (
      <Link to={url}>{buttonBody}</Link>
    );
  } else {
    return <StandardButtonLink as="span">{buttonBody}</StandardButtonLink>;
  }
};

export default ButtonBody;
