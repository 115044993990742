import { isDesktop, isMobile } from "react-device-detect";

export type WindowSize = {
  width: number;
  height: number;
};

export const isWindowUndefined = () => {
  return typeof window === "undefined" || typeof document === "undefined";
};

export const getWindowSize = (): WindowSize => {
  if (isWindowUndefined()) {
    return {
      width: 0,
      height: 0
    };
  }

  return {
    width: document.documentElement.clientWidth || window.innerWidth,
    height: document.documentElement.clientHeight || window.innerHeight
  };
};

export const isScrollUndefined = () => {
  return isWindowUndefined() || !window.scroll || !(window.scroll as any).scroll;
};

export const ifDesktopCallback = (callback: () => void) => {
  return isDesktop ? callback : undefined;
};
export const ifMobileCallback = (callback: () => void) => {
  return isMobile ? callback : undefined;
};
