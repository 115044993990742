import React, { RefObject } from "react";

import {
  type DreamstormLogoVariant,
  Logo,
  LogoWrapper
} from "components/common/other/DreamstormLogo/DreamstormLogo.styled";
import EmblemBlack from "media/images/dreamstorm/emblem-black.svg";
/* --------- TODO: Add later on -------- */
import EmblemWhite from "media/images/dreamstorm/emblem-black.svg";
import LogoWhite from "media/images/dreamstorm/logo.svg";
import LogoBlack from "media/images/dreamstorm/logo-black.svg";
import LogotypeWhite from "media/images/dreamstorm/logotype.svg";
import LogotypeBlack from "media/images/dreamstorm/logotype-black.svg";
import SigilWhite from "media/images/dreamstorm/sigil.svg";
import SigilBlack from "media/images/dreamstorm/sigil-black.svg";

interface DreamStormLogoProps {
  wrapperVariant: DreamstormLogoVariant;
  logoVariant: string;
  color: string;
  reference?: RefObject<HTMLImageElement> | null;
}

const DreamStormLogo = ({
  wrapperVariant,
  logoVariant,
  color,
  reference = null
}: DreamStormLogoProps) => {
  return (
    <LogoWrapper variant={wrapperVariant}>
      {GetLogoVariant(logoVariant, color, reference)}
    </LogoWrapper>
  );
};

export default DreamStormLogo;

const GetLogoVariant = (
  variant: string,
  color: string,
  reference: RefObject<HTMLImageElement> | null
) => {
  const logosSet = {
    sigil: { white: SigilWhite, black: SigilBlack, alt: "Dreamstorm Studios Sigil" },
    logotype: { white: LogotypeWhite, black: LogotypeBlack, alt: "Dreamstorm Studios Logotype" },
    logo: { white: LogoWhite, black: LogoBlack, alt: "Dreamstorm Studios Logo" },
    emblem: { white: EmblemWhite, black: EmblemBlack, alt: "Dreamstorm Studios Emblem" }
  };

  if (variant in logosSet) {
    return (
      <Logo
        loading="lazy"
        src={color === "black" ? logosSet[variant].black : logosSet[variant].white}
        alt={logosSet[variant].alt}
        ref={reference}
      />
    );
  } else {
    return <Logo loading="lazy" src={LogoWhite} alt="Dreamstorm Logo" ref={reference} />;
  }
};
