import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  Reducer,
  useContext,
  useReducer
} from "react";

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisplayElement } from "components/other/MenuWrapper/MenuWrapper";

/* ------------------------TYPES---------------------------- */
interface ContactFormContextData {
  displayForm: MutableRefObject<DisplayElement | null>;
}

type ContactFormContextType = [ContactFormContextData, Dispatch<AnyAction>];

const DefaultContactFormContextData: ContactFormContextData = {
  displayForm: null!
};

/* ------------------------REDUX------------------------ */
const ContactFormSlice = createSlice({
  name: "ContactForm",
  reducers: {
    setDisplayForm: (state, action: PayloadAction<MutableRefObject<DisplayElement | null>>) => {
      state.displayForm = action.payload;
    }
  },
  initialState: DefaultContactFormContextData
});
export const { setDisplayForm } = ContactFormSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const ContactContext = createContext<ContactFormContextType>([
  DefaultContactFormContextData,
  () => null
]);

export const ContactContextProvider: Component = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ContactFormContextData, AnyAction>>(
    ContactFormSlice.reducer,
    DefaultContactFormContextData
  );

  return <ContactContext.Provider value={[state, dispatch]}>{children}</ContactContext.Provider>;
};

export const useContactContext = (): ContactFormContextType => {
  return useContext(ContactContext);
};
