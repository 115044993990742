import { postPromise } from "network/base-promises";

const headers = {
  Accept: "application/json, text/plain, */*",
  "Content-type": "application/json"
};

export interface SendMessageResponse {
  msg: string;
  errorMessage: string;
  success: boolean;
}

export const sendMessage = async (payload: string): Promise<SendMessageResponse> => {
  const url = process.env.SERVER_URL
    ? process.env.SERVER_URL
    : "https://server-dev.dreamstormstudios.com";
  const path = `${url}/api/submit`;

  return postPromise<string, SendMessageResponse>(path, payload, headers);
};
