import React, { useEffect, useRef, useState } from "react";

import {
  ElementMainMenuChildren,
  LinkMenuImageArrow,
  MainMenuChildLink,
  MainMenuChildrenWrapper
} from "components/layout/Menu/MainMenu.styled";
import gsap, { Circ } from "gsap/gsap-core";
import Arrow from "media/images/other/arrow-menu.svg";
import {
  AnimatedChildMenuTextWrapper,
  AnimatedLinkMenuTextWrapper,
  AnimatedLinksAnimatedSpan
} from "styled/common/AnimatedLinks";
import Translate from "utils/translation/translation";

type MainMenuLink = {
  hideMainMenu: () => void;
  currentLocation: string;
  text: string;
  childrenPages: childrenPage[];
};

type childrenPage = {
  text: string;
  location: string;
};

const MainMenuChildrenLink = ({
  hideMainMenu,
  currentLocation,
  text,
  childrenPages
}: MainMenuLink) => {
  const [isMenuChildrenOpen, setIsMenuChildrenOpen] = useState(false);
  const wrapper = useRef(null);

  const handleChildLinkClick = () => {
    setIsMenuChildrenOpen(false);
    hideMainMenu();
  };

  const getFilteredChildrenLinks = (): JSX.Element[] => {
    const filteredPages = childrenPages
      .filter(page => page.location !== currentLocation)
      .map(page => {
        return (
          <MainMenuChildLink key={page.location} onClick={handleChildLinkClick} to={page.location}>
            <AnimatedChildMenuTextWrapper>
              <AnimatedLinksAnimatedSpan data-hover={Translate(page.text)}>
                {Translate(page.text)}
              </AnimatedLinksAnimatedSpan>
            </AnimatedChildMenuTextWrapper>
          </MainMenuChildLink>
        );
      });

    return filteredPages;
  };

  const handleMenuOpen = () => {
    setIsMenuChildrenOpen(!isMenuChildrenOpen);
  };

  const activateWrapper = () => {
    gsap.to(wrapper.current, {
      autoAlpha: 1,
      display: "block",
      ease: Circ.easeOut,
      duration: 0.5,
      stagger: {
        each: 0.5,
        from: "start"
      }
    });
  };

  const deactivateWrapper = () => {
    gsap.to(wrapper.current, {
      autoAlpha: 0,
      display: "none",
      ease: Circ.easeOut,
      duration: 0.5,
      stagger: {
        each: 0.5,
        from: "end"
      }
    });
  };

  useEffect(() => {
    if (isMenuChildrenOpen) {
      activateWrapper();
    } else {
      deactivateWrapper();
    }
  }, [isMenuChildrenOpen]);

  return (
    <>
      <ElementMainMenuChildren onClick={handleMenuOpen}>
        <AnimatedLinkMenuTextWrapper>
          <AnimatedLinksAnimatedSpan data-hover={Translate(text)}>
            {Translate(text)}
          </AnimatedLinksAnimatedSpan>
        </AnimatedLinkMenuTextWrapper>
        <LinkMenuImageArrow alt="arrow" src={Arrow} isTransformed={isMenuChildrenOpen} />
      </ElementMainMenuChildren>
      <MainMenuChildrenWrapper ref={wrapper}>{getFilteredChildrenLinks()}</MainMenuChildrenWrapper>
    </>
  );
};

export default MainMenuChildrenLink;
