import { FooterEmblemWrapper, FooterLogo } from "components/layout/footer/Footer/Footer.styled";
import { HeaderLogo, HeaderMobileSigil } from "components/layout/Header/Header.styled";
import { MainMenuLogo } from "components/layout/Menu/MainMenu.styled";
import { SocialSigil } from "components/layout/SidePanel/SidePanel.styled";
import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export type DreamstormLogoVariant =
  | "footer-logo"
  | "footer-emblem-wrapper"
  | "header-mobile-sigil"
  | "header-logo"
  | "social-sigil"
  | "main-menu-logo";

export const LogoWrapper = styled.div<{ variant: DreamstormLogoVariant }>`
  ${({ variant }) => variant === "footer-logo" && FooterLogo}
  ${({ variant }) => variant === "footer-emblem-wrapper" && FooterEmblemWrapper}
  ${({ variant }) => variant === "header-mobile-sigil" && HeaderMobileSigil}
  ${({ variant }) => variant === "header-logo" && HeaderLogo}
  ${({ variant }) => variant === "social-sigil" && SocialSigil}
  ${({ variant }) => variant === "main-menu-logo" && MainMenuLogo}
`;

export const Logo = styled.img`
  ${SizeFillSpaceMixin}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    width: 100%;
    height: 110%;
  }
  ${CalculateFromWidth("margin-left", { tablet: 3 })}
`;
