import React, { useRef } from "react";

import ContactForm from "components/common/contact/contact-form/ContactForm/ContactForm";
import BurgerMenu, { OpenMenuCallback } from "components/layout/BurgerMenu/BurgerMenu";
import ChatIcon from "components/layout/ChatIcon/ChatIcon";
import ContentScroll from "components/layout/ContentScroll";
import CookieNotification from "components/layout/CookieNotification/CookieNotification";
import { PageScrollContainer } from "components/layout/Layout/Layout.styled";
import LoadingScreen from "components/layout/LoadingScreen/LoadingScreen";
import MainMenu from "components/layout/Menu/MainMenu";
import SidePanel from "components/layout/SidePanel/SidePanel";
import RenderingStages from "components/rendering/RenderingStages/RenderingStages";
import LocomotiveProvider from "contexts/LocomotiveContext";
import GlobalStyles from "styled/base/Global";
import MiddleScroll from "utils/middle-scroll";

interface PageWrapperProps {
  location: Location;
}

export const PageWrapper: Component<PageWrapperProps> = ({ location, children }) => {
  const openMenuCallback = useRef<OpenMenuCallback["current"]>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <GlobalStyles />
      <PageScrollContainer ref={containerRef} data-scroll-container="">
        <LocomotiveProvider
          options={{
            smooth: true,
            smoothMobile: true,
            inertia: 0.95,
            direction: "vertical",
            repeat: false,
            tablet: { smooth: true },
            smartphone: { smooth: true }
          }}
          containerRef={containerRef}
          watch={[]}
        >
          <LoadingScreen />
          <RenderingStages />
          <MainMenu location={location} openMenuCallback={openMenuCallback} />
          <ContactForm location={location} />
          <SidePanel
            animateNavigation={location.pathname === "/"}
            iconsVisible={!location.pathname.includes("/blog/")}
          />
          <ChatIcon chatEnabled={!location.pathname.includes("/blog/")} />
          <CookieNotification />
          {children}
          <BurgerMenu openMenuCallback={openMenuCallback} inFooter={false} location={location} />
          <ContentScroll />
          <MiddleScroll />
        </LocomotiveProvider>
      </PageScrollContainer>
    </>
  );
};

export default PageWrapper;
