import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

export const Circle = css`
  position: absolute;
  ${SizeFillSpaceMixin}
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
`;
export const Button = css`
  cursor: pointer;
`;
export const EmptyCircle = css`
  ${Circle};
  z-index: 1;
  border-style: solid;
`;
export const FilledCircle = css`
  ${Circle};
  border-style: none;
`;
export const FilledCircleBackground = css`
  ${Circle};
  z-index: 2;
`;

export const FilledCircleForeground = css`
  ${Circle};
  z-index: 3;
`;

export const PaginationNumberButton = styled.button`
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  cursor: pointer;
  ${CalculateFromWidth("height", { mobile: 7 })}
  ${CalculateFromHeight("height", { tablet: 5, desktop: 6 })}
  ${CalculateFromWidth("width", { mobile: 7, tablet: 6, desktop: 4 })}
  ${CalculateFromWidth("margin-left", { mobile: 1.5, tablet: 2, desktop: 0.5 })}
  ${CalculateFromWidth("margin-right", { mobile: 1.5, tablet: 2, desktop: 0.5 })}
`;
