import React from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import StandardButton from "components/common/buttons/ButtonStandard/ButtonStandard";
import {
  ContactFormResultDescription,
  ContactFormResultTitle
} from "components/common/contact/contact-form/ContactFormSuccess/ContactFormSuccess.styled";
import Translate from "utils/translation/translation";

export interface ResultBodyProps {
  onClick: () => void;
}

const SuccessBody = ({ onClick }: ResultBodyProps) => {
  return (
    <>
      <ContactFormResultTitle>{Translate("Contact_Form_Result_ThankYou")}</ContactFormResultTitle>
      <ContactFormResultDescription>
        {Translate("Contact_Form_Result_ThankYouMessage")}
      </ContactFormResultDescription>
      <TrackedButton category="form" action="form-result-success-return">
        <StandardButton
          text="Contact_Form_Result_Return"
          variant="contact-form-result-button-wrapper"
          onClick={onClick}
        />
      </TrackedButton>
    </>
  );
};

export default SuccessBody;
