import React, { useRef } from "react";

import { Sprite } from "@inlet/react-pixi";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import gsap from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import * as PIXI from "pixi.js";
import type { ThunderData } from "providers/CloudThunderDataProvider";
import { LoadImageData } from "providers/RenderElementsProvider";

interface ThunderLayerProps {
  parentRect: DOMRect;
  imageData: IGatsbyImageData;
  thunderData?: ThunderData;
}

export const ThunderLayer = ({ parentRect, imageData, thunderData }: ThunderLayerProps) => {
  const thunderSprite = useRef<PIXI.Sprite>(null);

  //Fake warning here - adding parentRect to array causes infinite rerender
  /* eslint-disable */
  useFirstRenderEffect(() => {
    LoadImageData(
      imageData,
      parentRect,
      newTexture => {
        if (typeof thunderSprite.current === "undefined" || thunderSprite.current === null) {
          return;
        }
        thunderSprite.current.texture = newTexture;
      },
      0.25
    );
    if (thunderSprite.current) {
      AddGsapAnimation(thunderSprite.current);
    }
  }, [imageData, thunderSprite.current]);
  /* eslint-enable */

  if (!thunderData) {
    return (
      <Sprite
        ref={thunderSprite}
        image={imageData.placeholder!.fallback}
        anchor={0.5}
        blendMode={PIXI.BLEND_MODES.ADD}
        alpha={0}
      />
    );
  }

  return (
    <Sprite
      ref={thunderSprite}
      image={imageData.placeholder!.fallback}
      anchor={0.5}
      rotation={thunderData.rotation ?? 0}
      x={parentRect.width * (-0.5 + thunderData.x / 100)}
      y={parentRect.height * (-0.5 + thunderData.y / 100)}
      width={(parentRect.width * thunderData.size) / 100}
      height={(parentRect.height * thunderData.size) / 100}
      blendMode={PIXI.BLEND_MODES.ADD}
      alpha={0}
    />
  );
};

export default ThunderLayer;

const AddGsapAnimation = (sprite: PIXI.Sprite) => {
  const startDelay = Random(1, 4);
  const delay1 = Random(3, 15);
  const delay2 = Random(5, 20);
  const doubleShotDelay = Random(0.03, 0.07);
  const doubleShortDuration = Random(0.04, 0.09);
  const animationDuration = Random(0.09, 0.14);
  const targetAlpha = Random(0.4, 0.8);
  const timeline = gsap.timeline({ repeat: 9999 });

  // ----------------- First shot -----------------
  timeline.to(sprite, {
    pixi: { alpha: targetAlpha },
    duration: animationDuration,
    delay: startDelay
  });
  timeline.to(sprite, { pixi: { alpha: 0 }, duration: animationDuration });
  // ----------------- Double thunder shot -----------------
  timeline.to(sprite, {
    pixi: { alpha: targetAlpha * 0.5 },
    duration: doubleShortDuration,
    delay: delay1
  });
  timeline.to(sprite, { pixi: { alpha: 0 }, duration: doubleShortDuration });
  timeline.to(sprite, {
    pixi: { alpha: targetAlpha },
    duration: doubleShortDuration,
    delay: doubleShotDelay
  });
  timeline.to(sprite, { pixi: { alpha: 0 }, duration: doubleShortDuration });
  // ----------------- Second shot -----------------
  timeline.to(sprite, {
    pixi: { alpha: targetAlpha * 0.8 },
    duration: animationDuration,
    delay: delay2
  });
  timeline.to(sprite, { pixi: { alpha: 0 }, duration: animationDuration });

  timeline.play();
};

const Random = (min: number, max: number) => {
  return Math.min(Math.random() * (max - min)) + min;
};
