import { useEffect, useRef } from "react";

import { useLocomotiveScroll } from "contexts/LocomotiveContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { Smooth } from "locomotive-scroll";
import { getWindowSize } from "utils/window-utils";

type ScrollPosType = {
  x: number;
  y: number;
};

const MiddleScroll = () => {
  const locomotiveScroll = useLocomotiveScroll();
  const locomotiveScrollRef = useRef(locomotiveScroll);
  const maxMiddleScrollSpeed = 100;
  const middleClickDistanceThreshold = 20;
  const startScrollPosition = useRef<ScrollPosType | null>({ x: 0, y: 0 });
  const currentScrollPosition = useRef<ScrollPosType | null>({ x: 0, y: 0 });
  const hasStartedScrolling = useRef(false);
  const hasPassedThreshold = useRef<boolean>(false);
  const deltaScroll = useRef(0);

  useEffect(() => {
    locomotiveScrollRef.current = locomotiveScroll;
  });

  useFirstRenderEffect(() => {
    window.onmousedown = MiddleClickScrollDown;
  });

  const ClickedInteractable = eventArgs => {
    return (
      typeof eventArgs.path !== "undefined" &&
      eventArgs.path.some(item => {
        return item.nodeName === "A" || item.nodeName === "BUTTON";
      })
    );
  };

  const Distance = (startPos, endPos) => {
    return endPos.y - startPos.y;
  };

  const MiddleClickScrollDown = eventArgs => {
    if (hasStartedScrolling.current) {
      hasStartedScrolling.current = false;
      hasPassedThreshold.current = false;
      window.onmousemove = null;
      window.onmouseup = null;
    } else {
      if (eventArgs.button !== 1) {
        return;
      }

      if (ClickedInteractable(eventArgs)) {
        return;
      }

      hasStartedScrolling.current = true;
      startScrollPosition.current = { x: eventArgs.screenX, y: eventArgs.screenY };
      currentScrollPosition.current = startScrollPosition.current;
      locomotiveScrollRef.current.scroll?.stop();
      locomotiveScrollRef.current.scroll?.start();
      window.onmousemove = MiddleClickScrollMove;
      window.onmouseup = MiddleClickScrollUp;
      window.requestAnimationFrame(AnimateMiddleScroll);
    }
  };

  const MiddleClickScrollMove = eventArgs => {
    if (!hasStartedScrolling.current) {
      return;
    }

    currentScrollPosition.current = { x: eventArgs.screenX, y: eventArgs.screenY };

    const distance = Math.abs(Distance(startScrollPosition.current, currentScrollPosition.current));

    hasPassedThreshold.current =
      hasPassedThreshold.current || distance > middleClickDistanceThreshold;
  };

  const AnimateMiddleScroll = () => {
    if (hasStartedScrolling.current) {
      if (hasPassedThreshold.current) {
        deltaScroll.current =
          (Distance(startScrollPosition.current, currentScrollPosition.current) /
            getWindowSize().height) *
          maxMiddleScrollSpeed;

        const locomotive = locomotiveScrollRef.current.scroll!.scroll;

        locomotive.instance.delta.y += deltaScroll.current;

        if (locomotive.instance.delta.y < 0) {
          locomotive.instance.delta.y = 0;
        }

        if (locomotive.instance.delta.y > locomotive.instance.limit.y) {
          locomotive.instance.delta.y = locomotive.instance.limit.y;
        }

        const smoothScroll = locomotiveScrollRef.current.scroll as unknown as Smooth;

        if (smoothScroll) {
          smoothScroll.isScrolling = true;
          smoothScroll.animatingScroll = true;
          locomotive.checkScroll(true);
        }
      }

      window.requestAnimationFrame(AnimateMiddleScroll);
    } else {
      locomotiveScrollRef.current.scroll?.scrollTo(
        locomotiveScrollRef.current.scroll.scroll.instance.scroll.y + deltaScroll.current * 4,
        {
          offset: 0,
          duration: 200,
          easing: [0, 1, 0.1, 1],
          disableLerp: false
        }
      );
    }
  };

  const MiddleClickScrollUp = eventArgs => {
    if (eventArgs.button !== 1) {
      return;
    }

    if (hasPassedThreshold.current) {
      if (hasStartedScrolling.current) {
        hasStartedScrolling.current = false;
        hasPassedThreshold.current = false;
        window.onmousemove = null;
        window.onmouseup = null;
      }
    } else {
      hasStartedScrolling.current = true;
      startScrollPosition.current = { x: eventArgs.clientX, y: eventArgs.clientY };
      currentScrollPosition.current = startScrollPosition.current;
    }
  };

  return null;
};

export default MiddleScroll;
