import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  AnimatedOpacity,
  AnimatedTransformOpacityMixin,
  Background,
  CenterFlex,
  FillBackgroundSpaceMixin,
  SizeFillSpaceMixin
} from "styled/common/Utils";
import styled, { css } from "styled-components";

export type RendererWrapperVariant =
  | "fill-background-space"
  | "big-project-cloud-wrapper"
  | "big-project-cloud-wrapper-inversed"
  | "contact-form"
  | "main-menu"
  | "home-project-cloud-wrapper"
  | "project-image-wrapper";

const MainMenuCloudWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    opacity: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    top: 2.5%;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    top: 2.5%;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${CalculateFromWidth("height", { mobile: 100, tablet: 100, desktop: 90 })}
  ${CalculateFromWidth("width", { mobile: 100, tablet: 100, desktop: 90 })}
  position: absolute;
  left: 0;
  right: 0;
  ${Background}
`;

const ContactFormCloudWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    top: 40%;
    transform: translateY(-50%);
    margin: auto;
    opacity: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    top: 2.5%;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    top: 2.5%;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${CalculateFromWidth("height", { mobile: 100, tablet: 100, desktop: 90 })}
  ${CalculateFromWidth("width", { mobile: 100, tablet: 100, desktop: 90 })}
  position: absolute;
  left: 0;
  right: 0;
  ${Background}
`;

const BigProjectCloudWrapper = css`
  ${AnimatedTransformOpacityMixin}
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-left: auto;
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-left: auto;
  }
  ${CalculateFromWidth("width", { mobile: 90 })}
  ${CalculateFromHeight("width", { tablet: 90 * 0.8, desktop: 90 * 0.8 })}
  ${CalculateFromHeight("height", { mobile: 105, tablet: 90 * 0.8, desktop: 90 * 0.8 })}
  ${CalculateFromHeight("margin-top", { desktop: 15 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 8, desktop: 7.5 })}
  ${CalculateFromWidth("margin-left", { mobile: 5 })}
  ${CenterFlex}
  margin-bottom: auto;
`;

const BigProjectCloudWrapperInversed = css`
  ${AnimatedOpacity}
  ${CenterFlex}
  margin-top: auto;
  margin-bottom: auto;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-right: auto;
  }
  ${CalculateFromWidth("width", { mobile: 90 })}
  ${CalculateFromHeight("width", { tablet: 90 * 0.8, desktop: 90 * 0.8 })}
  ${CalculateFromHeight("height", { mobile: 105, tablet: 90 * 0.8, desktop: 90 * 0.8 })}
  ${CalculateFromWidth("margin-right", { mobile: 5, tablet: 8 })}
  ${CalculateFromWidth("margin-left", { mobile: 5 })}
`;

const HomeProjectCloudWrapper = css`
  ${CalculateFromWidth("margin-right", { desktop: 12.5 })}
  ${SizeFillSpaceMixin}
  ${Background}
  ${CenterFlex}
  ${AnimatedTransformOpacityMixin}
`;

const ProjectImageWrapper = css`
  position: absolute;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    width: 55%;
    height: 55%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    width: 60%;
    height: 60%;
  }
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  ${AnimatedTransformOpacityMixin}
`;

export const RendererContainer = styled.div<{ variant: RendererWrapperVariant }>`
  ${({ variant }) => variant === "fill-background-space" && FillBackgroundSpaceMixin}
  ${({ variant }) => variant === "big-project-cloud-wrapper" && BigProjectCloudWrapper}
  ${({ variant }) =>
    variant === "big-project-cloud-wrapper-inversed" && BigProjectCloudWrapperInversed}
  ${({ variant }) => variant === "contact-form" && ContactFormCloudWrapper}
  ${({ variant }) => variant === "main-menu" && MainMenuCloudWrapper}
  ${({ variant }) => variant === "home-project-cloud-wrapper" && HomeProjectCloudWrapper}
  ${({ variant }) => variant === "project-image-wrapper" && ProjectImageWrapper}
`;

export const RendererElementsWrapper = styled.div`
  ${FillBackgroundSpaceMixin}
  ${CenterFlex}
`;

export const RendererBackground = styled.div`
  ${FillBackgroundSpaceMixin}
  position: absolute;
`;
