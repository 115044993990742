import { CalculateFontSize } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const CenterFlex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Preserve3dMixin = css`
  transform-style: preserve-3d;
`;

export const AnimatedTransform = css`
  will-change: transform;
`;

export const NonSelectable = css`
  user-select: none;
  pointer-events: none;
`;

export const TextWhite = css`
  color: ${({ theme: { colors } }) => colors.white};
  ${({ theme: { fonts } }) => fonts.regular}
  font-weight: 500;
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1.2, desktop: 1.2 })}
`;

export const TextBlack = css`
  color: ${({ theme: { colors } }) => colors.black};
  ${({ theme: { fonts } }) => fonts.regular};
  font-weight: 600;
  ${CalculateFontSize("font-size", { mobile: 1.8, tablet: 1.8, desktop: 1.47 })}
`;

export const SizeFillSpaceMixin = css`
  width: 100%;
  height: 100%;
`;

export const FillSpaceMixin = css`
  ${SizeFillSpaceMixin}
  object-fit: contain;
  position: relative;
`;

export const FillBackgroundSpaceMixin = css`
  ${SizeFillSpaceMixin}
  object-fit: contain;
  position: relative;
  pointer-events: none;
`;

export const InvertColors = css`
  filter: invert(1);
  backface-visibility: hidden;
  transform: translateZ(0px);
`;

export const AbsoluteHidden = css`
  position: absolute !important;
  overflow: hidden !important;
`;

export const DisableSelection = css`
  user-select: none;
`;

export const AnimatedWrapper = css`
  will-change: height, width, opacity, clip-path;
`;

export const AnimatedBackground = css`
  will-change: height, width, opacity;
`;

export const AnimatedMask = css`
  will-change: opacity, top, left, height, width;
`;

export const AnimatedTransformOpacityMixin = css`
  will-change: transform, opacity;
`;

export const AnimatedOpacity = css`
  will-change: opacity;
`;

export const StaticElement = css`
  position: absolute;
  left: 0;
  top: 0;
`;

export const Background = css`
  z-index: -99;
`;
