import { SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const ContactFormInternal = styled.div`
  ${SizeFillSpaceMixin}
  top: 0;
  left: 0;
  position: relative;
  margin: 0 auto auto auto;
  transition: transform 0.1s linear, grid-template-rows 0.1s linear;
`;
