import "styles/styles.css";

import * as React from "react";
import { Helmet } from "react-helmet";

import { LocationProvider } from "@reach/router";
import { BlackSidePanelContextProvider } from "contexts/BlackSidePanelContext";
import { BlogContextProvider } from "contexts/BlogContext";
import { ContactContextProvider } from "contexts/ContactFormContext";
import { ImageDatabaseContextProvider } from "contexts/ImageContext";
import { RenderContextProvider } from "contexts/RenderContext";
import ScrollContextProvider from "contexts/ScrollContext";
import SeoData from "data/seo-data.json";
import ThemeProvider from "theme/ThemeProvider";

export const RootElementWrapper: Component = ({ children }) => {
  return (
    <LocationProvider>
      <ImageDatabaseContextProvider>
        <RenderContextProvider>
          <ScrollContextProvider>
            <ContactContextProvider>
              <BlackSidePanelContextProvider>
                <BlogContextProvider>
                  <ThemeProvider>
                    <Helmet htmlAttributes={{ translate: "no" }} defer={false}>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=6, minimum-scale=1, viewport-fit=cover"
                      />
                      <script type="application/ld+json">
                        {`` + JSON.stringify(SeoData.JSONLD)}
                      </script>
                      <style id="scrollLock" type="text/css">
                        {
                          "html, body, #___gatsby, #gatsby-focus-wrapper, #scroll-container, #scroll-container{margin: 0 !important; height: 100% !important; overflow: hidden !important}"
                        }
                      </style>
                    </Helmet>
                    {children}
                  </ThemeProvider>
                </BlogContextProvider>
              </BlackSidePanelContextProvider>
            </ContactContextProvider>
          </ScrollContextProvider>
        </RenderContextProvider>
      </ImageDatabaseContextProvider>
    </LocationProvider>
  );
};

export default RootElementWrapper;
