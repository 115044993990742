import { Link } from "gatsby";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { FooterCopyrightFont } from "styled/common/font-styles/FooterFonts";
import { GridFooter } from "styled/common/Grid";
import {
  AnimatedTransform,
  InvertColors,
  Preserve3dMixin,
  SizeFillSpaceMixin
} from "styled/common/Utils";
import styled, { css } from "styled-components";
import { desktopSizes } from "theme/theme";

const footerWidth = 100;
const footerColumnCount = 12;
const emblemSize = 7.5;

const SecondFooterRow = css`
  grid-row: 6 / span 2;
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })}
`;

export const FooterWrapper = styled.footer`
  ${GridFooter};
  position: relative;
  transform: translateZ(10px);
  pointer-events: none;
  ${Preserve3dMixin}
`;

export const FooterContentWrapper = styled.div`
  ${CalculateFromHeight("height", { mobile: 200, tablet: 100, desktop: 100 })}
  ${AnimatedTransform}
  background-color: ${({ theme: { colors } }) => colors.footer};
  width: 100vw;
  bottom: 0;
  pointer-events: none;
`;

export const FooterContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${footerColumnCount}, 1fr);
  grid-template-rows: repeat(8, 1fr);
  color: black;
  ${CalculateFromWidth("width", {
    mobile: footerWidth * 0.9,
    tablet: footerWidth * 0.9,
    desktop: footerWidth * 0.9
  })}

  @media screen and (min-width: ${desktopSizes.minWidth}px) and (max-width: ${desktopSizes.maxWidth +
  100}px) {
    ${CalculateFromWidth("width", { desktop: 84 })}
    ${CalculateFromWidth("margin-right", { desktop: 8 })}
    ${CalculateFromWidth("margin-left", { desktop: 8 })}
  }
`;

export const FooterLetsTalk = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 3 / span 3;
    grid-column: 1 / span 6;
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 2 / span 2;
    grid-column: 1 / span 6;
    margin-top: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 8;
  }
  ${CalculateFromHeight("margin-top", { mobile: 10 })}
  ${CalculateFromWidth("letter-spacing", { mobile: 0.2, tablet: 0.2 })}
  ${CalculateFromWidth("margin-left", { mobile: -0.5, tablet: -0.5, desktop: -1.5 })}
  .h1-talk {
    ${CalculateFromWidth("margin-left", { mobile: 10, tablet: 10, desktop: 8.5 })}
  }
  word-break: keep-all;
  white-space: nowrap;
`;

export const FooterEmblemWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    margin-bottom: 70px !important;
    ${SecondFooterRow}
    align-self: end;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
  ${CalculateFromWidth("height", { desktop: emblemSize })}
  ${CalculateFromWidth("width", { desktop: emblemSize })}
`;

export const FooterBorderBottom = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 3;
    grid-row: 5 / span 1;
    border-bottom: solid ${({ theme: { colors } }) => colors.black} 1px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    height: 1px;
    grid-column: 1 / span 4;
    grid-row: 6 / span 1;
    border-bottom: solid ${({ theme: { colors } }) => colors.black} 1px;
  }
  ${CalculateFromHeight("margin-top", { mobile: 3 })}
`;

export const FooterContactInfoWrapper = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 8 / span 4;
    grid-row: 4 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 5 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 4;
    grid-row: 5 / span 1;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${CalculateFromWidth("margin-left", { tablet: 1, desktop: 3 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 4 })}
`;

export const FooterLogoLink = styled(Link)`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    @media screen and (min-height: ${desktopSizes.maxHeight}px) {
      display: none;
    }
  }
  ${CalculateFromHeight("padding-top", { desktop: 0.15 })}
  ${CalculateFromWidth("padding-left", { tablet: 1 })}
  ${CalculateFromWidth("margin-left", { tablet: 6, desktop: 4.2 })}
  ${CalculateFromHeight("margin-top", { tablet: 4.75, desktop: -0.8 })}
  ${CalculateFromWidth("width", { tablet: 30, desktop: 14.3 })}
  ${CalculateFromHeight("height", { tablet: 5, desktop: 3.125 })}
  ${FooterCopyrightFont};
  position: fixed;
`;

export const FooterLogo = css`
  ${AnimatedTransform}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    overflow: none;
  }
  ${CalculateFromWidth("margin-left", { desktop: -0.05 })}
  ${CalculateFromHeight("margin-top", { mobile: 0.05, tablet: 0.05, desktop: 0.05 })}
  ${CalculateFromWidth("width", { mobile: 13, tablet: 13, desktop: 13 })}
  ${CalculateFromHeight("height", { mobile: 2.3, tablet: 2.3, desktop: 2.3 })}
  z-index: 5;
  position: relative;

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    padding-left: 1rem;
  }

  & > img {
    width: auto;
    height: auto;
  }
`;

export const FooterLogoWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    cursor: pointer;
  }

  ${CalculateFromWidth("width", { tablet: 25 })}
  ${CalculateFromHeight("margin-top", { desktop: 0.7 })}
  ${CalculateFromWidth("border-left-width", { mobile: 0.1, tablet: 0.1, desktop: 0.1 })}
  ${CalculateFromHeight("padding-top", { mobile: -0.15, tablet: -0.15, desktop: -0.15 })}
  ${CalculateFromWidth("margin-left", { tablet: 4 })}
  ${CalculateFromHeight("height", { tablet: 4, desktop: 3.125 })}
  @media screen and (min-height: 300px) and (max-height: 882px) {
    display: none;
    pointer-events: none;
  }

  border-left: white solid;
  overflow: hidden;
  position: absolute;
  z-index: 5;
`;

export const FooterSidePanelWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  ${SizeFillSpaceMixin}
  bottom: 0;
  left: 0;
  opacity: 1;
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    //Remove this for visible sigil
    display: none;
  }
  pointer-events: none !important;
  ${InvertColors}
`;

export const FooterSidePanel = styled.div`
  ${CalculateFromHeight("margin-top", { desktop: 5 })}
  ${CalculateFromHeight("margin-bottom", { desktop: -5 })}
  ${CalculateFromWidth("left", { tablet: 1.5, desktop: 2.2 })}
  ${CalculateFromWidth("width", { mobile: 4, tablet: 4, desktop: 4 })}
  position: absolute;
  height: calc(var(--vh, 1vh) * 100);
  bottom: 0;
`;
