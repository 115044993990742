import React, { useRef } from "react";
import { isMobile } from "react-device-detect";

import RenderBackgroundStage from "components/rendering/RenderingStages/RenderBackgroundStage";
import RenderForegroundStage from "components/rendering/RenderingStages/RenderForegroundStage";
import { BackgroundStageWrapper } from "components/rendering/RenderingStages/RenderingStages.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { clearRenderElements, IsDeviceRenderReady, useRenderContext } from "contexts/RenderContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { SetupPixi, SetupStage } from "utils/render-utilities";
import { getWindowSize } from "utils/window-utils";

export const RenderingStages = () => {
  const [{ canUseRenderer, renderElements }, dispatchRender] = useRenderContext();
  const stage = useRef(null);
  const [imageDatabase] = useImageDatabaseContext();
  const foregroundStage = useRef<HTMLCanvasElement>(null);
  const windowSize = useRef(getWindowSize());

  if (canUseRenderer && IsDeviceRenderReady()) {
    SetupPixi();
  }

  const windowResizeCallback = () => {
    windowSize.current = getWindowSize();
  };

  useFirstRenderEffect(() => {
    if (!canUseRenderer || !IsDeviceRenderReady()) {
      return;
    }

    SetupStage(stage, foregroundStage, windowSize);
    window.addEventListener("resize", windowResizeCallback);

    return () => {
      dispatchRender(clearRenderElements());
      window.removeEventListener("resize", windowResizeCallback);
    };
  }, [isMobile, canUseRenderer]);

  return (
    <>
      <BackgroundStageWrapper>
        {IsDeviceRenderReady() && canUseRenderer && (
          <RenderBackgroundStage
            renderElements={renderElements}
            stage={stage}
            imageDatabase={imageDatabase}
            windowSize={windowSize}
          />
        )}
      </BackgroundStageWrapper>
      {IsDeviceRenderReady() && canUseRenderer && (
        <RenderForegroundStage foregroundStage={foregroundStage} windowSize={windowSize} />
      )}
    </>
  );
};

export default RenderingStages;
