import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { SizeFillSpaceMixin, StaticElement } from "styled/common/Utils";
import styled from "styled-components";

export const ContactFormResultWrapper = styled.div`
  display: grid;
  ${StaticElement}
  margin-bottom: auto;
  margin-right: auto;
  ${SizeFillSpaceMixin}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }
  ${CalculateFromWidth("margin-left", { mobile: 10, tablet: 10, desktop: 10 })}
  ${CalculateFromWidth("margin-top", { mobile: 10, tablet: 10, desktop: 10 })}
`;
