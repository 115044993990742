import React, { useRef } from "react";

import {
  LoadingScreenWrapper,
  LogoAnimationWrapper
} from "components/layout/LoadingScreen/LoadingScreen.styled";
import AnimationData from "data/loader-animation.json";
import gsap from "gsap/gsap-core";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

export const LoadingScreen = () => {
  const lottieAnimation = useRef<LottieRefCurrentProps>(null);
  const screen = useRef<HTMLDivElement>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    initialSegment: [0, 35] as [number, number],
    onLoopComplete: () => AnimationComplete()
  };

  const AnimationComplete = () => {
    if (IsDocumentReady()) {
      gsap.to(screen.current, {
        delay: 0,
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          gsap.set(screen.current, { display: "none", opacity: 0, pointerEvents: "none" });
        }
      });
      lottieAnimation.current?.goToAndStop(55, true);
    } else {
      lottieAnimation.current?.playSegments([0, 107]);
    }
  };

  const IsDocumentReady = () => {
    return document.readyState === "complete" || document.readyState === "interactive";
  };

  return (
    <LoadingScreenWrapper ref={screen} style={{ opacity: 1 }}>
      <LogoAnimationWrapper>
        <Lottie lottieRef={lottieAnimation} {...defaultOptions} />
      </LogoAnimationWrapper>
    </LoadingScreenWrapper>
  );
};

export default LoadingScreen;
