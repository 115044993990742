import React, { Ref, useCallback, useState } from "react";

import { Graphics, useTick } from "@inlet/react-pixi";
import type { Graphics as GraphicsType } from "pixi.js";

interface MaskLayerProps {
  parent: HTMLDivElement;
  maskRef: Ref<GraphicsType>;
}

export const MaskLayer = ({ parent, maskRef }: MaskLayerProps) => {
  const [rotation, setRotation] = useState(0);

  useTick(delta => {
    setRotation(rotation + delta);
  });

  //Fake warning here - this callback needs the second argument to properly refresh
  /* eslint-disable */
  const circleDrawing = useCallback(
    graphics => {
      let parentRect = parent.getBoundingClientRect();
      graphics.clear();
      graphics.lineStyle(0);
      graphics.beginFill(0xffffff, 1);
      graphics.drawCircle(parentRect.left, parentRect.top, (parentRect.width / 4) * 3);
      graphics.endFill();
    },
    [parent, parent.getBoundingClientRect()]
  );
  /* eslint-enable */

  return <Graphics ref={maskRef} draw={circleDrawing} />;
};

export default MaskLayer;
