import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonLink } from "styled/common/font-styles/CommonFonts";
import {
  ContactFormMailAddressFont,
  ContactFormResultDescriptionFont,
  ContactFormResultTitleFont
} from "styled/common/font-styles/ContactFormFonts";
import styled from "styled-components";

export const ContactFormResultTitleError = styled.h4`
  ${ContactFormResultTitleFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 3;
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 1 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 4;
    grid-row: 3 / span 1;
  }
  ${CalculateFromWidth("margin-left", { desktop: 3 })}
  ${CalculateFromHeight("margin-top", { tablet: 10, desktop: 3 })}
`;

export const ContactFormResultDescriptionError = styled.p`
  ${ContactFormResultDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 3;
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 2 / span 1;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 3;
    grid-row: 4 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: 5, desktop: 3 })}
  ${CalculateFromWidth("margin-right", { mobile: 7, tablet: 11, desktop: 9 })}
  ${CalculateFromWidth("margin-left", { desktop: 3 })}
`;

export const ContactFormMailAdress = styled.a`
  ${CommonLink}
  ${ContactFormMailAddressFont}
  ${CalculateFromWidth("border-bottom-width", { mobile: 0.05, tablet: 0.05, desktop: 0.05 })}
  border-bottom-style: solid;
  border-bottom-color: #ffffff70;
`;
