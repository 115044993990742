import { RefObject } from "react";
import { isMobile } from "react-device-detect";

import gsap, { Circ } from "gsap";

export type ElementRect = DOMRect & { center: { x: number; y: number } };

export const GetToggleRect = <T extends HTMLElement>(element: T): ElementRect => {
  const rect = JSON.parse(JSON.stringify(element.getBoundingClientRect()));

  rect.center = { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };

  return rect;
};

export const GetForegroundAnimation = <T extends HTMLElement>(
  foregroundWrapper: RefObject<T>,
  renderingMaskWrapper: RefObject<T>,
  background: RefObject<T>,
  rect: ElementRect,
  animationTime: number,
  reversed = false,
  canUseRenderer = true
) => {
  const animation = gsap.timeline();

  const startingClipPath = {
    clipPath: "circle(0% at " + rect.center.x + "px " + rect.center.y + "px",
    duration: animationTime
  };
  const targetClipPath = {
    clipPath: "circle(200vw at " + rect.center.x + "px " + rect.center.y + "px",
    duration: animationTime,
    delay: animationTime * 0.0125
  };
  const startingOpacity = { opacity: 0, duration: 0.25, ease: Circ.easeOut };
  const targetOpacity = { opacity: 1, duration: 0.25, ease: Circ.easeOut };
  const startingMaskPos = {
    width: 0,
    height: 0,
    left: rect.center.x,
    top: rect.center.y,
    duration: 0
  };
  const targetMaskPos = { width: "267vw", height: "267vw", duration: animationTime };

  if (reversed) {
    animation.call(
      () => {
        gsap.set(background.current, { clearProps: "all" });
        gsap.set(foregroundWrapper.current, { clearProps: "all" });
        gsap.set(background.current, { pointerEvents: "none", width: "0px", height: "0px" });
        gsap.set(foregroundWrapper.current, { pointerEvents: "none", width: "0px", height: "0px" });
      },
      undefined,
      0
    );
    animation.eventCallback("onInterrupt", () => {
      gsap.set(background.current, { clearProps: "all" });
      gsap.set(foregroundWrapper.current, { clearProps: "all" });
      gsap.set(background.current, { pointerEvents: "none", width: "0px", height: "0px" });
      gsap.set(foregroundWrapper.current, { pointerEvents: "none", width: "0px", height: "0px" });
    });
  } else {
    gsap.set(background.current, { pointerEvents: "all" });
    gsap.set(foregroundWrapper.current, { pointerEvents: "all" });
    animation.fromTo(
      background.current,
      {},
      { width: "100vw", height: "100vh", pointerEvents: "all", duration: 0 },
      animationTime + 0.1
    );
    animation.fromTo(
      foregroundWrapper.current,
      {},
      { width: "100vw", height: "100vh", pointerEvents: "all", duration: 0 },
      animationTime + 0.1
    );
  }

  if (!isMobile && canUseRenderer) {
    animation.fromTo(".masked-stage", startingClipPath, targetClipPath, 0.1);
    animation.fromTo(".masked-stage", startingOpacity, targetOpacity, 0.1);
    animation.fromTo(renderingMaskWrapper.current, startingMaskPos, targetMaskPos, 0.1);
    animation.fromTo(renderingMaskWrapper.current, startingOpacity, targetOpacity, 0.1);
  }

  animation.fromTo(foregroundWrapper.current, startingClipPath, targetClipPath, 0.1);
  animation.fromTo(foregroundWrapper.current, startingOpacity, targetOpacity, 0.1);
  animation.fromTo(
    background.current,
    { opacity: 0, duration: animationTime / 3 },
    { opacity: 1, duration: animationTime / 3 },
    0.1
  );

  return animation;
};

export const GetTransitionAnimation = <T extends HTMLElement>(
  foregroundWrapper: RefObject<T>,
  renderingMaskWrapper: RefObject<T>,
  background: RefObject<T>,
  cloudWrapper: RefObject<T>,
  animationTime: number,
  reversed?: boolean
) => {
  const animation = gsap.timeline();

  const startingOpacity = { clipPath: "circle(0% at 0% 0%)", opacity: 0, duration: animationTime };
  const targetOpacity = { clipPath: "none", opacity: 1, duration: animationTime };
  const startingBackground = { opacity: 0, duration: animationTime };
  const targetBackground = { opacity: 1, duration: animationTime };
  const startingMaskSettings = {
    width: "267vw",
    height: "267vw",
    left: 0,
    top: 0,
    clipPath: "circle(0% at 0% 0%)",
    opacity: 0,
    duration: animationTime
  };
  const targetMaskSettings = {
    width: "267vw",
    height: "267vw",
    clipPath: "none",
    opacity: 1,
    duration: animationTime
  };

  if (reversed) {
    animation.call(
      () => {
        gsap.set(background.current, { clearProps: "all" });
        gsap.set(foregroundWrapper.current, { clearProps: "all" });
        gsap.set(background.current, { pointerEvents: "none", width: "0px", height: "0px" });
        gsap.set(foregroundWrapper.current, { pointerEvents: "none", width: "0px", height: "0px" });
      },
      undefined,
      0
    );
    animation.eventCallback("onInterrupt", () => {
      gsap.set(background.current, { clearProps: "all" });
      gsap.set(foregroundWrapper.current, { clearProps: "all" });
      gsap.set(background.current, { pointerEvents: "none", width: "0px", height: "0px" });
      gsap.set(foregroundWrapper.current, { pointerEvents: "none", width: "0px", height: "0px" });
    });
    gsap.set(background.current, { pointerEvents: "none" });
    gsap.set(foregroundWrapper.current, { pointerEvents: "none" });
  } else {
    gsap.set(background.current, { pointerEvents: "all" });
    gsap.set(foregroundWrapper.current, { pointerEvents: "all" });
    animation.fromTo(
      background.current,
      {},
      { width: "100vw", height: "100vh", pointerEvents: "all", duration: 0 },
      animationTime + 0.1
    );
    animation.fromTo(
      foregroundWrapper.current,
      {},
      { width: "100vw", height: "100vh", pointerEvents: "all", duration: 0 },
      animationTime + 0.1
    );
  }

  if (!isMobile) {
    animation.fromTo(renderingMaskWrapper.current, startingMaskSettings, targetMaskSettings, 0.1);
  }

  animation.fromTo(foregroundWrapper.current, startingOpacity, targetOpacity, 0.1);
  animation.fromTo(cloudWrapper.current, startingOpacity, targetOpacity, 0.1);
  animation.fromTo(background.current, startingBackground, targetBackground, 0.1);

  return animation;
};
