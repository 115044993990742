module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141829617-1","siteSpeedSampleRate":10,"cookieDomain":"dreamstormstudios.com","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5P4CDL6","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/media/images/icons/favicon.png","name":"DreamStorm Studios","short_name":"DreamStorm Studios","start_url":"/","display":"standalone","background_color":"#000000","theme_color":"#000000","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"285720c8b254538989c7ec4d8ebb42fb"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1400,"quality":95,"wrapperStyle":"padding-bottom: 0.5rem; border-radius: 10px; margin-top: 5vh; margin-bottom: 5vh; overflow: hidden;","loading":"lazy","withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"description":"Games, Apps, VR and AR. Brought together by passion.","canonical":"https://www.dreamstormstudios.com","language":"en","metaTags":[{"property":"og:type","content":"website"},{"property":"keywords","content":"unity,game,game development,vr,ar,xr,mr,unity development,\n            unity developers,games,natural instincts,dream,storm,dreamstorm,\n            dreamstorm studios,studios,startup,gamedev,gamedev studio,studio"}],"openGraph":{"type":"website","locale":"en_GB","url":"https://dreamstormstudios.com","site_name":"DreamStorm Studios","description":"Games, Apps, VR and AR. Brought together by passion."},"twitter":{"handle":"@TheDreamStorm","site":"@TheDreamStorm","cardType":"summary_large_image"},"facebook":{"appId":741780553338771}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
