import { Link } from "gatsby";
import { CalculateFontSize } from "styled/base/SizeFunctions";
import { CommonLink, StandardFont } from "styled/common/font-styles/CommonFonts";
import { Preserve3dMixin } from "styled/common/Utils";
import styled from "styled-components";

export const CookieNotificationWrapper = styled.div`
  position: fixed;
  top: 100.1vh;
  ${Preserve3dMixin};
  transform: translate3d(0, -100%, 30px);
  left: 0px;
  z-index: 99;
  box-sizing: border-box;
  width: 100%;
  max-height: 86px;
  padding: 19px 100px;
  display: flex;
  justify-content: space-between;
  pointer-events: all;
  color: ${({ theme: { colors } }) => colors.black};
  background-color: ${({ theme: { colors } }) => colors.footer};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.footer};

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-height: 130px;
    padding: 19px 30px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-height: 500px;
    padding: 19px 30px 85px 30px;
    flex-direction: column;
  }
`;

export const CookieButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    justify-content: flex-start;
    gap: 3px;
  }
`;

type CookieButtonProps = {
  darkVariant?: boolean;
};

export const CookieButton = styled.button<CookieButtonProps>`
  width: 125px;
  padding: 7px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors.black};
  border-radius: 54px;
  background-color: ${({ darkVariant, theme: { colors } }) =>
    darkVariant ? colors.black : "inherit"};
  color: ${({ darkVariant, theme: { colors } }) => (darkVariant ? colors.white : colors.black)};
  transition: background-color 200ms linear, color 200ms linear;
  ${StandardFont};
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1, desktop: 0.9 })}

  &:hover {
    background-color: ${({ darkVariant, theme: { colors } }) =>
      !darkVariant ? colors.black : "inherit"};
    color: ${({ darkVariant, theme: { colors } }) => (!darkVariant ? colors.white : colors.black)};
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding: 5px 0;
  }
`;

export const CookieText = styled.p`
  ${StandardFont}
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1.2, desktop: 1.1 })}

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    width: 60%;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    margin-bottom: 20px;
  }
`;

export const CookieLink = styled(Link)`
  ${CommonLink};
  color: ${({ theme: { colors } }) => colors.black};
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.black};
  ${({ theme: { fonts } }) => fonts.medium};
  pointer-events: all;
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1.2, desktop: 1.1 })}
`;
