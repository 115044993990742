import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { graphql, useStaticQuery } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

/* ------------------------TYPES---------------------------- */
interface ImageDatabaseProps {
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
}

export type ImageContextData = Record<string, ImageDatabaseProps>;

type ImageDatabaseContextType = [ImageContextData, Dispatch<AnyAction>];

/* ------------------------REDUX------------------------ */
const ImageDatabaseSlice = createSlice({
  name: "ImageDatabase",
  reducers: {
    setImages: (state, action: PayloadAction<ImageContextData>) => {
      state = action.payload;
    }
  },
  initialState: {}
});
export const { setImages } = ImageDatabaseSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const ImageDatabaseContext = createContext<ImageDatabaseContextType>([{}, () => null]);

export const ImageDatabaseContextProvider: Component = ({ children }) => {
  const data = useStaticQuery(imagesQuery);

  const [state, dispatch] = useReducer<Reducer<ImageContextData, AnyAction>>(
    ImageDatabaseSlice.reducer,
    data
  );

  return (
    <ImageDatabaseContext.Provider value={[state, dispatch]}>
      {children}
    </ImageDatabaseContext.Provider>
  );
};

export const useImageDatabaseContext = (): ImageDatabaseContextType => {
  return useContext(ImageDatabaseContext);
};

export const GetThunderData = graphql`
  fragment GetThunderData on File {
    childImageSharp {
      gatsbyImageData(
        width: 128
        height: 128
        quality: 85
        layout: CONSTRAINED
        placeholder: BLURRED
        formats: [AUTO, WEBP]
      )
    }
  }
`;

export const GetCloudData = graphql`
  fragment GetCloudData on File {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetIconImage = graphql`
  fragment GetIconImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 512
        height: 512
        quality: 95
        layout: CONSTRAINED
        placeholder: BLURRED
        formats: [AUTO, WEBP]
      )
    }
  }
`;

export const GetProjectIconImage = graphql`
  fragment GetProjectIconImage on File {
    childImageSharp {
      gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetRectImage = graphql`
  fragment GetRectImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 880
        height: 800
        quality: 85
        layout: CONSTRAINED
        placeholder: BLURRED
        formats: [AUTO, WEBP]
      )
    }
  }
`;

export const GetFullscreenImage = graphql`
  fragment GetFullscreenImage on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetTeamImageFull = graphql`
  fragment GetTeamImageFull on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;
export const GetTeamImageMedium = graphql`
  fragment GetTeamImageMedium on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;
export const GetTeamImageSmall = graphql`
  fragment GetTeamImageSmall on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;
export const GetTeamImageLarge = graphql`
  fragment GetTeamImageLarge on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;
export const GetTeamImageRegular = graphql`
  fragment GetTeamImageRegular on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetFloatingImageSide = graphql`
  fragment GetFloatingImageSide on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetFloatingImageCenter = graphql`
  fragment GetFloatingImageCenter on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

export const GetSquareImage = graphql`
  fragment GetSquareImage on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;

const imagesQuery = graphql`
  {
    Floating1: file(relativePath: { eq: "images/photos/floating-1.jpg" }) {
      ...GetFloatingImageSide
    }
    Floating2: file(relativePath: { eq: "images/photos/floating-2.jpg" }) {
      ...GetFloatingImageCenter
    }
    Floating3: file(relativePath: { eq: "images/photos/floating-3.jpg" }) {
      ...GetFloatingImageSide
    }
    Floating4: file(relativePath: { eq: "images/photos/floating-4.jpg" }) {
      ...GetFloatingImageSide
    }
    Floating5: file(relativePath: { eq: "images/photos/floating-5.jpg" }) {
      ...GetFloatingImageCenter
    }
    Floating6: file(relativePath: { eq: "images/photos/floating-6.jpg" }) {
      ...GetFloatingImageSide
    }
    HomeLeft: file(relativePath: { eq: "images/photos/home-left.jpg" }) {
      ...GetFloatingImageCenter
    }
    Square1: file(relativePath: { eq: "images/photos/square-1.jpg" }) {
      ...GetSquareImage
    }
    Square2: file(relativePath: { eq: "images/photos/square-2.jpg" }) {
      ...GetSquareImage
    }
    HomeRight: file(relativePath: { eq: "images/photos/home-right.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 720
          height: 720
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    OfficePhoto: file(relativePath: { eq: "images/photos/OfficePhoto.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 90
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    #
    #--------------------------------TEAM---------------------------------------
    #
    PiotrPenarSmall: file(relativePath: { eq: "images/other/piotr-penar-small.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }

    AgnieszkaKosciecha: file(relativePath: { eq: "images/team/agnieszka-kosciecha.png" }) {
      ...GetTeamImageFull
    }
    AleksandraCurylo: file(relativePath: { eq: "images/team/aleksandra-curylo.png" }) {
      ...GetTeamImageFull
    }
    AndrzejGolas: file(relativePath: { eq: "images/team/andrzej-golas.png" }) {
      ...GetTeamImageFull
    }
    BogdanDrzewiecki: file(relativePath: { eq: "images/team/bogdan-drzewiecki.png" }) {
      ...GetTeamImageFull
    }
    DanielFojcik: file(relativePath: { eq: "images/team/daniel-fojcik.png" }) {
      ...GetTeamImageFull
    }
    FilipStarczewski: file(relativePath: { eq: "images/team/filip-starczewski.png" }) {
      ...GetTeamImageFull
    }
    IgorKaluza: file(relativePath: { eq: "images/team/igor-kaluza.png" }) {
      ...GetTeamImageFull
    }
    IgorPatro: file(relativePath: { eq: "images/team/igor-patro.png" }) {
      ...GetTeamImageFull
    }
    JuliaBodak: file(relativePath: { eq: "images/team/julia-bodak.png" }) {
      ...GetTeamImageFull
    }
    JustynaPtak: file(relativePath: { eq: "images/team/justyna-ptak.png" }) {
      ...GetTeamImageFull
    }
    KamilKolecki: file(relativePath: { eq: "images/team/kamil-kolecki.png" }) {
      ...GetTeamImageFull
    }
    KarolinaDabek: file(relativePath: { eq: "images/team/karolina-dabek.png" }) {
      ...GetTeamImageFull
    }
    KatarzynaBohn: file(relativePath: { eq: "images/team/katarzyna-bohn.png" }) {
      ...GetTeamImageFull
    }
    KatarzynaKopec: file(relativePath: { eq: "images/team/katarzyna-kopec.png" }) {
      ...GetTeamImageFull
    }
    KatarzynaSitek: file(relativePath: { eq: "images/team/katarzyna-sitek.png" }) {
      ...GetTeamImageFull
    }
    KingaNowak: file(relativePath: { eq: "images/team/kinga-nowak.png" }) {
      ...GetTeamImageFull
    }
    LukaszWiduch: file(relativePath: { eq: "images/team/lukasz-widuch.png" }) {
      ...GetTeamImageFull
    }
    MartaEbisz: file(relativePath: { eq: "images/team/marta-ebisz.png" }) {
      ...GetTeamImageFull
    }
    MichalBorowiecki: file(relativePath: { eq: "images/team/michal-borowiecki.png" }) {
      ...GetTeamImageFull
    }
    MichalLazowski: file(relativePath: { eq: "images/team/michal-lazowski.png" }) {
      ...GetTeamImageFull
    }
    OliwiaGolenia: file(relativePath: { eq: "images/team/oliwia-golenia.png" }) {
      ...GetTeamImageFull
    }
    PawelLesnik: file(relativePath: { eq: "images/team/pawel-lesnik.png" }) {
      ...GetTeamImageFull
    }
    PawelSlawuta: file(relativePath: { eq: "images/team/pawel-slawuta.png" }) {
      ...GetTeamImageFull
    }
    PiotrBednarczuk: file(relativePath: { eq: "images/team/piotr-bednarczuk.png" }) {
      ...GetTeamImageFull
    }
    PiotrCharchut: file(relativePath: { eq: "images/team/piotr-charchut.png" }) {
      ...GetTeamImageFull
    }
    PiotrPenar: file(relativePath: { eq: "images/team/piotr-penar.png" }) {
      ...GetTeamImageFull
    }
    PiotrWrobel: file(relativePath: { eq: "images/team/piotr-wrobel.png" }) {
      ...GetTeamImageFull
    }
    SzymonKin: file(relativePath: { eq: "images/team/szymon-kin.png" }) {
      ...GetTeamImageFull
    }
    TymoteuszKossek: file(relativePath: { eq: "images/team/tymoteusz-kossek.png" }) {
      ...GetTeamImageFull
    }
    NaturalInstincts1: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-1.png" }
    ) {
      ...GetFullscreenImage
    }
    NaturalInstincts2: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-2.png" }
    ) {
      ...GetFullscreenImage
    }
    NaturalInstincts5: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-5.png" }
    ) {
      ...GetFullscreenImage
    }
    NaturalInstincts7: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-7.png" }
    ) {
      ...GetFullscreenImage
    }
    NaturalInstincts11: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-11.png" }
    ) {
      ...GetFullscreenImage
    }
    NaturalInstincts1Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-1-880x800.png" }
    ) {
      ...GetRectImage
    }
    NaturalInstincts2Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-2-880x800.png" }
    ) {
      ...GetRectImage
    }
    NaturalInstincts3Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-3-880x800.png" }
    ) {
      ...GetRectImage
    }
    NaturalInstincts5Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-5-880x800.png" }
    ) {
      ...GetRectImage
    }
    NaturalInstincts6Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-6-880x800.png" }
    ) {
      ...GetRectImage
    }
    NaturalInstincts8Rect: file(
      relativePath: { eq: "images/projects/natural-instincts/natural-instincts-8-880x800.png" }
    ) {
      ...GetRectImage
    }
    MedievalWarmiaAndMasuria2: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-2.png"
      }
    ) {
      ...GetFullscreenImage
    }
    MedievalWarmiaAndMasuria3: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-3.png"
      }
    ) {
      ...GetFullscreenImage
    }
    MedievalWarmiaAndMasuria14: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-14.png"
      }
    ) {
      ...GetFullscreenImage
    }
    MedievalWarmiaAndMasuria2Rect: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-2-880x800.png"
      }
    ) {
      ...GetRectImage
    }
    MedievalWarmiaAndMasuria4Rect: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-4-880x800.png"
      }
    ) {
      ...GetRectImage
    }
    MedievalWarmiaAndMasuria5Rect: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-5-880x800.png"
      }
    ) {
      ...GetRectImage
    }
    MedievalWarmiaAndMasuria6Rect: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-6-880x800.png"
      }
    ) {
      ...GetRectImage
    }
    MedievalWarmiaAndMasuria14Rect: file(
      relativePath: {
        eq: "images/projects/medieval-warmia-and-masuria/medieval-warmia-and-masuria-14-880x800.png"
      }
    ) {
      ...GetRectImage
    }
    ARHunters1: file(relativePath: { eq: "images/projects/ar-hunters/ar-hunters-1.png" }) {
      ...GetFullscreenImage
    }
    ARHunters6: file(relativePath: { eq: "images/projects/ar-hunters/ar-hunters-6.png" }) {
      ...GetFullscreenImage
    }
    ARHunters7: file(relativePath: { eq: "images/projects/ar-hunters/ar-hunters-7.png" }) {
      ...GetFullscreenImage
    }
    ARHunters3Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-3-880x800.png" }
    ) {
      ...GetRectImage
    }
    ARHunters5Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-5-880x800.png" }
    ) {
      ...GetRectImage
    }
    ARHunters6Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-6-880x800.png" }
    ) {
      ...GetRectImage
    }
    ARHunters19Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-19-880x800.png" }
    ) {
      ...GetRectImage
    }
    ARHunters20Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-20-880x800.png" }
    ) {
      ...GetRectImage
    }
    ARHunters21Rect: file(
      relativePath: { eq: "images/projects/ar-hunters/ar-hunters-21-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision2: file(relativePath: { eq: "images/projects/trivision/trivision-2.png" }) {
      ...GetFullscreenImage
    }
    Trivision3: file(relativePath: { eq: "images/projects/trivision/trivision-3.png" }) {
      ...GetFullscreenImage
    }
    Trivision1Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-1-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision2Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-2-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision5Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-5-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision6Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-6-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision7Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-7-880x800.png" }
    ) {
      ...GetRectImage
    }
    Trivision9Rect: file(
      relativePath: { eq: "images/projects/trivision/trivision-9-880x800.png" }
    ) {
      ...GetRectImage
    }

    #
    #--------------------------------Crashed---------------------------------------
    #

    # Crashed1: file(relativePath: { eq: "images/projects/crashed/crashed-1.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed2: file(relativePath: { eq: "images/projects/crashed/crashed-2.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed3: file(relativePath: { eq: "images/projects/crashed/crashed-3.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed4: file(relativePath: { eq: "images/projects/crashed/crashed-4.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed5: file(relativePath: { eq: "images/projects/crashed/crashed-5.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed6: file(relativePath: { eq: "images/projects/crashed/crashed-6.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed7: file(relativePath: { eq: "images/projects/crashed/crashed-7.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed8: file(relativePath: { eq: "images/projects/crashed/crashed-8.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed9: file(relativePath: { eq: "images/projects/crashed/crashed-9.png" }) {
    #   ...GetFullscreenImage
    # }
    # Crashed10: file(relativePath: { eq: "images/projects/crashed/crashed-10.png" }) {
    #   ...GetFullscreenImage
    # }
    Crashed11: file(relativePath: { eq: "images/projects/crashed/crashed-11.png" }) {
      ...GetFullscreenImage
    }
    Crashed12: file(relativePath: { eq: "images/projects/crashed/crashed-12.png" }) {
      ...GetFullscreenImage
    }
    Crashed13: file(relativePath: { eq: "images/projects/crashed/crashed-13.png" }) {
      ...GetFullscreenImage
    }
    Crashed14: file(relativePath: { eq: "images/projects/crashed/crashed-14.png" }) {
      ...GetFullscreenImage
    }
    #--------------------------------------Crashed rect---------------------------------------------------
    Crashed1Rect: file(relativePath: { eq: "images/projects/crashed/crashed-1-880x800.png" }) {
      ...GetRectImage
    }
    # Crashed2Rect: file(relativePath: { eq: "images/projects/crashed/crashed-2-880x800.png" }) {
    #   ...GetRectImage
    # }
    Crashed3Rect: file(relativePath: { eq: "images/projects/crashed/crashed-3-880x800.png" }) {
      ...GetRectImage
    }
    Crashed4Rect: file(relativePath: { eq: "images/projects/crashed/crashed-4-880x800.png" }) {
      ...GetRectImage
    }
    # Crashed5Rect: file(relativePath: { eq: "images/projects/crashed/crashed-5-880x800.png" }) {
    #   ...GetRectImage
    # }
    # Crashed6Rect: file(relativePath: { eq: "images/projects/crashed/crashed-6-880x800.png" }) {
    #   ...GetRectImage
    # }
    Crashed7Rect: file(relativePath: { eq: "images/projects/crashed/crashed-7-880x800.png" }) {
      ...GetRectImage
    }
    # Crashed8Rect: file(relativePath: { eq: "images/projects/crashed/crashed-8-880x800.png" }) {
    #   ...GetRectImage
    # }
    Crashed9Rect: file(relativePath: { eq: "images/projects/crashed/crashed-9-880x800.png" }) {
      ...GetRectImage
    }
    Crashed10Rect: file(relativePath: { eq: "images/projects/crashed/crashed-10-880x800.png" }) {
      ...GetRectImage
    }

    #
    #--------------------------------Narru---------------------------------------
    #

    Narru1: file(relativePath: { eq: "images/projects/narru/narru-1.png" }) {
      ...GetFullscreenImage
    }
    Narru2: file(relativePath: { eq: "images/projects/narru/narru-2.png" }) {
      ...GetFullscreenImage
    }
    Narru3: file(relativePath: { eq: "images/projects/narru/narru-3.png" }) {
      ...GetFullscreenImage
    }
    Narru4: file(relativePath: { eq: "images/projects/narru/narru-4.png" }) {
      ...GetFullscreenImage
    }
    Narru5: file(relativePath: { eq: "images/projects/narru/narru-5.png" }) {
      ...GetFullscreenImage
    }
    Narru6: file(relativePath: { eq: "images/projects/narru/narru-6.png" }) {
      ...GetFullscreenImage
    }
    Narru7: file(relativePath: { eq: "images/projects/narru/narru-7.png" }) {
      ...GetFullscreenImage
    }
    #--------------------------------------Narru rect---------------------------------------------------
    Narru1Rect: file(relativePath: { eq: "images/projects/narru/narru-1-880x800.png" }) {
      ...GetRectImage
    }
    Narru2Rect: file(relativePath: { eq: "images/projects/narru/narru-2-880x800.png" }) {
      ...GetRectImage
    }
    Narru3Rect: file(relativePath: { eq: "images/projects/narru/narru-3-880x800.png" }) {
      ...GetRectImage
    }
    Narru4Rect: file(relativePath: { eq: "images/projects/narru/narru-4-880x800.png" }) {
      ...GetRectImage
    }
    Narru5Rect: file(relativePath: { eq: "images/projects/narru/narru-5-880x800.png" }) {
      ...GetRectImage
    }
    Narru6Rect: file(relativePath: { eq: "images/projects/narru/narru-6-880x800.png" }) {
      ...GetRectImage
    }
    # Narru7Rect: file(relativePath: { eq: "images/projects/narru/narru-7-880x800.png" }) {
    #   ...GetRectImage
    # }
    # Narru8Rect: file(relativePath: { eq: "images/projects/narru/narru-8-880x800.png" }) {
    #   ...GetRectImage
    # }
    # Narru9Rect: file(relativePath: { eq: "images/projects/narru/narru-9-880x800.png" }) {
    #   ...GetRectImage
    # }

    #
    #--------------------------------KaykoAndKokosh---------------------------------------
    #

    # KaykoAndKokosh1: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-1.png" }) {
    #   ...GetFullscreenImage
    # }
    KaykoAndKokosh2: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-2.png" }
    ) {
      ...GetFullscreenImage
    }
    KaykoAndKokosh3: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-3.png" }
    ) {
      ...GetFullscreenImage
    }
    # KaykoAndKokosh4: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-4.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh5: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-5.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh6: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-6.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh7: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-7.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh8: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-8.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh9: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-9.png" }) {
    #   ...GetFullscreenImage
    # }
    # KaykoAndKokosh10: file(relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-10.png" }) {
    #   ...GetFullscreenImage
    # }
    KaykoAndKokosh11: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-11.png" }
    ) {
      ...GetFullscreenImage
    }
    #--------------------------------------KaykoAndKokosh rect---------------------------------------------------
    KaykoAndKokosh1Rect: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-1-880x800.png" }
    ) {
      ...GetRectImage
    }
    KaykoAndKokosh2Rect: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-2-880x800.png" }
    ) {
      ...GetRectImage
    }
    KaykoAndKokosh3Rect: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-3-880x800.png" }
    ) {
      ...GetRectImage
    }
    KaykoAndKokosh4Rect: file(
      relativePath: { eq: "images/projects/kayko-and-kokosh/kayko-and-kokosh-4-880x800.png" }
    ) {
      ...GetRectImage
    }
    #
    #--------------------------------TheLabz---------------------------------------
    #

    # TheLabz1: file(relativePath: { eq: "images/projects/the-labz/the-labz-1.png" }) {
    #   ...GetFullscreenImage
    # }
    TheLabz2: file(relativePath: { eq: "images/projects/the-labz/the-labz-2.png" }) {
      ...GetFullscreenImage
    }
    TheLabz3: file(relativePath: { eq: "images/projects/the-labz/the-labz-3.png" }) {
      ...GetFullscreenImage
    }
    # TheLabz4: file(relativePath: { eq: "images/projects/the-labz/the-labz-4.png" }) {
    #   ...GetFullscreenImage
    # }
    TheLabz5: file(relativePath: { eq: "images/projects/the-labz/the-labz-5.png" }) {
      ...GetFullscreenImage
    }
    # TheLabz6: file(relativePath: { eq: "images/projects/the-labz/the-labz-6.png" }) {
    #   ...GetFullscreenImage
    # }
    # TheLabz7: file(relativePath: { eq: "images/projects/the-labz/the-labz-7.png" }) {
    #   ...GetFullscreenImage
    # }
    # TheLabz8: file(relativePath: { eq: "images/projects/the-labz/the-labz-8.png" }) {
    #   ...GetFullscreenImage
    # }
    # TheLabz9: file(relativePath: { eq: "images/projects/the-labz/the-labz-9.png" }) {
    #   ...GetFullscreenImage
    # }
    # TheLabz10: file(relativePath: { eq: "images/projects/the-labz/the-labz-10.png" }) {
    #   ...GetFullscreenImage
    # }
    # TheLabz11: file(relativePath: { eq: "images/projects/the-labz/the-labz-11.png" }) {
    #   ...GetFullscreenImage
    # }
    #--------------------------------------TheLabz rect---------------------------------------------------
    TheLabz1Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-1-880x800.png" }) {
      ...GetRectImage
    }
    TheLabz2Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-2-880x800.png" }) {
      ...GetRectImage
    }
    TheLabz3Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-3-880x800.png" }) {
      ...GetRectImage
    }
    TheLabz4Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-4-880x800.png" }) {
      ...GetRectImage
    }
    TheLabz5Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-5-880x800.png" }) {
      ...GetRectImage
    }
    TheLabz10Rect: file(relativePath: { eq: "images/projects/the-labz/the-labz-10-880x800.png" }) {
      ...GetRectImage
    }
    #
    #--------------------------------Aloki---------------------------------------
    Aloki1: file(relativePath: { eq: "images/projects/aloki/aloki-1.png" }) {
      ...GetFullscreenImage
    }
    Aloki2: file(relativePath: { eq: "images/projects/aloki/aloki-2.png" }) {
      ...GetFullscreenImage
    }
    Aloki3: file(relativePath: { eq: "images/projects/aloki/aloki-3.png" }) {
      ...GetFullscreenImage
    }
    Aloki4: file(relativePath: { eq: "images/projects/aloki/aloki-4.png" }) {
      ...GetFullscreenImage
    }
    Aloki5: file(relativePath: { eq: "images/projects/aloki/aloki-5.png" }) {
      ...GetFullscreenImage
    }
    #--------------------------------Aloki rect---------------------------------------
    Aloki1Rect: file(relativePath: { eq: "images/projects/aloki/aloki-1-880x800.png" }) {
      ...GetRectImage
    }
    Aloki2Rect: file(relativePath: { eq: "images/projects/aloki/aloki-2-880x800.png" }) {
      ...GetRectImage
    }
    Aloki3Rect: file(relativePath: { eq: "images/projects/aloki/aloki-3-880x800.png" }) {
      ...GetRectImage
    }
    Aloki4Rect: file(relativePath: { eq: "images/projects/aloki/aloki-4-880x800.png" }) {
      ...GetRectImage
    }
    Aloki5Rect: file(relativePath: { eq: "images/projects/aloki/aloki-5-880x800.png" }) {
      ...GetRectImage
    }

    #
    #-------------------------------- PROJECT ICONS---------------------------------------
    #
    NaturalInstinctsIcon: file(relativePath: { eq: "images/projects/natural-instincts-icon.png" }) {
      ...GetProjectIconImage
    }
    MedievalWarmiaAndMasuriaIcon: file(
      relativePath: { eq: "images/projects/medieval-warmia-and-masuria-icon.png" }
    ) {
      ...GetProjectIconImage
    }
    TrivisionIcon: file(relativePath: { eq: "images/projects/trivision-icon.png" }) {
      ...GetProjectIconImage
    }
    ArHuntersIcon: file(relativePath: { eq: "images/projects/ar-hunters-icon.png" }) {
      ...GetProjectIconImage
    }
    CrashedIcon: file(relativePath: { eq: "images/projects/crashed-icon.png" }) {
      ...GetProjectIconImage
    }
    NarruIcon: file(relativePath: { eq: "images/projects/narru-icon.png" }) {
      ...GetProjectIconImage
    }
    KaykoAndKokoshIcon: file(relativePath: { eq: "images/projects/kayko-and-kokosh-icon.png" }) {
      ...GetProjectIconImage
    }
    TheLabzIcon: file(relativePath: { eq: "images/projects/the-labz-icon.png" }) {
      ...GetProjectIconImage
    }
    AlokiIcon: file(relativePath: { eq: "images/projects/aloki-icon.png" }) {
      ...GetProjectIconImage
    }
    #
    #-------------------------------- OTHER ICONS---------------------------------------
    #
    ArIcon: file(relativePath: { eq: "images/icons/other/ar.png" }) {
      ...GetIconImage
    }
    VrIcon: file(relativePath: { eq: "images/icons/other/vr.png" }) {
      ...GetIconImage
    }
    GamesIcon: file(relativePath: { eq: "images/icons/other/games.png" }) {
      ...GetIconImage
    }
    EfficiencyIcon: file(relativePath: { eq: "images/icons/other/efficiency.png" }) {
      ...GetIconImage
    }
    CreativityIcon: file(relativePath: { eq: "images/icons/other/creativity.png" }) {
      ...GetIconImage
    }
    AutomationIcon: file(relativePath: { eq: "images/icons/other/automation.png" }) {
      ...GetIconImage
    }
    QualityIcon: file(relativePath: { eq: "images/icons/other/quality.png" }) {
      ...GetIconImage
    }
    ExperienceIcon: file(relativePath: { eq: "images/icons/other/experience.png" }) {
      ...GetIconImage
    }
    PassionIcon: file(relativePath: { eq: "images/icons/other/passion.png" }) {
      ...GetIconImage
    }
    AtmosphereIcon: file(relativePath: { eq: "images/icons/other/atmosphere.png" }) {
      ...GetIconImage
    }
    InnovationIcon: file(relativePath: { eq: "images/icons/other/innovation.png" }) {
      ...GetIconImage
    }
    CreativityIcon: file(relativePath: { eq: "images/icons/other/creativity.png" }) {
      ...GetIconImage
    }
    Automation1Icon: file(relativePath: { eq: "images/icons/other/automation1.png" }) {
      ...GetIconImage
    }
    WebDevIcon: file(relativePath: { eq: "images/icons/other/web-dev.png" }) {
      ...GetIconImage
    }
    ArtIcon: file(relativePath: { eq: "images/icons/other/art.png" }) {
      ...GetIconImage
    }
    StoryIcon: file(relativePath: { eq: "images/icons/other/story.png" }) {
      ...GetIconImage
    }
    DesignIcon: file(relativePath: { eq: "images/icons/other/design.png" }) {
      ...GetIconImage
    }
    AutomationHorizontallyIcon: file(
      relativePath: { eq: "images/icons/other/automation-horizontally.png" }
    ) {
      ...GetIconImage
    }
    #
    #--------------------------------CLOUDS---------------------------------------
    #
    BluePink1: file(relativePath: { eq: "images/clouds/Blue-Pink-1-noindex.png" }) {
      ...GetCloudData
    }
    BluePink2: file(relativePath: { eq: "images/clouds/Blue-Pink-2-noindex.png" }) {
      ...GetCloudData
    }
    BluePink3: file(relativePath: { eq: "images/clouds/Blue-Pink-3-noindex.png" }) {
      ...GetCloudData
    }
    DarkPurpleBlue1: file(relativePath: { eq: "images/clouds/DarkPurple-Blue-1-noindex.png" }) {
      ...GetCloudData
    }
    DarkPurpleBlue2: file(relativePath: { eq: "images/clouds/DarkPurple-Blue-2-noindex.png" }) {
      ...GetCloudData
    }
    DarkPurpleBlue3: file(relativePath: { eq: "images/clouds/DarkPurple-Blue-3-noindex.png" }) {
      ...GetCloudData
    }
    Green1: file(relativePath: { eq: "images/clouds/Green-1-noindex.png" }) {
      ...GetCloudData
    }
    Green2: file(relativePath: { eq: "images/clouds/Green-2-noindex.png" }) {
      ...GetCloudData
    }
    Green3: file(relativePath: { eq: "images/clouds/Green-3-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet1: file(relativePath: { eq: "images/clouds/Pink-Violet-1-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet2: file(relativePath: { eq: "images/clouds/Pink-Violet-2-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet3: file(relativePath: { eq: "images/clouds/Pink-Violet-3-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet21: file(relativePath: { eq: "images/clouds/Pink-Violet2-1-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet22: file(relativePath: { eq: "images/clouds/Pink-Violet2-2-noindex.png" }) {
      ...GetCloudData
    }
    PinkViolet23: file(relativePath: { eq: "images/clouds/Pink-Violet2-3-noindex.png" }) {
      ...GetCloudData
    }
    RedOrange1: file(relativePath: { eq: "images/clouds/Red-Orange-1-noindex.png" }) {
      ...GetCloudData
    }
    RedOrange2: file(relativePath: { eq: "images/clouds/Red-Orange-2-noindex.png" }) {
      ...GetCloudData
    }
    RedOrange3: file(relativePath: { eq: "images/clouds/Red-Orange-3-noindex.png" }) {
      ...GetCloudData
    }
    VioletBlack1: file(relativePath: { eq: "images/clouds/Violet-Black-1-noindex.png" }) {
      ...GetCloudData
    }
    VioletBlack2: file(relativePath: { eq: "images/clouds/Violet-Black-2-noindex.png" }) {
      ...GetCloudData
    }
    VioletBlue1: file(relativePath: { eq: "images/clouds/Violet-Blue-1-noindex.png" }) {
      ...GetCloudData
    }
    VioletBlue2: file(relativePath: { eq: "images/clouds/Violet-Blue-2-noindex.png" }) {
      ...GetCloudData
    }
    VioletBlue3: file(relativePath: { eq: "images/clouds/Violet-Blue-3-noindex.png" }) {
      ...GetCloudData
    }
    YellowOrange1: file(relativePath: { eq: "images/clouds/Yellow-Orange-1-noindex.png" }) {
      ...GetCloudData
    }
    YellowOrange2: file(relativePath: { eq: "images/clouds/Yellow-Orange-2-noindex.png" }) {
      ...GetCloudData
    }
    YellowOrange3: file(relativePath: { eq: "images/clouds/Yellow-Orange-3-noindex.png" }) {
      ...GetCloudData
    }
    #
    #--------------------------------Thunders---------------------------------------
    #
    BluePinkThunder1: file(relativePath: { eq: "images/thunders/Blue-Pink-Thunder1-noindex.png" }) {
      ...GetThunderData
    }
    BluePinkThunder2: file(relativePath: { eq: "images/thunders/Blue-Pink-Thunder2-noindex.png" }) {
      ...GetThunderData
    }
    DarkPurpleBlueThunder1: file(
      relativePath: { eq: "images/thunders/DarkPurple-Blue-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    DarkPurpleBlueThunder2: file(
      relativePath: { eq: "images/thunders/DarkPurple-Blue-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    GreenThunder1: file(relativePath: { eq: "images/thunders/Green-Thunder1-noindex.png" }) {
      ...GetThunderData
    }
    GreenThunder2: file(relativePath: { eq: "images/thunders/Green-Thunder2-noindex.png" }) {
      ...GetThunderData
    }
    PinkVioletThunder1: file(
      relativePath: { eq: "images/thunders/Pink-Violet-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    PinkVioletThunder2: file(
      relativePath: { eq: "images/thunders/Pink-Violet-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    PinkViolet2Thunder1: file(
      relativePath: { eq: "images/thunders/Pink-Violet2-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    PinkViolet2Thunder2: file(
      relativePath: { eq: "images/thunders/Pink-Violet2-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    RedOrangeThunder1: file(
      relativePath: { eq: "images/thunders/Red-Orange-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    RedOrangeThunder2: file(
      relativePath: { eq: "images/thunders/Red-Orange-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    VioletBlackThunder1: file(
      relativePath: { eq: "images/thunders/Violet-Black-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    VioletBlackThunder2: file(
      relativePath: { eq: "images/thunders/Violet-Black-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    VioletBlueThunder1: file(
      relativePath: { eq: "images/thunders/Violet-Blue-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    VioletBlueThunder2: file(
      relativePath: { eq: "images/thunders/Violet-Blue-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    YellowOrangeThunder1: file(
      relativePath: { eq: "images/thunders/Yellow-Orange-Thunder1-noindex.png" }
    ) {
      ...GetThunderData
    }
    YellowOrangeThunder2: file(
      relativePath: { eq: "images/thunders/Yellow-Orange-Thunder2-noindex.png" }
    ) {
      ...GetThunderData
    }
    #
    #--------------------------------Automation---------------------------------------
    #
    AutomationSquareOne: file(relativePath: { eq: "images/other/automation-square-one.png" }) {
      ...GetSquareImage
    }
    AutomationSquareTwo: file(relativePath: { eq: "images/other/automation-square-two.png" }) {
      ...GetSquareImage
    }
    #
    #--------------------------------OTHER---------------------------------------
    #
    BlackSquare: file(relativePath: { eq: "images/other/black-square-noindex.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 64
          height: 64
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    ArticleCTA: file(relativePath: { eq: "images/other/cta-article.png" }) {
      ...GetSquareImage
    }
    Noise: file(relativePath: { eq: "images/filters/noiseTiled-noindex.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    Farah: file(relativePath: { eq: "images/partners/farah-noindex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    Jakub: file(relativePath: { eq: "images/partners/jakub-noindex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    Barbara: file(relativePath: { eq: "images/partners/barbara-noindex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    Sebastian: file(relativePath: { eq: "images/partners/sebastian-noindex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    Dominik: file(relativePath: { eq: "images/partners/dominik-noindex.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    ClientPhoto: file(relativePath: { eq: "images/partners/clientPhoto-noindex.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          height: 128
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    MKDNiS_ENG: file(relativePath: { eq: "images/legal/MKDNiS_ENG.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2429
          height: 1030
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    MKDNiS_PL: file(relativePath: { eq: "images/legal/MKDNiS_PL.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2429
          height: 1030
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
