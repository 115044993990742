import { Link } from "gatsby";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { AnimatedLinks } from "styled/common/AnimatedLinks";
import { CommonLink, NavLinkFont } from "styled/common/font-styles/CommonFonts";
import { GridHeader } from "styled/common/Grid";
import { CenterFlex, SizeFillSpaceMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

export const HeaderWrapper = styled.header`
  ${GridHeader};
  transform: translateZ(5px);
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    width: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    width: 60%;
  }
  height: 100%;
`;

export const HeaderContent = styled.div`
  width: 100%;
  z-index: 5;
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  ${CalculateFromHeight("height", { mobile: 22, tablet: 15, desktop: 4 })}
  ${CalculateFromHeight("margin-top", { mobile: -4, tablet: -5, desktop: 5 })}
`;

export const HeaderLink = styled(Link)`
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    ${SizeFillSpaceMixin}
  }
  z-index: 5;
  position: relative;
  display: inline;
`;

export const HeaderLogoWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    border-left-color: rgb(255, 255, 255);
    border-left-style: solid;
  }
  ${CalculateFromWidth("width", { desktop: 14.3 })}
  ${CalculateFromWidth("margin-left", { desktop: 6.4 })}
  ${CalculateFromWidth("border-left-width", { desktop: 0.1 })}
  ${CalculateFromHeight("padding-top", { desktop: 0.15 })}
  ${CalculateFromHeight("margin-top", { desktop: 0.15 })}
  ${CalculateFromHeight("height", { desktop: 3.125 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }

  z-index: 10;
  cursor: pointer;
  overflow: hidden;
`;

export const NavWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    align-content: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
`;
export const NavLinks = styled.div`
  display: flex;
  align-content: flex-start;
  ${SizeFillSpaceMixin}
  ${AnimatedLinks}
  ${CalculateFromWidth("padding-left", { mobile: 7.5, tablet: 7.5, desktop: 7.5 })}
`;

const NavSharedElement = css`
  ${CenterFlex}
  ${CalculateFromWidth("margin-right", { mobile: 6, tablet: 6, desktop: 6 })}
  width: 15%;
  ${CommonLink};
  ${NavLinkFont};
`;

export const NavLink = styled(Link)`
  ${NavSharedElement}
`;

type NavElementProps = {
  cursor?: string;
};

export const NavElement = styled.a<NavElementProps>`
  ${NavSharedElement}
  position: relative;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
`;

export const HeaderLogo = css`
  z-index: 5;
  position: relative;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: none;
  }
  ${CalculateFromHeight("margin-top", { mobile: -1, tablet: -0.25, desktop: 0.02 })}
  ${CalculateFromWidth("margin-left", { mobile: 3.5, tablet: -0.5, desktop: 0.4 })}
  ${CalculateFromWidth("width", { mobile: 37, tablet: 25.5, desktop: 13 })}
  ${CalculateFromHeight("height", { mobile: 6.75, tablet: 4, desktop: 2.3 })}
`;

export const HeaderMobileSigil = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: none;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    display: block;
    position: absolute;
    bottom: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    display: block;
    position: absolute;
    bottom: 0;
  }
  ${CalculateFromWidth("margin-left", { mobile: 6.5, tablet: 2 })}
  ${CalculateFromWidth("height", { mobile: 10, tablet: 7 })}
  ${CalculateFromWidth("width", { mobile: 48.5, tablet: 35 })}
`;

export const HeaderChildrenMenuWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  transform: translateY("100%");
`;

export const HeaderChildrenMenuLink = styled(Link)`
  ${CommonLink};
  ${NavLinkFont};
  z-index: 99;
  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: 1, desktop: 1 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 1, tablet: 1, desktop: 1 })}

  &:hover {
    ${({ theme: { fonts } }) => fonts.demiBold};
  }
`;
