import {
  CalculateFontSize,
  CalculateFromHeight,
  CalculateFromWidth
} from "styled/base/SizeFunctions";
import { EmptyCircle, FilledCircle } from "styled/common/Buttons";
import { CommonLink } from "styled/common/font-styles/CommonFonts";
import { CenterFlex, NonSelectable, SizeFillSpaceMixin } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type StandardButtonVariant =
  | "big-project-standard-button"
  | "contact-form-result-button-wrapper"
  | "home-description-button"
  | "why-us-side-button"
  | "home-project-button";

export const StandardButtonFilledCircle = styled.div`
  ${FilledCircle};
  ${CenterFlex};
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export const StandardButtonWrapper = styled.div`
  ${CalculateFromWidth("width", { mobile: 50, tablet: 30, desktop: 22.35 })}
  ${CalculateFromWidth("height", { mobile: 17.5, tablet: 9, desktop: 4.7 })}
`;

export const StandardButtonCircleWrapper = styled.div`
  ${CalculateFromWidth("width", { mobile: 17.5, tablet: 9, desktop: 4.7 })}
  ${CalculateFromWidth("height", { mobile: 17.5, tablet: 9, desktop: 4.7 })}
  ${CenterFlex};
  z-index: 1;

  position: absolute;
`;

export const StandardButtonEmptyCircle = styled.div`
  ${EmptyCircle}
`;

export const StandardButtonArrow = styled.img`
  z-index: 2;
  ${NonSelectable}
  ${CalculateFromWidth("height", { mobile: 2.4, tablet: 2.4, desktop: 2.4 })}
  margin: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
`;
export const StandardButtonTextParent = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    ${CenterFlex}
    width: fit-content;
    height: 100%;
    flex-direction: column;
  }

  ${CalculateFromWidth("max-width", { desktop: 20 })}
  ${CalculateFromWidth("margin-left", { mobile: 2.35, tablet: 2.35, desktop: 2.35 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    ${CenterFlex}
    ${SizeFillSpaceMixin}
    flex-direction: column;
  }
`;

export const StandardButtonText = styled.p`
  ${CommonLink};
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-left: 0;
    align-self: flex-start;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-top: auto;
    margin-bottom: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    align-self: flex-start;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${CalculateFromWidth("margin-left", { mobile: 5, tablet: 3 })}
  white-space: nowrap;
  transform: translate(0%, 0px);
`;

export const StandardButtonLink = styled.a`
  ${CommonLink}
`;

const ContactFormStandardButton = css`
  ${StandardButtonCircleWrapper} {
    ${CalculateFromHeight("height", { desktop: 7.4 })}
    ${CalculateFromWidth("width", { desktop: 5 })}
  }
  ${StandardButtonCircleWrapper} {
    ${CalculateFromWidth("width", { tablet: 10.15 })}
    ${CalculateFromHeight("height", { tablet: 8.69 })}
  }
  ${StandardButtonText} {
    ${CalculateFromWidth("width", { tablet: 20 })}
    ${CalculateFromHeight("margin-right", { tablet: 8 })}
  }
  ${StandardButtonCircleWrapper} {
    ${CalculateFromHeight("height", { mobile: 10.5 })}
    ${CalculateFromWidth("width", { mobile: 10 })}
  }
  ${StandardButtonText} {
    ${CalculateFromHeight("margin-top", { mobile: 2 })}
    ${CalculateFromWidth("margin-left", { mobile: 2 })}
      ${CalculateFromWidth("width", { mobile: 40 })}
  }
`;

const ContactFormLinkFont = css`
  ${StandardButtonText} {
    ${CalculateFontSize("font-size", { mobile: 1, tablet: 1.3 })}
    ${CalculateFontSize("line-height", { mobile: 2.2, tablet: 2.2 })}
  }
`;

const ContactFormResultButtonWrapper = css`
  ${ContactFormStandardButton}
  ${ContactFormLinkFont}
  ${StandardButtonText} {
    ${CalculateFontSize("font-size", { mobile: 1, tablet: 1.3 })}
    ${CalculateFontSize("line-height", { mobile: 2.2, tablet: 2.2 })}
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 2;
    grid-row: 3 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 3;
    grid-row: 3 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 3;
    grid-row: 5 / span 1;
  }
  ${CalculateFromWidth("margin-left", { desktop: 3 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 3, desktop: 1 })}
`;

const WhyUsSideButton = css`
  ${CalculateFromHeight("margin-bottom", { tablet: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: 7, desktop: 7 })}
`;

const HomeDescriptionButton = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 3;
    grid-row: 10 / span 2;
    display: flex;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 4;
    grid-row: 10 / span 2;
    display: flex;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 6;
    grid-row: 11 / span 2;
    display: flex;
  }
  ${CalculateFromWidth("margin-left", { mobile: -3 })}
`;

const HomeProjectButton = css`
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: relative;
    margin-top: auto;
    margin-bottom: 0;
  }
  ${CalculateFromHeight("margin-bottom", { tablet: -5 })}
  ${CalculateFromHeight("margin-top", { tablet: 5 })}
  ${CalculateFromWidth("margin-left", { tablet: 12 })}
`;

const BigProjectStandardButton = css`
  ${CommonLink}
  ${HomeProjectButton}
`;

export const ButtonStandard = styled.div<{ variant?: StandardButtonVariant }>`
  ${({ variant }) => variant === "home-project-button" && HomeProjectButton}
  ${({ variant }) => variant === "big-project-standard-button" && BigProjectStandardButton}
  ${({ variant }) =>
    variant === "contact-form-result-button-wrapper" && ContactFormResultButtonWrapper}
  ${({ variant }) => variant === "home-description-button" && HomeDescriptionButton}
  ${({ variant }) => variant === "why-us-side-button" && WhyUsSideButton}
`;
