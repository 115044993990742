import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

import { useLocation } from "@reach/router";
import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

/* ------------------------TYPES---------------------------- */
interface BlackSidePanelContextData {
  footerReference: unknown;
  blackSidePanelWrapperHeight: number;
  allowSmallerHeight: boolean;
}

type BlackSidePanelContextType = [BlackSidePanelContextData, Dispatch<AnyAction>];

const DefaultBlackSidePanelContextData: BlackSidePanelContextData = {
  footerReference: null,
  blackSidePanelWrapperHeight: 0,
  allowSmallerHeight: true
};

/* ------------------------REDUX------------------------ */
const BlackSidePanelSlice = createSlice({
  name: "BlackSidePanel",
  reducers: {
    setFooterReference: (state, action: PayloadAction<unknown>) => {
      state.footerReference = action.payload;
    },
    setSidePanelWrapperHeight: (state, action: PayloadAction<number>) => {
      if (action.payload > state.blackSidePanelWrapperHeight || state.allowSmallerHeight) {
        state.blackSidePanelWrapperHeight = action.payload;
        state.allowSmallerHeight = false;
      }
    },
    setAllowSmallerHeight: (state, action: PayloadAction<boolean>) => {
      state.allowSmallerHeight = action.payload;
    }
  },
  initialState: DefaultBlackSidePanelContextData
});
export const { setFooterReference, setSidePanelWrapperHeight, setAllowSmallerHeight } =
  BlackSidePanelSlice.actions;

/* ------------------------CONTEXT------------------------ */
export const BlackSidePanelContext = createContext<BlackSidePanelContextType>([
  DefaultBlackSidePanelContextData,
  () => null
]);

export const BlackSidePanelContextProvider: Component = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<BlackSidePanelContextData, AnyAction>>(
    BlackSidePanelSlice.reducer,
    DefaultBlackSidePanelContextData
  );
  const location = useLocation();

  useFirstRenderEffect(() => {
    dispatch(setAllowSmallerHeight(true));
  }, [location]);

  return (
    <BlackSidePanelContext.Provider value={[state, dispatch]}>
      {" "}
      {children}
    </BlackSidePanelContext.Provider>
  );
};

export const useBlackSidePanelContext = (): BlackSidePanelContextType => {
  return useContext(BlackSidePanelContext);
};
