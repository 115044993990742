import { CtaButtonText } from "components/common/buttons/ButtonCta/ButtonCta.styled";
import { CalculateFontSize } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const ContactFormSendWrapperFont = css`
  ${CtaButtonText} {
    ${CalculateFontSize("font-size", { mobile: 1.93, tablet: 1.49, desktop: 1.25 })}
    ${CalculateFontSize("line-height", { mobile: 1.93, tablet: 1.5, desktop: 1.5 })}
    ${({ theme: { fonts } }) => fonts.medium}
    font-weight: 500;
  }
`;

export const ContactFormTitleFont = css`
  ${CalculateFontSize("font-size", { mobile: 4, tablet: 6.3, desktop: 6.5 })}
  ${CalculateFontSize("line-height", { mobile: 5, tablet: 6.9, desktop: 7.2 })}
  ${CalculateFontSize("letter-spacing", { mobile: -0.2, tablet: -0.2, desktop: -0.3 })}
  ${({ theme: { fonts } }) => fonts.demiBold}
  font-weight: 500;
  text-transform: uppercase;
`;

export const ContactFormDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 1.49, desktop: 1.4 })}
  ${CalculateFontSize("line-height", { mobile: 2.5, tablet: 2, desktop: 2 })}
  ${({ theme: { fonts } }) => fonts.light}
`;

export const ContactFormValidationCommonFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.3, desktop: 1.3 })}
  ${CalculateFontSize("line-height", { mobile: 2 })}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    text-align: center;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    text-align: center;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    text-align: right;
  }
  ${({ theme: { fonts } }) => fonts.extraLight}
`;

export const ContactFormValidationCommonFontTerms = css`
  ${CalculateFontSize("font-size", { mobile: 1.2, tablet: 1.2, desktop: 1.1 })}
  ${CalculateFontSize("line-height", { mobile: 2 })}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    text-align: center;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    text-align: center;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    text-align: left;
  }
`;

export const ContactFormCommonFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.88, tablet: 1.49, desktop: 1.3 })}
  ${({ theme: { fonts } }) => fonts.extraLight}
  color: white;

  &::placeholder {
    ${({ theme: { breakpoints } }) => breakpoints.desktop} {
      opacity: 0.8;
    }
    ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
      opacity: 1;
    }
    color: rgb(228, 226, 226);
  }

  &:-webkit-autofill {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
  }
`;

export const ContactFormContentFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.5, tablet: 1.5, desktop: 1.5 })}
  ${({ theme: { fonts } }) => fonts.light}
  color: white;
`;

export const ContactFormCheckboxTextFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.47, tablet: 1.32, desktop: 1.2 })}

  color: rgb(228, 226, 226);
`;

export const ContactFormResultTitleFont = css`
  ${CalculateFontSize("font-size", { mobile: 5, tablet: 7, desktop: 8.8 })}
  ${CalculateFontSize("line-height", { mobile: 4.5, tablet: 6.2, desktop: 7.35 })}
  ${CalculateFontSize("letter-spacing", { mobile: 0.2, tablet: -0.2, desktop: -0.4 })}
`;

export const ContactFormResultDescriptionFont = css`
  ${CalculateFontSize("font-size", { mobile: 1, tablet: 1.3, desktop: 1.5 })}
  ${CalculateFontSize("line-height", { mobile: 1.5, tablet: 2.5, desktop: 2.3 })}

  color: white;
`;

export const ContactFormMailAddressFont = css`
  ${CalculateFontSize("font-size", { mobile: 0.95, tablet: 1.19, desktop: 1.35 })}
  ${CalculateFontSize("line-height", { mobile: 1, tablet: 2.3, desktop: 2.3 })}
  ${({ theme: { fonts } }) => fonts.light}

  font-weight: 200;
`;
