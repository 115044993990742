import { CalculateFromWidth } from "styled/base/SizeFunctions";
import { Button, FilledCircleBackground, FilledCircleForeground } from "styled/common/Buttons";
import { CtaButtonContentWrapperFont } from "styled/common/font-styles/ButtonFonts";
import {
  CenterFlex,
  InvertColors,
  SizeFillSpaceMixin,
  StaticElement,
  TextBlack,
  TextWhite
} from "styled/common/Utils";
import styled, { css } from "styled-components";

export type CtaButtonColor = "black" | "white";
export type CtaButtonVariant =
  | "contact-page-cta-button-font"
  | "cta-button-small"
  | "cta-button-wide";

type FilledCircleProps = {
  variant?: CtaButtonColor;
  wide: boolean;
};

const CircleDefaultVariant = css`
  border-radius: 50%;
  width: 100%;
`;

const CircleWideVariant = css`
  border-radius: 54px;
  height: 74px;
  width: 100%;
  ${CalculateFromWidth("padding-left", { tablet: 3 })}
  ${CalculateFromWidth("padding-right", { tablet: 3 })}

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    height: 69px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    height: 64px;
  }
`;

export const FilledCircleHover = styled.div<FilledCircleProps>`
  ${FilledCircleForeground};
  background-color: ${({
    variant,
    theme: {
      colors: { white, footer }
    }
  }) => (variant === "black" ? white : footer)};
  ${({ wide }) => (wide ? CircleWideVariant : CircleDefaultVariant)};
`;

export const FilledCircle = styled.div<FilledCircleProps>`
  ${FilledCircleBackground};
  background-color: ${({
    variant,
    theme: {
      colors: { black, white }
    }
  }) => (variant === "black" ? black : white)};
  ${({ wide }) => (wide ? CircleWideVariant : CircleDefaultVariant)};
`;

export const CtaButtonContentWrapper = styled.div`
  ${CtaButtonContentWrapperFont}
  ${CenterFlex}
  position: relative;
  z-index: 4;
  display: inline-flex;
  flex-direction: row;
`;

export const CtaButtonText = styled.p<{ variant?: CtaButtonColor }>`
  white-space: nowrap;
  display: inline-block;
  margin: auto;
  ${({ variant }) => (variant === "black" ? TextWhite : TextBlack)}
`;

export const CtaButtonArrow = styled.img<{ variant?: CtaButtonColor }>`
  display: inline-block;
  margin: auto;
  margin-left: 17px;
  font-size: 20px;
  width: 20px;
  height: 20px;

  ${({ variant }) => variant === "black" && InvertColors}

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 18px;
    height: 18px;
  }
`;

const CtaButtonSmall = css`
  ${CtaButtonArrow} {
    ${CalculateFromWidth("height", { mobile: 3, tablet: 3, desktop: 1 })}
  }
`;

export const CtaButtonWrapper = styled.div<{ variant?: CtaButtonVariant; wide?: boolean }>`
  position: relative;
  padding-top: ${({ wide }) => (wide ? "0px" : "100%")};
  width: 100%;
  color: white;
  ${({ variant }) =>
    variant === "contact-page-cta-button-font" &&
    css`
      ${CtaButtonText} {
        font-size: 20px;
        line-height: 28px;
        ${({ theme: { fonts } }) => fonts.medium}
        font-weight: 500;

        ${({ theme: { breakpoints } }) => breakpoints.tablet} {
          font-size: 18px;
          line-height: 24px;
        }
        ${({ theme: { breakpoints } }) => breakpoints.mobile} {
          font-size: 16px;
          line-height: 22px;
        }
      }
    `}
  ${({ variant }) => variant === "cta-button-small" && CtaButtonSmall}
`;

export const ButtonCta = styled.button`
  ${Button};
  ${CenterFlex};
  ${StaticElement}
  bottom: 0;
  right: 0;
  ${SizeFillSpaceMixin}
`;
