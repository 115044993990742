exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-automation-tsx": () => import("./../../../src/pages/automation.tsx" /* webpackChunkName: "component---src-pages-automation-tsx" */),
  "component---src-pages-blog-article-tsx": () => import("./../../../src/pages/blog/article.tsx" /* webpackChunkName: "component---src-pages-blog-article-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-game-development-tsx": () => import("./../../../src/pages/game-development.tsx" /* webpackChunkName: "component---src-pages-game-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-aloki-tsx": () => import("./../../../src/pages/projects/aloki.tsx" /* webpackChunkName: "component---src-pages-projects-aloki-tsx" */),
  "component---src-pages-projects-ar-hunters-tsx": () => import("./../../../src/pages/projects/ar-hunters.tsx" /* webpackChunkName: "component---src-pages-projects-ar-hunters-tsx" */),
  "component---src-pages-projects-crashed-tsx": () => import("./../../../src/pages/projects/crashed.tsx" /* webpackChunkName: "component---src-pages-projects-crashed-tsx" */),
  "component---src-pages-projects-kayko-and-kokosh-tsx": () => import("./../../../src/pages/projects/kayko-and-kokosh.tsx" /* webpackChunkName: "component---src-pages-projects-kayko-and-kokosh-tsx" */),
  "component---src-pages-projects-narru-tsx": () => import("./../../../src/pages/projects/narru.tsx" /* webpackChunkName: "component---src-pages-projects-narru-tsx" */),
  "component---src-pages-projects-natural-instincts-tsx": () => import("./../../../src/pages/projects/natural-instincts.tsx" /* webpackChunkName: "component---src-pages-projects-natural-instincts-tsx" */),
  "component---src-pages-projects-the-labz-tsx": () => import("./../../../src/pages/projects/the-labz.tsx" /* webpackChunkName: "component---src-pages-projects-the-labz-tsx" */),
  "component---src-pages-projects-trivision-tsx": () => import("./../../../src/pages/projects/trivision.tsx" /* webpackChunkName: "component---src-pages-projects-trivision-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-web-development-tsx": () => import("./../../../src/pages/web-development.tsx" /* webpackChunkName: "component---src-pages-web-development-tsx" */)
}

