import type { IGatsbyImageData } from "gatsby-plugin-image";
import type { Resource, Texture } from "pixi.js";
import * as PIXI from "pixi.js";
import { ThunderData } from "providers/CloudThunderDataProvider";
import UUID from "uuid";

type LoadingEntry = {
  address: string;
  loader: PIXI.Loader;
  image?: Texture<Resource>;
  scheduledCallbacks: ((texture: Texture<Resource>) => void)[];
};

export const CreateRenderElement = (
  id: UUID,
  name: string,
  type: string | undefined,
  wrapper: HTMLDivElement | null,
  group: string | undefined,
  additionalData: ThunderData | undefined,
  maskWrapper: HTMLDivElement | null | undefined
) => {
  const value = {
    id,
    name,
    type,
    wrapper,
    group,
    additionalData,
    maskWrapper
  };

  return value;
};

const loadingEntries = [] as LoadingEntry[];
let webpSupported: boolean;
let webP: HTMLImageElement;

const OnWebpTested = (callback: (supported: boolean) => void) => {
  if (typeof webpSupported !== "undefined") {
    return callback(webpSupported);
  }

  if (typeof webP === "undefined") {
    webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA";
  }

  const onSupportDetected = () => {
    webpSupported = webP.height === 2;
    callback(webpSupported);
  };

  webP.addEventListener("load", onSupportDetected);
  webP.addEventListener("error", onSupportDetected);
};

if (typeof window !== "undefined") {
  OnWebpTested(() => undefined);
}

export const LoadImageData = (
  imageData: IGatsbyImageData,
  parent: DOMRect,
  callback: (texture: Texture<Resource>) => void,
  multiplier = 1
) => {
  OnWebpTested(supported => {
    if (imageData.images.sources === undefined || imageData.images.fallback === undefined) {
      return;
    }

    const sourceSet = supported
      ? imageData.images.sources[0].srcSet
      : imageData.images.fallback.srcSet!;
    const addresses = sourceSet
      .split("\n")
      .map(item => item.split(" "))
      .map(element => {
        return { address: element[0].substring(1), width: parseFloat(element[1].split("w")[0]) };
      });

    const parentWidth = parent.width * multiplier;
    const addressObject =
      addresses[addresses.indexOf(addresses.find(element => element.width >= parentWidth)!) - 1] ??
      addresses[0];
    const targetAddress = addressObject.address;
    const loadingEntry = loadingEntries.filter(item => item.address === targetAddress)[0];

    if (loadingEntry) {
      ProcessExistingEntry(loadingEntry, callback);

      return;
    }

    AddNewLoadingEntry(targetAddress, callback);
  });
};

const ProcessExistingEntry = (
  loadingEntry: LoadingEntry,
  callback: (texture: Texture<Resource>) => void
) => {
  if (typeof loadingEntry.image !== "undefined") {
    callback(loadingEntry.image);

    return;
  }

  loadingEntry.scheduledCallbacks.push(callback);
};

const AddNewLoadingEntry = (
  targetAddress: string,
  callback: (texture: Texture<Resource>) => void
) => {
  const newLoadingEntry: LoadingEntry = {
    address: targetAddress,
    loader: new PIXI.Loader(),
    scheduledCallbacks: [callback]
  };

  newLoadingEntry.loader.baseUrl = window.location.origin;
  newLoadingEntry.loader.add(targetAddress, targetAddress);
  newLoadingEntry.loader.load(() => {
    newLoadingEntry.image = PIXI.Texture.from(targetAddress);
    newLoadingEntry.scheduledCallbacks.forEach(callback => {
      callback(newLoadingEntry.image!);
    });
  });
  loadingEntries.push(newLoadingEntry);
};
