import { CtaButtonArrow } from "components/common/buttons/ButtonCta/ButtonCta.styled";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactFormCheckboxTextFont,
  ContactFormCommonFont,
  ContactFormContentFont,
  ContactFormSendWrapperFont
} from "styled/common/font-styles/ContactFormFonts";
import { DisableSelection, StaticElement } from "styled/common/Utils";
import styled, { css } from "styled-components";

const ContactFormCommon = css`
  border: none;
  border-bottom-style: solid;
  border-bottom-color: white;
  outline: none;
  background-color: transparent;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 4 / span 7;

    border-bottom-width: 1.5px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 8;

    border-bottom-style: double;
    border-bottom-width: 1.5px;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;

    border-bottom-style: double;
    border-bottom-width: 1.5px;
  }
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 5 })}
  ${CalculateFromWidth("margin-right", { mobile: 6.4, tablet: 5.2, desktop: 4 })}
`;

const ContactFormCtaButtonArrowImg = css`
  ${CtaButtonArrow} {
    ${CalculateFromHeight("width", { mobile: 4, tablet: 1.5 })}
    ${CalculateFromWidth("height", { mobile: 6.4, tablet: 3 })}
  }
`;

export const ContactFormContentWrapper = styled.form`
  ${ContactFormContentFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
  ${CalculateFromHeight("margin-top", { mobile: 12, tablet: -1, desktop: -2 })}
  ${CalculateFromHeight("height", { mobile: 190, tablet: 70, desktop: 43 })}
  width: 100%;
  display: grid;
`;

export const ContactFormEmail = styled.input`
  ${ContactFormCommonFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 1 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 1 / span 1;
  }
  ${CalculateFromHeight("height", { mobile: 20, tablet: 8, desktop: 4 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 6, tablet: 3, desktop: 3 })}

  ${ContactFormCommon}
`;

export const ContactFormPhone = styled.input`
  ${ContactFormCommonFont}
  ${ContactFormCommon}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 2 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 2 / span 1;
  }
  ${CalculateFromHeight("height", { mobile: 20, tablet: 8, desktop: 4 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 6, tablet: 7.5, desktop: 3 })}
`;

export const ContactFormMessage = styled.textarea`
  ${ContactFormCommon}
  ${ContactFormCommonFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 3 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 3 / span 5;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 3 / span 5;
  }
  ${CalculateFromHeight("margin-top", { mobile: 10, tablet: 5 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 6, tablet: 4, desktop: 3 })}
  ${CalculateFromHeight("height", { mobile: 50, tablet: 17, desktop: 8 })}
  resize: none;
  cursor: auto;

  &::-webkit-scrollbar {
    ${CalculateFromWidth("width", { mobile: 0.7, tablet: 0.7, desktop: 0.7 })}
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    ${CalculateFromWidth("border-radius", { mobile: 1.5, tablet: 1, desktop: 1 })}
    box-shadow: inset 0 0 1vw * 0.51 rgba(0, 0, 0, 0.6);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    ${CalculateFromWidth("border-radius", { mobile: 1, tablet: 1, desktop: 1 })}
    box-shadow: inset 0 0 1vw * 0.51 rgba(209, 205, 205, 0.6);
  }

  &::-webkit-scrollbar {
    ${CalculateFromWidth("width", { mobile: 2.5, tablet: 1.5, desktop: 0.7 })}
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    ${CalculateFromWidth("border-radius", { mobile: 1, tablet: 1, desktop: 1 })}
    box-shadow: inset 0 0 1vw * 0.51 rgba(209, 205, 205, 0.7);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    ${CalculateFromWidth("border-radius", { mobile: 1, tablet: 1, desktop: 1 })}
    box-shadow: inset 0 0 1vw * 0.51 rgba(209, 205, 205, 0.7);
    &:hover {
      background-color: rgba(70, 69, 69, 0.2);
    }
    &:active {
      background-color: rgba(70, 69, 69, 0.3);
    }
  }
`;

export const Checkmark = styled.span`
  ${CalculateFromHeight("margin-top", { tablet: 0.7 })}
  ${CalculateFromHeight("height", { mobile: 8.2, tablet: 3.6, desktop: 2.8 })}
  ${CalculateFromWidth("width", { mobile: 8, tablet: 4.3, desktop: 2 })}
  ${StaticElement}
  background-color: #c6c6c6;
  cursor: pointer;
  transition: background-color 250ms ease 0s;

  &::after {
    content: "";
    position: absolute;
    display: none;
    ${CalculateFromHeight("top", { mobile: 0.8, tablet: 0.4, desktop: 0.3 })}
    ${CalculateFromWidth("left", { mobile: 2.8, tablet: 1.6, desktop: 0.55 })}
    ${CalculateFromHeight("height", { mobile: 4.5, tablet: 2, desktop: 1.2 })}
    ${CalculateFromWidth("width", { mobile: 2, tablet: 1, desktop: 0.7 })}
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ContactFormLabel = styled.label`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 4 / span 5;
    grid-row: 5 / span 2;
    position: relative;
    display: block;
    cursor: pointer;
    ${DisableSelection}
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 7 / span 2;
    position: relative;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 7 / span 4;
    position: relative;
  }
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 5 })}
  ${CalculateFromHeight("margin-top", { mobile: -2, desktop: 2 })}
  &:hover input ~ ${Checkmark} {
    background-color: #afafaf;
  }

  & input:checked ~ ${Checkmark} {
    background-color: ${({ theme: { colors } }) => colors.footer};
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const ContactFormCheckboxText = styled.p`
  ${ContactFormCheckboxTextFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    position: absolute;
    top: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    position: absolute;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    position: absolute;
  }
  ${CalculateFromWidth("margin-right", { mobile: 10, desktop: 1 })}
  ${CalculateFromWidth("margin-left", { mobile: 17, tablet: 7, desktop: 4 })}
  ${CalculateFromHeight("top", { mobile: 1, tablet: 1 })}
`;

export const ContactFormSendWrapper = styled.div`
  ${ContactFormSendWrapperFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 9 / span 2;
    grid-row: 5 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 6 / span 3;
    grid-row: 7 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 2;
    grid-row: 8 / span 5;
  }
  ${CalculateFromWidth("margin-left", { tablet: 2, desktop: 1 })}
  ${CalculateFromWidth("margin-right", { tablet: 8, desktop: 6 })}
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: -2 })}
  ${ContactFormCtaButtonArrowImg}
`;
