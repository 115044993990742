import React, { useRef } from "react";

import { Sprite } from "@inlet/react-pixi";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import type { Sprite as SpriteType } from "pixi.js";
import { LoadImageData } from "providers/RenderElementsProvider";

interface CloudLayerProps {
  parentRect: DOMRect;
  imageData: IGatsbyImageData;
}

export const CloudLayer = ({ parentRect, imageData }: CloudLayerProps) => {
  const cloudSprite = useRef<SpriteType>(null);

  useFirstRenderEffect(() => {
    LoadImageData(
      imageData,
      parentRect,
      newTexture => {
        if (cloudSprite.current && cloudSprite.current !== null) {
          cloudSprite.current.texture = newTexture;
        }
      },
      0.5
    );
  }, [imageData]);

  return (
    <Sprite
      ref={cloudSprite}
      image={imageData.placeholder!.fallback}
      anchor={0.5}
      width={parentRect.width}
      height={parentRect.height}
    />
  );
};

export default CloudLayer;
