import React, { MutableRefObject, useReducer, useRef, useState } from "react";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types";

import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "@reach/router";
import TrackedButton from "components/common/analytics/TrackedButton";
import TrackedInput from "components/common/analytics/TrackedInput";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import {
  Checkmark,
  ContactFormCheckboxText,
  ContactFormContentWrapper,
  ContactFormEmail,
  ContactFormLabel,
  ContactFormMessage,
  ContactFormPhone,
  ContactFormSendWrapper
} from "components/common/contact/contact-form/ContactFormContent/ContactFormContent.styled";
import {
  contactFormSchema,
  ContactFormValues
} from "components/common/contact/contact-form/ContactFormContent/contactFormValidator";
import RenderValidation from "components/common/contact/contact-form/ContactFormValidation/ContactFormValidation";
import gsap, { Power3 } from "gsap/gsap-core";
import Cookies from "js-cookie";
import { sendMessage } from "network/send-message";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";
import Translate from "utils/translation/translation";

interface ContactFormContentProps {
  resultCallback: (success: boolean) => void;
  resetFormState: MutableRefObject<(() => void) | null>;
}

type Field = {
  name: string;
  value: string;
};

export const ContactFormContent = ({ resultCallback, resetFormState }: ContactFormContentProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [, setCurrentErrors] = useState<unknown>(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ContactFormValues>({
    resolver: yupResolver(contactFormSchema),
    shouldFocusError: false
  });
  const location = useLocation();

  const [messagesState, updateMessagesState] = useReducer(UpdateMessagesState, {
    email: {
      ref: useRef(),
      shown: false,
      message: ""
    },
    phone: {
      ref: useRef(),
      shown: false,
      message: ""
    },
    message: {
      ref: useRef(),
      shown: false,
      message: ""
    },
    acceptedTerms: {
      ref: useRef(),
      shown: false,
      message: ""
    }
  });

  const UpdateErrors = () => {
    for (const messageName in messagesState) {
      const isCurrentMessageShown = messagesState[messageName].shown;
      const shouldShowError = errors && typeof errors[messageName] !== "undefined";
      const errorStateChanged = shouldShowError !== isCurrentMessageShown;

      if (errorStateChanged) {
        if (shouldShowError) {
          updateMessagesState({
            validationName: messageName,
            parameterName: "message",
            value: errors[messageName].message
          });
        }

        updateMessagesState({
          validationName: messageName,
          parameterName: "shown",
          value: shouldShowError
        });
      }

      if (shouldShowError && messagesState[messageName].message !== errors[messageName].message) {
        updateMessagesState({
          validationName: messageName,
          parameterName: "message",
          value: errors[messageName].message
        });
      }

      if (shouldShowError) {
        InvokeAnalyticsEvent("error", location, "form", "form-result", "error-" + messageName);
      }
    }

    setCurrentErrors(errors);
  };

  const onSubmit: SubmitHandler<ContactFormValues> = async formData => {
    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha("homepage");

    setToken(result);

    const context = {
      hutk: Cookies.get("hubspotutk"),
      pageUri: document.location.href,
      pageName: document.title
    };

    const legalConsentOptions = {
      legitimateInterest: {
        value: formData.acceptedTerms,
        subscriptionTypeId: 999,
        legalBasis: "LEAD",
        text: "I agree to the processing of my personal data for contact" // TODO - add data
      }
    };

    const formattedFields: Field[] = [];

    for (const field in formData) {
      if (field === "acceptedTerms") {
        continue;
      }

      formattedFields.push({
        name: field,
        value: formData[field]
      });
    }

    const userData = { context, fields: formattedFields, legalConsentOptions };
    const postBody = JSON.stringify({ userData, token: token ? token : result });

    try {
      const { msg, success } = await sendMessage(postBody);

      if (msg === "Thank you, your message was sent successfully!" || success === true) {
        InvokeAnalyticsEvent("form", location, "form", "form-result", "sent");

        return resultCallback(true);
      } else {
        InvokeAnalyticsEvent("form", location, "form", "form-result", "error-server");

        return resultCallback(false);
      }
    } catch (error) {
      console.error(error);
      InvokeAnalyticsEvent("form", location, "form", "form-result", "error-server");

      return resultCallback(false);
    }
  };

  useEffect(() => {
    UpdateErrors();
    resetFormState.current = reset;
  }, [reset, onSubmit, errors]);

  return (
    <ContactFormContentWrapper
      onSubmit={handleSubmit(onSubmit)}
      onInvalid={UpdateErrors}
      name="Website Contact Form"
    >
      <TrackedInput category="form" action="form-email">
        <ContactFormEmail
          {...register("email")}
          ref={register("email").ref}
          placeholder={Translate("Contact_Form_Email")}
        />
      </TrackedInput>
      <RenderValidation messageState={messagesState.email} variant="contact-form-email-valid" />
      <TrackedInput category="form" action="form-phone">
        <ContactFormPhone
          {...register("phone")}
          ref={register("phone").ref}
          placeholder={Translate("Contact_Form_Phone")}
        />
      </TrackedInput>
      <RenderValidation messageState={messagesState.phone} variant="contact-form-phone-valid" />
      <TrackedInput category="form" action="form-message">
        <ContactFormMessage
          {...register("message")}
          ref={register("message").ref}
          placeholder={Translate("Contact_Form_Message")}
        />
      </TrackedInput>
      <RenderValidation messageState={messagesState.message} variant="contact-form-message-valid" />
      <ContactFormLabel>
        <TrackedInput category="form" action="form-acceptedterms">
          <input
            type="checkbox"
            {...register("acceptedTerms")}
            ref={register("acceptedTerms").ref}
            placeholder="terms"
          />
        </TrackedInput>
        <Checkmark />
        <ContactFormCheckboxText>{Translate("Contact_Form_Agreement")}</ContactFormCheckboxText>
      </ContactFormLabel>
      <RenderValidation
        messageState={messagesState.acceptedTerms}
        variant="contact-form-terms-valid"
      />
      <ContactFormSendWrapper>
        <TrackedButton category="form" action="form-send">
          <CtaButton
            variant="cta-button-small"
            text="Contact_Form_Send"
            type="submit"
            onClick={UpdateErrors}
          />
        </TrackedButton>
      </ContactFormSendWrapper>
    </ContactFormContentWrapper>
  );
};

export default ContactFormContent;

const UpdateMessagesState = (prevData, { validationName, parameterName, value }) => {
  prevData[validationName][parameterName] = value;
  AnimateValidation(parameterName, value, prevData[validationName].ref.current);

  return prevData;
};

const AnimateValidation = (parameterName, value, validation) => {
  if (parameterName === "shown") {
    if (value) {
      gsap.fromTo(
        validation,
        { y: 25, opacity: 0, ease: Power3.easeOut },
        { y: 0, opacity: 1, duration: 1, display: "block", ease: Power3.easeOut }
      );
    } else {
      gsap.fromTo(
        validation,
        { y: 0, opacity: 1, display: "block", ease: Power3.easeOut },
        { opacity: 0, y: -25, duration: 1, ease: Power3.easeOut }
      );
    }
  }

  if (parameterName === "message") {
    gsap.from(validation, {
      y: 0,
      opacity: 0,
      duration: 0.5,
      display: "block",
      ease: Power3.easeOut
    });
  }
};
