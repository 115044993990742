import axios from "axios";
import { AxiosRequestConfig } from "axios";

const noCacheHeaders = {
  "Cache-Control": "no-cache",
  Expires: "0"
};

export interface Response {
  data: string;
}

const getHeaders = (headers: any, addNoCacheHeaders: boolean): any => {
  return addNoCacheHeaders ? { ...headers, ...noCacheHeaders } : headers;
};

export const readValue = <TResponse>(response: Response): TResponse => {
  if (typeof response.data === "object") {
    return response.data;
  }

  return JSON.parse(response.data) as TResponse;
};

export const postPromise = async <TPayload, TResponse>(
  url: string,
  payload: TPayload,
  headers: AxiosRequestConfig["headers"] | null = null,
  addNoCacheHeaders = true
): Promise<TResponse> => {
  return axios
    .post(url, payload, {
      params: { timeStamp: Date.now() },
      cancelToken: axios.CancelToken.source().token,
      headers: getHeaders(headers, addNoCacheHeaders)
    })
    .then(response => readValue<TResponse>(response))
    .catch(error => {
      throw new Error(error);
    });
};
