const styles = {
  log: `
    padding: 0.3rem 0.3rem;
    font-family: Arial;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px black;
    border-radius: 5px;
    background-image: linear-gradient(43deg, #000000 10%, #c93e3e 46%, #a1715a 100%)
  `
};

/**
 * Add `GATSBY_DEV_MODE=true` into `.env.development if u want to use this methods
 */
export const dev = {
  log(...input) {
    if (process.env.GATSBY_DEV_MODE !== "true") {
      return;
    }

    console.log(`%c [Dev]: `, styles.log, ...input);
  }
};

export {};
